import React, {useContext, useEffect} from 'react';
import styled from "styled-components";
import Icon from "../../utils/icon";
import AppButton from "./NewAppButton";
import {remFontTheme} from "../../styles/remFonts";
import {fontTheme} from "../../styles/fonts";
import {ChannelContext} from "../../context/channelContext";
import {ChannelEntity} from "../../repository/entity/ChannelEntity";
import {theme} from "../../styles/theme";


function labelType(diff) {
    return diff < 0 ? 'down' : 'up';
}

function subscriberStr(subscriberNum) {
    // 소수점 1자리까지만 표기
    const parts = (subscriberNum / 10000).toFixed(1).toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return `${parts.join('.')}만`;
}

function viewCountStr(viewCountNum) {
    // 소수점 1자리까지만 표기
    if (viewCountNum >= 100000000) {
        const parts = (viewCountNum / 100000000).toFixed(1).toString().split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return `${parts.join('.')}억`;
    } else if (viewCountNum >= 1000000) {
        const parts = (viewCountNum / 10000).toFixed(0).toString().split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return `${parts.join('.')}만`;
    } else {
        const parts = (viewCountNum / 10000).toFixed(1).toString().split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return `${parts.join('.')}만`;
    }
}

function diffStr(diffNum) {
    // 999% 초과일 때는 '999+%'로 표기
    if (diffNum > 9.99 || diffNum < -9.99) {
        return '999+%';
    }

    if (diffNum < 0.1 && diffNum > -0.1) {
        const parts = (diffNum * 100).toFixed(1).toString().split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return `${parts.join('.')}%`
    } else {
        const parts = Math.round(diffNum * 100).toString().split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return `${parts.join('.')}%`
    }
}

const ChannelDetailInfoContainer = (props) => {
    const {
        isUsedInMobile
    } = props;


    const {selectedChannelIndex, channelList, goToNextPage, setPopupSelectedChannelIndex} = useContext(ChannelContext);

    useEffect(() => {
    },[selectedChannelIndex])

    /* Intent */
    const optional = (instance) => {
        return selectedChannelIndex !== null ? channelList[selectedChannelIndex][instance] : null;
    }

    const isActive = () => {
        return selectedChannelIndex !== null;
    }

    const confirmHandler = () => {
        if (isActive()) {
            setPopupSelectedChannelIndex(null);
            goToNextPage(1);
        } else {
            alert("원하는 유튜브 채널을 선택해주세요")
        }
    };


    return <>
        <B.Container isUsedInMobile={isUsedInMobile}>
            <U.BlurOverlayContainer show={!isActive()}/>
            <B.TopSectionWrapper>
                {/* Title Section */}
                <B.LeadingWrapper>
                    <U.LeadingTitle show={!isActive()}>원하는 유튜브 채널을
                        선택해주세요</U.LeadingTitle>
                    <A.DurationInfo show={isActive()}>해당 유튜브 채널의 성장세에요</A.DurationInfo>
                </B.LeadingWrapper>
                {/*  Body Section  */}
                <B.BodyWrapper>
                    <DetailInfoContainer
                        prevData={subscriberStr(optional("beforeSubscriberNum"))}
                        currentData={subscriberStr(optional("currentSubscriberNum"))}
                        totalRate={diffStr(optional("diffSubscriberNum"))}
                        isActive={isActive()}
                        increased={labelType(optional("diffSubscriberNum"))}
                        title={"구독자 수"}
                    />
                    <A.SizedBox/>
                    <DetailInfoContainer
                        prevData={viewCountStr(optional("beforeViewCount"))}
                        currentData={viewCountStr(optional("currentViewCount"))}
                        totalRate={diffStr(optional("diffViewCount"))}
                        isActive={isActive()}
                        increased={labelType(optional("diffViewCount"))}
                        title={"누적 조회 수"}
                    />

                </B.BodyWrapper>
            </B.TopSectionWrapper>
            {/*  Bottom Section */}
            <B.BottomSectionWrapper>
                <AppButton id="invest_btn" isActive={selectedChannelIndex !== null}
                           onClick={() => confirmHandler()}
                >투자하기</AppButton>
            </B.BottomSectionWrapper>
        </B.Container>
    </>

};

// `Active` State에서 보여지는 컴포넌트
const A = {
    DurationInfo: styled.span`
      position: absolute;
      color: ${props => props.theme.colors.grey70};
      opacity: ${props => props.show ? 1 : 0.0};
      top: ${props => props.show ? 0 : '40px'};
      ${remFontTheme.alert2};
    `,


    SizedBox: styled.div`
      display: none;
      height: 12px;
      @media ${({theme}) => theme.device.mobile} {
        display: block;
      };

    `,


}


// `UnActivate` State에서 보여지는 컴포넌트
const U = {
    LeadingTitle: styled.h2`
      color: ${props => props.theme.colors.grey40};
      margin: auto;
      color: ${props => props.theme.colors.grey70};
      opacity: ${props => props.show ? 1 : 0.0};
      top: ${props => props.show ? 0 : '1.0rem'};
      position: relative;
      //transition: top 0.5s ease-out 0s, opacity 0.2s;
      ${remFontTheme.title2};

    `,

    BlurOverlayContainer: styled.div`
      width: 30rem;
      height: 20rem;
      backdrop-filter: blur(3px);
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      margin-top: 6.6rem;
      opacity: ${props => props.show ? 1 : 0.0};
      transition: opacity 0.4s;

      @media ${({theme}) => theme.device.mobile} {
        display: none;
      };

    `,


}

// `Base` Component
const B = {
    BottomSectionWrapper: styled.div`
      display: flex;
      justify-content: center;

      @media ${({theme}) => theme.device.mobile} {
        display: none;
      };
    `,

    LeadingWrapper: styled.div`
      height: 5.2rem;
      display: flex;
      margin-bottom: 3.0rem;
      position: relative;


      @media ${({theme}) => theme.device.mobile} {
        margin-bottom: 0;
        display: block;
        height: 13px;

      };
    `,


    SectionTitle: styled.h3`
      color: #898989;
      ${remFontTheme.alert2};
    `,


    TopSectionWrapper: styled.div`
      @media ${({theme}) => theme.device.mobile} {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        
      };
    `,

    Container: styled.div`
      height: 36.2rem;
      width: 36.0rem;
      border: 1px solid ${props => props.theme.colors.grey10};
      border-radius: 27px;
      padding: 2.4rem 4.0rem;
      position: relative;
      overflow: hidden;
      display: ${props => props.isUsedInMobile ? "none" : "flex"};
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: ${props => props.isUsedInMobile ? "0px" : "16px"};

      
      @media only screen and (max-width: 1024px) and (min-width: 768px){
        border-radius: 20px;
      }

      @media ${({theme}) => theme.device.mobile} {
        display: ${props => props.isUsedInMobile ? "flex" : "none"};
        padding: 12px 20px 18px 20px;
        height: 100%;
        width: 343px;
        flex-direction: row;
        border-radius: 16px;


        @media screen and (max-width: 355px) {
          width: 283px;
        }

        @media screen and (max-height: ${theme.breakPoint.fourthHeightBreak}) {
          padding: 12px 20px 10px 20px;
        }
      };

    `,

    BodyWrapper: styled.div`
      @media ${({theme}) => theme.device.mobile} {
        display: flex;
        flex-direction: column;

      };

      // @media ${({theme}) => theme.device.mobileM} {
      //   margin-top: 10px;
      // };

    `,

}

const DetailInfoContainer = (props) => {
    const {title, isActive, prevData, currentData, totalRate, increased} = props;


    const isIncreased = () => {
        return increased === "up";
    };


    return <SC.Container>
        <SC.Title isActive={isActive}>{title}</SC.Title>
        <SC.InfoWrapper isActive={isActive}>
            <SC.PrevData
                isActive={isActive}>{isActive ? prevData : "?"}</SC.PrevData>
            <SC.Arrow name="longArrowRight"/>
            <SC.CurrentData isActive={isActive}>{isActive ? currentData : "?"}</SC.CurrentData>
            {isActive ? (
                <SC.RateContainer increased={isIncreased} isActive={isActive}>
                    <SC.Rate increased={isIncreased}>{totalRate}</SC.Rate>
                    <SC.UpdownArrow increased={isIncreased} name="pinkUpArrow"/>
                </SC.RateContainer>
            ) : null}
        </SC.InfoWrapper>
    </SC.Container>

};


const SC = {
    RateContainer: styled.div`
      margin-left: 0.8rem;
      border-radius: 0.8rem;
      padding: 0.4rem 0.6rem;
      background-color: ${props => props.increased ? "#FFF2F6" : "#F2F5FF"};
      display: flex;
      justify-content: center;
      align-items: center;

      @media ${({theme}) => theme.device.mobileM} {
        padding: 2px 6px;
      };
    `,
    Rate: styled.span`
      color: ${props => props.increased ? props.theme.colors.pink : props.theme.colors.subBlue};
      ${remFontTheme.alert2};
      
    `,
    UpdownArrow: styled(Icon)`
      height: 12px;
      width: 12px;
      stroke: ${props => props.increased ? props.theme.colors.pink : props.theme.colors.subBlue};
      transform: ${props => props.increased ? "rotate(00deg)" : "rotate(180deg)"};

      @media ${({theme}) => theme.device.mobile} {
        font-size: 10px;
        height: 10px;
        width: 10px;
      };
    `,


    Container: styled.div`
      width: 100%;
      height: 100%;
      margin-bottom: 3.2rem;


      @media ${({theme}) => theme.device.mobile} {
        display: flex;
        justify-content: start;
        align-items: center;
        margin-bottom: 0;
      }


    `,

    Title: styled.h3`
      color: ${props => props.isActive ? props.theme.colors.black : "#898989"};
      margin-bottom: 1.4rem;
      white-space: nowrap;
      ${remFontTheme.alert2};

      @media ${({theme}) => theme.device.mobile} {
        width: 92px;
        margin-bottom: 0;
        display: block;
        ${fontTheme.body3};
      };

      
    `,


    InfoWrapper: styled.div`
      display: flex;
      align-items: center;
    `,

    PrevData: styled.span`
      color: ${props => props.isActive ? props.theme.colors.grey30 : "#DBDDE3"};
      white-space: nowrap;
      ${remFontTheme.headline2};
      margin-right: 6px;

      @media ${({theme}) => theme.device.mobile} {
        ${fontTheme.title1};
      };

    `,

    Arrow: styled(Icon)`
      width: 20px;
      height: 19px;
      margin: 0 auto;

      @media ${({theme}) => theme.device.mobile} {
        width: 14px;
      };

    `,

    CurrentData: styled.span`
      color: ${props => props.isActive ? props.theme.colors.black : "#898989"};
      white-space: nowrap;
      ${remFontTheme.headline2};
      margin-left: 6px;

      @media ${({theme}) => theme.device.mobile} {
        ${fontTheme.title1};

      };

      // @media ${({theme}) => theme.device.mobileM} {
      //   font-size: 14px;
      // };
    `,


}


export default ChannelDetailInfoContainer;


