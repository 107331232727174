import React from 'react';
import "../../styles/modal.css";
import styled from "styled-components";
import {fontTheme} from "../../styles/fonts";


const IosConfirmModal = (props) => {
    // 열기, 닫기, 모달 헤더 텍스트를 부모로부터 받아옴
    const {open, close} = props;

    function confirmHandler() {
        close();
        window.location.href = "https://testflight.apple.com/join/7e1uG6e2";
    }



    return (
        // 모달이 열릴때 openModal 클래스가 생성된다.
        <div onClick={()=> close()}
            className={open ? 'openModal modal' : 'modal'}>
            {open ? (
                <ModalContainer>
                    <Title>IOS 사용자이신가요?</Title>
                    <Content>현재 스토어에서 앱 심사중에 있어,<br/>테스트플라이트를 통한 다운로드만 가능합니다 😥<br/>
                        아래의 버튼을 통해 다운로드 해주세요.</Content>
                    <ConfirmBtn id='iosConfirmBtn' href="#" onClick={(e) => {
                        e.preventDefault();
                        confirmHandler();
                    }}>앱 다운받기</ConfirmBtn>
                </ModalContainer>
            ) : null}
        </div>
    );
};

const ConfirmBtn = styled.button`
  height: 48px;
  width: 136px;
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.blue30};
  border-radius: 16px;
  margin: 16px auto;
  ${fontTheme.body3}
`;

const Content = styled.div`
  margin: 8px 24px 8px 24px;
  color: ${props => props.theme.colors.grey40};
  ${fontTheme.body2}
`;

const ModalContainer = styled.section`
  width: 90%;
  max-width: 320px;
  border-radius: 24px;
  background-color: #fff;
  /* 팝업이 열릴때 스르륵 열리는 효과 */
  animation: modal-show 0.3s;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin: 0 auto;
  //padding: 20px 12.5px 16px 12.5px;
`;

const Title = styled.span`
  color: ${props => props.theme.colors.black};
  border-radius: 24px;
  margin: 20px 24px 8px 24px;

  ${fontTheme.headline2};
`;


export default IosConfirmModal;