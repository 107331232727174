import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { remFontTheme } from "../../styles/remFonts";
import { fontTheme } from "../../styles/fonts";
import { styled as muiStyled } from "@mui/material/styles";
import { Button } from "@mui/material";
import * as props from "../../styles/theme";
import { darken, lighten } from "polished";
import Icon from "../../utils/icon";
import { ChannelContext } from "../../context/channelContext";
import ScrollNotiBox from "../../components/ui/ScrollNotiBox";
import ProfitDetailDialog from "../../components/ui/ProfitDialog";
import DemoPage from "../../components/layout/BackgroundPage";
import { theme } from "../../styles/theme";
import LottieComponent from "../../components/ui/LottieComponent";
import ReactGA from "react-ga4";
import handleViewport from "react-in-viewport";
import { loadAppearAnimation } from "../../feature/loadAppearAnimation";

function labelType(diff) {
  return diff < 0 ? "downNoArrow" : "upNoArrow";
}

function profitRateStr(tradingProfitRate, profitType) {
  if (tradingProfitRate > 9.99) {
    return `${profitType} +999% 이상`;
  } else if (tradingProfitRate < -9.99) {
    return `${profitType} -999% 이상`;
  } else if (tradingProfitRate < 0.1 && tradingProfitRate > -0.1) {
    const parts = (tradingProfitRate * 100).toFixed(1).toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    if (tradingProfitRate > 0) {
      return `${profitType} +${parts.join(".")}%`;
    } else if (tradingProfitRate < 0) {
      return `${profitType} ${parts.join(".")}%`;
    } else {
      return `${profitType} 0%`;
    }
  } else {
    const parts = Math.round(tradingProfitRate * 100)
      .toString()
      .split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    if (tradingProfitRate < 0) {
      return `${profitType} ${parts.join(".")}%`;
    } else {
      return `${profitType} +${parts.join(".")}%`;
    }
  }
}

function profitStr(profit) {
  const parts = Math.round(profit).toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return `${parts.join(".")}원`;
}

function priceStr(profit) {
  const parts = Math.round(profit).toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return `${parts.join(".")}원`;
}

const DemoInvestmentSubPage3 = (props) => {
  const [openInfoDialog, setOpenInfoDialog] = useState(false);
  const { goToNextPage } = useContext(ChannelContext);
  const { openIosConfirmModal, refreshChannelList, channel, stockCount } =
    props;
  const { inViewport, forwardedRef, enterCount } = props;

  // For ViewPort State
  const [isViewportDetective, setIsViewportDetective] = useState(false);

  // 화면이 보여 졌을 때 해당 메소드 실행
  useEffect(() => {
    if (inViewport && !isViewportDetective) {
      setIsViewportDetective(true);
      ReactGA.event({
        category: "aim category",
        action: "on invested flow ends",
        label: channel.name,
      });
    } else if (!inViewport) {
      setIsViewportDetective(false);
    } else {
      setIsViewportDetective(false);
    }
  }, [enterCount, inViewport]);

  const reInvestBtnClickHandler = () => {
    refreshChannelList();
    setIsViewportDetective(false);
  };

  return (
    <DemoPage id="invest_3_page" className="swiper-slide slide-4">
      <B.ContentSection>
        <div style={{ position: "relative" }}>
          <div
            style={{
              width: "fit-content",
              height: "fit-content",
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: `translate(-50%, -50%)`,
            }}
          >
            <LottieComponent />
          </div>
          <B.LeadingTitle>
            총{" "}
            {profitStr(
              stockCount * channel.profitPerOneStock +
                stockCount * 2000 * channel.tradingProfitRate
            )}
            의<br /> 수익금을 받았을거예요!
          </B.LeadingTitle>
        </div>
        <B.CenterSection>
          <B.ProfitCard ref={forwardedRef}>
            <B.CardInfoWrapper>
              <B.RoundChannelImg alt="Channel Image" src={channel.profile} />
              <B.ChannelName id="invested_channel_name">
                {channel.name}
              </B.ChannelName>
            </B.CardInfoWrapper>
            <B.ProfitInfoWrapper>
              <B.AnnualRate>
                {profitRateStr(
                  (channel.profitPerOneStock +
                    2000 * channel.tradingProfitRate) /
                    2000,
                  "수익률"
                )}
              </B.AnnualRate>
              <B.Profit>
                {profitStr(
                  stockCount * channel.profitPerOneStock +
                    stockCount * 2000 * channel.tradingProfitRate
                )}
              </B.Profit>
            </B.ProfitInfoWrapper>
            <B.DetailBtn
              id="detail_btn"
              className={
                matchMedia("only screen and (max-width: 768px)").matches
                  ? ""
                  : "swiper-no-swiping"
              }
              onClick={() => {
                setOpenInfoDialog(true);
                console.log(channel.tradingProfitRate);
              }}
            >
              자세히 보기
            </B.DetailBtn>
          </B.ProfitCard>
          <B.ReInvestBtnContainer id="re-invest-btn">
            <B.LeftArrow name="leftArrow" />
            <B.InvestBtn
              id="re-invest-btn"
              className={
                matchMedia("only screen and (max-width: 768px)").matches
                  ? ""
                  : "swiper-no-swiping"
              }
              onClick={() => reInvestBtnClickHandler()}
            >
              다른 채널 투자하기
            </B.InvestBtn>
          </B.ReInvestBtnContainer>
        </B.CenterSection>
        <B.BottomSection>
          <B.StoreBtnWrapper
            className={
              matchMedia("only screen and (max-width: 768px)").matches
                ? ""
                : "swiper-no-swiping"
            }
          >
            <B.StoreBtn
              id="aos_btn"
              onClick={() =>
                (window.location.href =
                  "https://ourtube.onelink.me/AcWx/ksc83dbb")
              }
            >
              <B.StoreLog name="googlePlay" />
              <B.StoreName>Google Play</B.StoreName>
            </B.StoreBtn>
            <B.StoreBtn
              id="ios_btn"
              onClick={() =>
                (window.location.href =
                  "https://ourtube.onelink.me/AcWx/ksc83dbb")
              }
            >
              <B.StoreLog name="apple" />
              <B.StoreName>Apple Store</B.StoreName>
            </B.StoreBtn>
          </B.StoreBtnWrapper>
        </B.BottomSection>
        <ProfitDetailDialog
          open={openInfoDialog}
          onClose={() => setOpenInfoDialog(false)}
          adProfit={priceStr(stockCount * channel.profitPerOneStock)}
          adProfitRate={profitRateStr(
            channel.profitPerOneStock / 2000,
            "연수익률"
          )}
          principal={priceStr(stockCount * 2000)}
          channelValue={priceStr(
            stockCount * 2000 * (1 + channel.tradingProfitRate)
          )}
          tradingProfit={priceStr(
            stockCount * 2000 * channel.tradingProfitRate
          )}
          tradingProfitRate={profitRateStr(
            channel.tradingProfitRate,
            "시세차익"
          )}
          adProfitLabelType={labelType(channel.profitPerOneStock / 2000)}
          tradingProfitLabelType={labelType(channel.tradingProfitRate)}
        />
      </B.ContentSection>
      <B.ScrollNotiWrapper>
        <ScrollNotiBox isActive={true} />
      </B.ScrollNotiWrapper>
    </DemoPage>
  );
};

const B = {
  ScrollNotiWrapper: styled.div`
    margin-top: 10px;
    margin-bottom: 2.4rem;
    @media ${({ theme }) => theme.device.mobile} {
      margin-bottom: 0;
      display: block;
    } ;
  `,

  CenterSection: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  BottomSection: styled.div``,

  StoreBtnWrapper: styled.div`
    width: 29.2rem;
    display: flex;
    justify-content: space-between;
  `,
  StoreName: styled.span``,

  StoreBtn: styled.button`
    width: 14rem;
    height: 4.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 1.313rem;
    cursor: pointer;
    box-shadow: rgba(100, 100, 111, 0.1) 0px 7px 29px 0px;
    color: #333333;
  `,

  StoreLog: styled(Icon)`
    height: 1.4rem;
    width: 1.4rem;
    margin-right: 0.72rem;
  `,

  ReInvestBtnContainer: styled.div`
    position: relative;
    margin: 2.4rem 0 4rem 0;

    @media ${({ theme }) => theme.device.mobile} {
      margin: 26px 0 26px 0;

      @media screen and (max-height: ${theme.breakPoint
          .secondHeightBreakDemo3}) {
        margin: 20px 0 28px 0;
      }

      @media screen and (max-height: ${theme.breakPoint
          .fourthHeightBreakDemo3}) {
        margin: 12px 0 10px 0;
      }
    } ;
  `,

  LeftArrow: styled(Icon)`
    position: absolute;
    z-index: 100;
    right: 0;
    top: 0;
    left: 1.45rem;
    bottom: 0;
    margin: auto 0;
    height: 2rem;
    width: 2rem;
  `,

  InvestBtn: muiStyled(Button)({
    padding: "0.9rem 1.45rem 0.9rem 4.3rem",
    backgroundColor: "white",
    borderRadius: "7.9rem",
    fontSize: "1.4rem",
    color: props.theme.colors.grey50,
    lineHeight: "2.2rem",
    fontFamily: "PretendardSemiBold, serif",
    zIndex: 1,
    border: "1px solid #DCDEE5",
    textAlign: "start",

    /* 이벤트 */
    "&:hover": {
      background: lighten(0.1, `${props.theme.colors.white}`),
    },
    "&:active": {
      background: darken(0.1, `${props.theme.colors.white}`),
    },

    // "@media (max-width: 768px)": {  // textOverflow 때문에 line break 필요
    //     whiteSpace: "pre-wrap",
    // }
  }),

  AnnualRate: styled.div`
    padding: 0.4rem 0.6rem;
    border-radius: 0.8rem;
    background-color: #fff2f6;
    color: #ff628f;
    width: fit-content;
    block-size: fit-content;
    margin-bottom: 8px;
    ${remFontTheme.alert1};

    @media ${({ theme }) => theme.device.mobile} {
      margin-bottom: 8px;
    } ;
  `,

  DetailBtn: muiStyled(Button)({
    padding: "0.7rem 1.2rem",
    backgroundColor: "white",
    borderRadius: "1.2rem",
    fontSize: "1.4rem",
    lineHeight: "2.2rem",
    fontFamily: "PretendardSemiBold, serif",
    color: props.theme.colors.black,
    zIndex: 1,
    textAlign: "start",

    /* 이벤트 */
    "&:hover": {
      background: lighten(0.1, `${props.theme.colors.white}`),
    },
    "&:active": {
      background: darken(0.1, `${props.theme.colors.white}`),
    },
  }),

  ContentSection: styled.div`
    width: 55.2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto auto;
    position: relative;
    top: 32px;

    @media ${({ theme }) => theme.device.mobile} {
      width: 343px;
      margin-top: 3rem;

      @media screen and (max-width: ${theme.breakPoint.firstWidthBreakDemo3}) {
        width: 310px;
      }
    } ;
  `,

  ChannelName: styled.span`
    color: ${(props) => props.theme.colors.white};
    ${remFontTheme.body2};
  `,

  ProfitInfoWrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1.8rem;

    @media ${({ theme }) => theme.device.mobile} {
      margin-bottom: 2rem;
    } ;
  `,

  CardInfoWrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `,

  Profit: styled.span`
    ${remFontTheme.web1};
    color: ${(props) => props.theme.colors.white};
    margin-bottom: 3px;
  `,

  RoundChannelImg: styled.img`
    width: 2.8rem;
    height: 2.8rem;
    margin-bottom: 0.7rem;
    border-radius: 50%;
  `,

  ProfitCard: styled.div`
    padding: 2rem 3.4rem 2.4rem 3.4rem;
    width: 100%;
    height: 30.4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${(props) => props.theme.colors.blue30};
    justify-content: space-between;

    border-radius: 2rem;

    @media ${({ theme }) => theme.device.mobile} {
      padding: 20px 13px 24px 13px;

      @media screen and (max-width: ${theme.breakPoint.firstWidthBreakDemo3}) {
        height: 296px;
        padding: 20px 13px 24px 13px;
      }

      @media screen and (max-height: ${theme.breakPoint
          .thirdHeightBreakDemo3}) {
        height: 296px;
        padding: 20px 13px 24px 13px;
      }

      @media screen and (max-height: ${theme.breakPoint
          .fifthHeightBreakDemo3}) {
        height: 224px;
        padding: 16.74px 13px 24px 20.28px;
      }
    } ;
  `,

  LeadingTitle: styled.h1`
    display: block;
    color: ${(props) => props.theme.colors.black};
    ${remFontTheme.web3};
    text-align: center;
    margin-bottom: 2.8rem;

    @media ${({ theme }) => theme.device.mobile} {
      ${fontTheme.headline1};
      margin-bottom: 43px;

      @media screen and (max-height: ${theme.breakPoint
          .firstHeightBreakDemo3}) {
        margin-bottom: 20px;
      }

      @media screen and (max-height: ${theme.breakPoint.lastHeightBreakDemo3}) {
        margin-bottom: 12px;
      }
    } ;
  `,
};

export default handleViewport(DemoInvestmentSubPage3);
