import React from 'react';
import Page from "../../components/layout/Page";
import constants from "../../utils/constants";
import {S} from "./Footer.style";


const Footer = () => {
    return <Page backgroundColor='#F7F8FA' className="swiper-slide slide-6">
        <S.Logo name={"ourtubeTextLogo"}/>
        <S.ContentSection>
            <S.CompanyInfoContainer>
                <S.LeadingParagraph>Copyright© OURTUBE Inc ALL rights reserved.</S.LeadingParagraph>
                <S.Row>
                    <S.NoWrapParagraph>대표자 최준영</S.NoWrapParagraph> <S.Separator/>
                    <S.NoWrapParagraph>사업자번호 276-86-02405</S.NoWrapParagraph>
                </S.Row>
                <S.NoWrapParagraph>Tel) 070-8015-2578</S.NoWrapParagraph>
                <S.NoWrapParagraph>E-mail) support@ourtube.co.kr</S.NoWrapParagraph>
                <S.Paragraph>서울특별시 도봉구 우이천로381, 지하 1층 B116-9호</S.Paragraph>
            </S.CompanyInfoContainer>
            <S.TrailingWrapper>
                <S.NoticeContainer>
                    <S.LeadingParagraph>아워튜브 브랜드 및 서비스 관련 상표권과 저작권 등을 포함한 모든 권리는 주식회사 아워튜브에 있습니다.</S.LeadingParagraph>
                    <S.Paragraph>투자 유의사항</S.Paragraph>
                    <S.ListWrapper>
                        {
                            constants.NOTICE.map((value) => (
                                <S.Item key={value.index}>{value.content}</S.Item>
                            ))
                        }
                    </S.ListWrapper>
                </S.NoticeContainer>
                <S.ActionBtnContainer>
                    <S.Row>   
                        <S.AnkerBtn href="https://ourtube.co.kr/mainPolicy">이용약관</S.AnkerBtn>
                        <S.Separator/>
                        <S.Button href="https://ourtube.co.kr/privacy">개인정보처리방침</S.Button>
                        <S.Separator/>
                        <S.Button href="https://ourtube.co.kr/marketing">마케팅정보수신동의</S.Button>
                        <S.Separator/>
                        <S.AnkerBtn showUnerline={true} href={"https://skillful-relish-d1c.notion.site/ourtube-recruit-7fd1d5c07c5f4023ae599f6df44f3b62"}>채용</S.AnkerBtn>
                    </S.Row>
                </S.ActionBtnContainer>
            </S.TrailingWrapper>
        </S.ContentSection>

    </Page>
};


export default Footer;