import React, {useContext} from 'react';
import '../../styles/modal.css';
import styled from "styled-components";
import Icon from "../../utils/icon";
import {fontTheme} from "../../styles/fonts";
import {remFontTheme} from "../../styles/remFonts";
import AppSearchBar from "./AppSearchBar";
import {searchChannelList, writeSearchHistory} from "../../repository/repository";
import ChannelButtonGrid from "./ChannelButtonGrid";
import SearchChannelDetailInfo from "./SearchChannelDetailInfoContainer";
import NoSearchResultView from "./NoSearchResultView";
import useWindowDimensions from "../../feature/useWindowDimensions";
import AppButton from "./AppButton";
import {ChannelContext} from "../../context/channelContext";


/** Modal Dialog
 *  - 모달 다이어로그 노출 여부 , 다이어로그 닫기, 제목, 컨텐츠(본문)을 props로 전달 받음
 * */

const SearchChannelModal = (props) => {
    const {height, width} = useWindowDimensions();

    const isMobileViewPort = () => {
        return width <= 768 ? true : false;
    };

    const {isOpen, closeModal, goToNextScreen, openRequestIsDoneModal} = props;

    const {
        popupChannelList, setPopupChannelList,
        popupSelectedChannelIndex, setPopupSelectedChannelIndex,
        term, setTerm,
    } = useContext(ChannelContext);

    const onRequestEventHandler = () => {
        setPopupSelectedChannelIndex(null);
        setPopupChannelList([]);
        openRequestIsDoneModal();
    };

    const refreshStateHandler = () => {
        setTerm(null);
        setPopupSelectedChannelIndex(null);
        setPopupChannelList([]);
    };


    const onInvestBtnClicked = () => {
        closeModal();
        // setPopupSelectedChannelIndex(selectedChannelIndex);
        goToNextScreen();
    };

    return (
        <div onClick={() => {
        }}
             className={isOpen ? 'openModal modal swiper-no-swiping search' : 'modal search'}>
            {isOpen ? (
                <S.Container id="search_channel_dialog">
                    <S.TopSection>
                        <S.CloseBtn>
                            <S.CloseIc name="close" onClick={(e) => {
                                e.preventDefault();
                                setTerm(null);
                                setPopupSelectedChannelIndex(null);
                                setPopupChannelList([]);
                                closeModal();
                            }}/>
                        </S.CloseBtn>
                    </S.TopSection>
                    <S.LeadingTitle>찾는 채널을 알려주세요</S.LeadingTitle>
                    <S.ContentSection>
                        <S.LeftSection>
                            <AppSearchBar
                                setSelectedChannelIndex={setPopupSelectedChannelIndex}
                                value={term}
                                setValue={(newTerm) => {
                                    setTerm(newTerm);
                                    setPopupChannelList(searchChannelList(newTerm));
                                    setPopupSelectedChannelIndex(null);
                                }}
                                onChange={writeSearchHistory}
                            />
                            {popupChannelList.length === 0 && term !== null && term.replace(' ', '').length !== 0 && (
                                <S.NoResultIndicatorWrapper>
                                    <NoSearchResultView term={term}
                                                        openDoneDialog={() => onRequestEventHandler()}/></S.NoResultIndicatorWrapper>)}
                            <S.SizedBox/>
                            <S.ChannelInfoWrapper>
                                <SearchChannelDetailInfo
                                    channelList={popupChannelList.slice(0, 6) ?? null}
                                    selectedChannelIndex={popupSelectedChannelIndex ?? null}
                                    closeModal={closeModal}
                                    isUsedInMobile={popupSelectedChannelIndex !== null && term !== null && term.length > 0}
                                    goToNextScreen={goToNextScreen}
                                />
                            </S.ChannelInfoWrapper>
                            {popupChannelList.length !== 0 && term !== null && (
                                    <S.ScrollView>
                                        <ChannelButtonGrid
                                            selectedChannelIndex={popupSelectedChannelIndex}
                                            channelList={popupChannelList.slice(0, 6)}
                                            setSelectedChannelIndex={setPopupSelectedChannelIndex}
                                        />
                                    </S.ScrollView>
                                )}
                        </S.LeftSection>
                        <S.RightSection>
                            <SearchChannelDetailInfo
                                channelList={popupChannelList.slice(0, 6) ?? null}
                                selectedChannelIndex={popupSelectedChannelIndex ?? null}
                                closeModal={closeModal}
                                isUsedInMobile={false}
                                goToNextScreen={goToNextScreen}
                            />
                        </S.RightSection>
                    </S.ContentSection>
                    <S.FloatBtnWrapper activate={popupSelectedChannelIndex !== null}>
                        <AppButton id="invest_btn" onClick={() => onInvestBtnClicked()}>투자하기</AppButton>
                    </S.FloatBtnWrapper>
                </S.Container>
            ) : null}
        </div>
    );
};

const S = {
    NoResultIndicatorWrapper: styled.div`
    `,

    ChannelInfoWrapper: styled.div`
      display: none;
      @media ${({theme}) => theme.device.mobile} {
        display: block;
      }

    `,

    FloatBtnWrapper: styled.div`
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      display: block;
      opacity: 0;
      z-index: 1;

      @media ${({theme}) => theme.device.mobile} {
        opacity: ${props => props.activate ? 1 : 0.0};
        bottom: ${props => props.activate ? "90px" : "-100px"};
        transition: bottom 0.5s ease 0s, opacity 1.0s;
      }

    `,


    TopSection: styled.div`
      display: flex;
      justify-content: end;
      width: 100%;
      margin-bottom: 2.4rem;;


      @media ${({theme}) => theme.device.mobile} {
        margin-top: 20px;
      };
    `,

    SizedBox: styled.div`
      height: 20px;
      display: none;

      @media ${({theme}) => theme.device.mobile} {
        display: block;
      };
    `,

    Sample: styled.div`
      color: red;
      font-size: 15px;
    `,

    ContentSection: styled.div`
      display: flex;
      justify-content: space-between;
      
      

      @media ${({theme}) => theme.device.mobile} {
        left: inherit;
        flex-direction: column;

        /*@media ${({theme}) => theme.device.contentHeight} {
          justify-content: start;
        };*/
      };
    `,
    LeftSection: styled.div`
      
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;


      @media ${({theme}) => theme.device.mobile} {
        display: flex;
        flex-direction: column;
        align-items: center;
        
        min-height: 500px;
        
        


      };
    `,
    RightSection: styled.div`

      @media ${({theme}) => theme.device.mobile} {
        display: none;
      };
    `,

    ScrollView: styled.div`
      
      margin-top: 4.8rem;

      &::-webkit-scrollbar {
        display: none; /* for Chrome, Safari, and Opera */
      }


      @media ${({theme}) => theme.device.mobile} {
        margin-top: 20px;
      };
    `,

    LeadingTitle: styled.h1`
      ${remFontTheme.web3};
      color: ${props => props.theme.colors.black};
      margin-bottom: 3.2rem;

      @media ${({theme}) => theme.device.mobile} {
        ${fontTheme.headline1};
        text-align: center;
      };
    `,


    CloseIc: styled(Icon)`
      height: 100%;
      width: 100%;
    `,

    CloseBtn: styled.button`
      height: 24px;
      width: 24px;
      background: white;
      cursor: pointer;

    `,


    Container: styled.section`
      width: 115.9rem;
      height: 65.0rem;
      left: 6.4rem;
      padding: 3.2rem;
      background-color: white;
      top: -5.2rem;


      position: relative;
      /* 팝업이 열릴때 스르륵 열리는 효과 */
      animation: modal-show 0.3s;
      display: flex;
      flex-direction: column;
      justify-content: start;
      margin: 0 auto;


      @media ${({theme}) => theme.device.mobile} {
        width: 375px;
        height: 100%;
        padding: 0 16px;
        left: inherit;
        top: 5.8rem;
        justify-content: center;
      };


      @media ${({theme}) => theme.device.mobileS} {
        width: 343px;
      };
    `,
}


export default SearchChannelModal;