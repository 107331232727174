import styled from "styled-components";
import Icon from "../../../utils/icon";
import {fontTheme} from "../../../styles/fonts";


export const LeadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EmptyBox = styled.div`
  display: none;

  @media ${({theme}) => theme.device.tablet} {
    display: block;
  }
`;

export const NavbarContainer = styled.section`
  z-index: 120;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  background-color: white;

  @media ${({theme}) => theme.device.tablet} {
    padding: 0 40px;
  }
  
  @media ${({theme}) => theme.device.mobile} {
    padding: 0 16px;
    position: relative;
  }
  
`;

export const MainLogo = styled(Icon)`
  cursor: pointer;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 550px;
  

  @media ${({theme}) => theme.device.tablet} {
    width: 540px;
  }

  @media screen and (max-width: 906px) {
    width: 440px;
  }


  @media ${({theme}) => theme.device.mobile} {
    display: none;
  }
`;

export const NavbarButton = styled.button`
  background: white;
  cursor: pointer;
  ${fontTheme.body2};
  color: ${props => props.theme.colors.grey80};
`;

