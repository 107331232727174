import styled from "styled-components";
import {fontTheme} from "../../styles/fonts";
import {ContentSection} from "../../components/ui/ContentSection";
import background from "../../assets/images/youtube_background.png";

export const S = {
    RefContainer: styled.span``,

    LeadingTitle: styled.h1`
      display: block;
      text-align: center;
      color: #808180;
      margin-bottom: 56px;
      position: relative;
      ${props => props.theme.appearAnimation.first};
      white-space: nowrap;
      
      letter-spacing: -0.6px;
      font-family: "PretendardSemiBold", serif;
      font-size: 36px;
      line-height: 50px;
      
      @media ${({theme}) => theme.device.tablet} {
        font-size: 32px;
        line-height: 48px;
      };
    `,

    Strong: styled.span`
      color: white;
    `,

    SubDescription: styled.p`
      display: block;
      text-align: center;
      color: #808180;
      position: relative;
      ${fontTheme.alert2};
      ${props => props.theme.appearAnimation.second};
      letter-spacing: -0.6px;
    `,

    ContentSection: styled(ContentSection)`
      background-image: url(${background});
      //background-position-x: -120px;
      background-position-y: top;
      background-size: cover;
      background-repeat: no-repeat;
      height: 100%;
      width: 100%;
      display: block;
    `,

    GradientOverlay: styled.div`
      height: 100%;
      width: 100%;
      background-image: linear-gradient(to top, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.86), rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 1));
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    `,
}