import React, {useState} from 'react';
import styled from "styled-components";
import Page from "../layout/Page";
import Icon from "../../utils/icon";
import {fontTheme} from "../../styles/fonts";




const ScrollNotiBox = (props) => {
    const {isActive} = props;

    return <S.Container>
            <S.Text isActive={isActive}>스크롤 해보세요</S.Text>
            <S.Icon name="doubleArrowDown"/>
            
        </S.Container>


};


const S = {
    Container: styled.div`
      display: inline-flex;
      align-items: center;
      padding-bottom: 40px;
    `,
    Text: styled.span`
      ${fontTheme.body3};
      color: ${props => props.isActive ? props.theme.colors.grey40 : props.theme.colors.grey20};
      display: inline-block;
      position: relative; 
      animation: arrow_down 1.5s infinite;
  
    
      @keyframes arrow_down{
      0%{top:28px;}
      50%{top:40px;}
      100%{top:28px;}
      }
      @keyframes arrow-wave {
      0% {opacity: 0;}
      50% {opacity: .5;}
      100% {opacity: 1;}
      }
    `,

    Arrow: styled.div`
      margin-bottom:4px;
      margin-left: 15px;
      display:inline-block; 
      position: relative; 
      animation:arrow_down 1.5s infinite;
     
      @keyframes arrow_down{
      0%{top:28px;}
      50%{top:40px;}
      100%{top:28px;}
      }  
     `,

    ScrollArrow: styled.div`
    
      width:8px; 
      height:8px; 
      border-right: 3px solid #C9CCD7; 
      border-bottom: 3px solid #C9CCD7;
      transform: rotate(45deg); -webkit-transform: rotate(45deg); -moz-transform: rotate(45deg); -o-transform: rotate(45deg);  -ms-transform: rotate(45deg);
      
      animation: arrow-wave 0.7s infinite; animation-direction: alternate; 
      .scroll-arrow:nth-child(1) {animation-delay: 0.3s;}
      .scroll-arrow:nth-child(2) {margin-bottom: 4px; animation-delay: 0.5s;}
      .scroll-arrow:nth-child(3) {margin-top:1px; animation-delay: 0.6s;}
      @keyframes arrow-wave {
      0% {opacity: 1;}
      50% {opacity: .5;}
      100% {opacity: 0;}
      }
    
    `,

    Icon: styled(Icon)`
      margin-left: 5px;
      stroke: ${props => props.isActive ? props.theme.colors.grey40 : props.theme.colors.grey20};
      display:inline-block;
      position: relative; 
      animation: arrow_down 1.5s infinite;
      animation-delay: 100s
  
    
      @keyframes arrow_down{
      0%{top:28px;}
      50%{top:40px;}
      100%{top:28px;}
      }
      @keyframes arrow-wave {
      0% {opacity: 0;}
      50% {opacity: .5;}
      100% {opacity: 1;}
      }
    `,
}


export default ScrollNotiBox;