import React from 'react';
import styled from "styled-components";
import "../Footer/Marketing.css";

const PrivacyPolicyPage = () => {
  return <Container>
  <Title>개인정보처리방침 </Title>
  
  <Text>주식회사 아워튜브(이하 “회사”)는 「개인정보보호법」 제30조에 따라 이용자의 개인정
        보 및 권익을 보호하고 개인정보와 관련한 이용자의 고충을 신속하고 원활하게 처리할
        수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립ㆍ공개합니다
  </Text>

  <SubTitle style={{marginTop:"50px"}}>제1조 (개인정보 수집ㆍ이용 목적, 수집항목, 수집방법)</SubTitle>      
  <SubTitle>1. 개인정보 처리 목적 및 처리 항목</SubTitle>    

  <Text>“개인정보”는 생존하는 개인에 관한 정보로써 해당 정보에 포함되어 있는 성명, 주민등
        록번호 등의 사항에 의하여 당해 개인을 식별할 수 있는 정보 또는 해당 정보만으로는
        특정 개인을 식별할 수 없더라도 다른 정보와 쉽게 결합하여 식별할 수 있는 정보를 말
        합니다.
  </Text>

  
  <Text>회사가 이용자로부터 수집하는 개인정보는 아래와 같습니다. 회사가 아래와 같이 처리하
        는 각 항목은 개인정보의 처리 목적에 필요한 최소한의 개인정보입니다. 회사는 다음의
        목적 이외의 용도로는 각 항목을 처리하지 않으며, 이용 목적이 변경되는 경우에는 「개
        인정보보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행하겠습니다.
  </Text>

  <SubTitle>[회원가입 시] (필수항목) </SubTitle>  

<TableContainer>
  <table className='MarketingTable'>

    <tr className='MarketingThTr'>
      <th  style={{borderLeft:"none"}} >목적</th>
      <th  style={{borderLeft:"none"}}>항목 </th>
    </tr>


    
    <tr className='MarketingTdTr'>
      <td style={{border: "1px solid silver", borderLeft:"none", padding:"20px" }}>
          이용자 식별, 회원 관리,
          로그인 문제 해결 시 본인 확인,
          서비스와 관련된 중요한 사항 안내,
          민원 등 이용자 불만처리
      </td>

      <td style={{border: "1px solid silver", borderLeft:"none" ,borderRight:"none", textAlign:"center",width:"50%"}}>
          이름, 생년월일, 이메일주소, 휴대전화번호,
          비밀번호, 성별, 개인고유식별키, 사이트별
          식별키, 통신사, 외국인 여부
      </td>
    </tr>

  </table>
</TableContainer>
  <SubTitle>[서비스 이용과정] (해당 서비스 이용 시 필수항목)  </SubTitle>  

<TableContainer>
  <table className='MarketingTable' >
    <tr className='MarketingThTr'>
      <th  style={{borderLeft:"none"}} >목적</th>
      <th  style={{borderLeft:"none"}}>항목 </th>
    </tr>

    <tr className='MarketingTdTr' >
      <td style={{border: "1px solid silver", borderLeft:"none", padding:"20px",width:"50%" }}>
      SNS 계정 연동
      2단계 인증 설정
      콘텐츠 등 서비스 제공에 따르는 본인 인증, 구매 및 결제를 포함한 회원에 대한 회사 서비스 제공 및 계약의 이행, 서비스 부정이용 방지
      회원에 대한 상품 배송
      캐시출금 및 기타 캐시관련 업무
      만 14세 미만 아동일 경우 법정대리인 동의 진행,
      이용자 및 법정대리인의 본인 확인
      </td>

      <td style={{border: "1px solid silver", borderLeft:"none" ,borderRight:"none", textAlign:"center",width:"50%"}}>
        사용자 이름(프로필 정보), 사용자 식별자, 이메일주소
        OTP 일련번호, 인증번호
        성명, 생년월일, 휴대전화번호, 성별, 연령대, 내ㆍ외국인 구분, 본인확인정보, 중복가입확인정보, 연계정보
        성명, 주소, 휴대전화번호
        계좌정보(은행명, 계좌번호, 예금주)
        법정대리인의 개인정보(성명, 생년월일, 이메일주소)


      </td>
    </tr>
  </table>
</TableContainer>

  <SubTitle>[서비스 이용과정] (선택항목) </SubTitle>  
<TableContainer>
  <table className='MarketingTable'>
    <tr className='MarketingThTr'>
      <th  style={{borderLeft:"none"}} >목적</th>
      <th  style={{borderLeft:"none"}}>항목 </th>
    </tr>

    <tr className='MarketingTdTr' >
      <td style={{border: "1px solid silver", borderLeft:"none", padding:"20px" }}>
      신규 서비스 개발,맞춤 서비스 제공 및 마케팅,회원의 서비스 이용 통계 및 설문ii
      </td>

      <td style={{border: "1px solid silver", borderLeft:"none" ,borderRight:"none", textAlign:"center",width:"50%"}}>
      성별, 생년월일, 휴대폰번호, 서비스 이용 이력
      </td>
    </tr>
  </table>
</TableContainer>

  <SubTitle>[만 14 세 미만 아동의 경우 법정대리인 동의] </SubTitle>
<TableContainer>
    <table className='MarketingTable'>
    <tr className='MarketingThTr'>
      <th  style={{borderLeft:"none"}} >목적</th>
      <th  style={{borderLeft:"none"}}>항목 </th>
    </tr>

    <tr className='MarketingTdTr' >
      <td style={{border: "1px solid silver", borderLeft:"none", padding:"20px" }}>
      만 14 세 미만의 아동에 대한 개인정보 처리 관련 법정대리인의 동의 의사 확인
      </td>

      <td style={{border: "1px solid silver", borderLeft:"none" ,borderRight:"none", textAlign:"center",width:"50%"}}>
      법정대리인의 이름, 만 14 세 아동과의 관계(가족관계증명서 중 위 내용에 한정된개인정보 항목)
      </td>
    </tr>
  </table>  
</TableContainer>

  <SmallUl>
    <SmallLi> 법령 및 이용약관을 위반하는 회원에 대한 이용제한조치, 부정이용행위를 포함하여 서비스의 원활한 운영에 지장을 주는 행위에 대한 방지 및 제재, 약관 개정 등의 고지사항 전달, 분쟁조정을 위한 기록 보존, 민원처리 등 이용자 보호 및 서비스 운영을 위하여 개인정보를 이용합니다.</SmallLi>
    <SmallLi> 이벤트 정보 및 참여기회 제공, 광고성 정보 제공 등 마케팅 및 프로모션 목적으로 개인정보를 이용합니다.</SmallLi>
  </SmallUl>

  <SubTitle>[이용자에 대한 맞춤형 서비스 제공]</SubTitle>
<TableContainer>
  <table className='MarketingTable'>
    <tr className='MarketingThTr'>
      <th  style={{borderLeft:"none"}} >목적</th>
      <th  style={{borderLeft:"none"}}>항목 </th>
    </tr>

    <tr className='MarketingTdTr' >
      <td style={{border: "1px solid silver", borderLeft:"none", padding:"20px" }}>
      서비스 이용기록과 접속 빈도 분석,
       서비스 이용에 대한 통계,
       서비스 분석 및 통계에 따른 맞춤 서비스 제공
      </td>

      <td style={{border: "1px solid silver", borderLeft:"none" ,borderRight:"none", textAlign:"center",width:"50%"}}>
      IP주소, 쿠키, 서비스 이용기록, 디바이스정보

      </td>
    </tr>
  </table>  
</TableContainer>

  <SubTitle>2. 개인정보 수집방법</SubTitle>   
  <Text style={{justifyContent:"left"}}>회사는 다음과 같은 방법으로 개인정보를 수집합니다.</Text>
  <Ul>
    <Li>회원가입 및 서비스 이용과정에서 회원이 개인정보 수집에 대해 동의를 하고, 직접 정보를 입력하는 경우에 해당 개인정보를 수집합니다.</Li>
    <Li>회사는 회사와 제휴한 업체로부터, 또는 회원이 제3자에게 직접 제공 동의 의사를 표시하고 개인정보를 제공받을 수 있고, 고객센터를 통한 상담과정, 기타 이벤트 응모 등을 통해 개인정보를 수집할 수 있습니다.</Li>
  </Ul>

  <SubTitle style={{marginTop:"50px"}}>제2조 (개인정보의 제3자 제공)</SubTitle>      
  <Text>회사는 원칙적으로 회원의 사전 동의 없이 개인정보를 외부에 제공하지 않습니다. 단,다음의 경우를 제외하고는 명시한 범위를 초과하여 처리하거나 개인정보를 제3자에게제공하지 않습니다.</Text>
  <Ul>
    <Li> 회원이 개인정보 제공에 직접 동의를 한 경우</Li>
    <Li>다른 법률에 특별한 규정이 있는 경우</Li>
    <Li>「개인정보보호법」 제17조 및 제18조에 해당하는 경우</Li>
  </Ul>

  <SubTitle style={{marginTop:"50px"}}>제4조 (개인정보의 처리ㆍ보유 기간과 파기 절차ㆍ방법)</SubTitle>      
  <SubTitle>1. 개인정보 처리ㆍ보유 기간</SubTitle>   
  <Text>회사는 법령에 따른 개인정보 보유ㆍ이용 기간 또는 이용자로부터 개인정보를 수집시에 동의 받은 개인정보 보유ㆍ이용 기간 내에서 개인정보를 처리ㆍ보유합니다. 다만, 다음과 같은 경우에는 예외로 합니다.</Text>
  
  <Ul>
    <Li>회사 내부정책에 의한 처리ㆍ보유 기간</Li>
     <Ul>
      <Li>회원의 탈퇴 신청 등으로 서비스 이용계약이 종료된 경우, 회사는 투자자 보호 및 본 약관이 정한 제한을 회피하기 위한 임의해지 방지를 위하여 상당하다고 인정되는 경우에 한하여 이용계약종료 후 1년간 아이디디(이메일 포함), 성명, 연락처, 거래상세내역, 약관위반 행위자료 등 최소한의 필요정보를 보관합니다.</Li>
      <Li>회사가 이용계약을 해지하거나 회사로부터 서비스 이용제한 조치를 받은 회원에 대해서는 재가입에 대한 승낙거부사유가 존재하는지 여부를 확인하기 위한 목적으로 이용계약종료 후 1년간 아이디(이메일 포함), 성명, 연락처, 주소, 해지와 회원자격정지와 관련된 정보 등 최소한의 필요정보를 보관합니다.</Li>
      <Li>서비스 부정 이용 방지를 위해 회원의 부정이용기록([이름, 생년월일, 휴대전화번호, 이메일 주소, 비밀번호, 성별, 개인고유식별키, 사이트별 식별키, 통신사, 외국인 여부, CI, DI, 거래내역, 계좌번호])을 부정이용 시점으로부터 3년간 보관합니다.</Li>
     </Ul>
     <Li>관련 법령에 의한 처리ㆍ보유 기간</Li>
  </Ul>
  <NoneUl>
    <Li>-  전자상거래 등에서 소비자 보호에 관한 법률」</Li>
      <Ul>
        <Li>계약 또는 청약철회 등에 관한 기록: 5년 보관</Li>
        <Li>대금결제 및 재화 등의 공급에 관한 기록: 5년 보관</Li>
        <Li>소비자의 불만 또는 분쟁처리에 관한 기록: 3년 보관</Li>
      </Ul>
    <Li>- 「전자금융거래법」</Li>
      <Ul>
        <Li>전자금융에 관한 기록: 5년 보관</Li>
      </Ul>
    <Li>- 「통신비밀보호법」</Li>
    <Ul>
      <Li>인터넷 로그기록자료: 3개월</Li>
    </Ul>
  </NoneUl>
  <Text>개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도, 내부 정책 또는 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스로 옮기거나 보관장소를 달리하여 보존합니다.</Text>

  <SubTitle>2. 개인정보 파기 절차 및 방법</SubTitle>   
  <Text>회사는 개인정보 보유기간 경과, 처리목적 달성, 사업을 폐업하는 경우, 이용자가 개인정보 파기를 요청하거나 수집ㆍ이용 동의를 철회한 경우 등 개인정보가 불필요하게 되었을 때에는, 지체없이 해당 개인정보를 파기합니다. 개인정보의 파기절차 및 파기방법은 다음과 같습니다.</Text>
  <SubTitle>파기절차</SubTitle>
  <Text style={{justifyContent:"left"}}>회사는 파기 사유가 발생한 개인정보를 선정하고, 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.</Text>   
  <SubTitle>파기기한</SubTitle>
  <Text>개인정보 보유기간이 경과한 경우에는 그 경과한 날로부터, 개인정보의 처리목적 달성, 회사 사업의 종료 등 그 개인정보가 불필요하게 된 경우에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 각 지체없이(정당한 사유가 없는 한 5일 이내) 해당 개인정보를 파기합니다.</Text> 
  <SubTitle>파기방법</SubTitle>
  <Text style={{justifyContent:"left"}}>회사는 전자적 파일 형태로 기록ㆍ저장된 개인정보는 기록을 재생할 수 없도록 파기하며, 종이 문서에 기록ㆍ저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.</Text>

  <SubTitle>제5조 (이용자 및 법정대리인의 권리ㆍ의무와 행사방법)</SubTitle> 
  <Ul>
    <Li>이용자는 회사에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다.</Li>
      <Ul>
        <Li>개인정보 열람 요구</Li>
        <Li>오류 등이 있을 경우 정정 요 구</Li>
        <Li>삭제 요구</Li>
        <Li>처리정지 요구</Li>
      </Ul>
    <Li>제1항에 따른 권리 행사는 회사에 대해 서면, 전자우면, 모사전송(FAX) 등을 통하여 행사할 수 있으며, 회사는 이에 대해 지체없이 필요한 조치를 하겠습니다.</Li>
      <Ul>
        <Li>이용자 및 법정대리인은 언제든지 수집 정보에 대하여 수정, 동의철회, 삭제 등을 요청하실 수 있습니다. 다만, 동의철회, 삭제 시 서비스의 일부 또는 전부 이용이 제한될 수 있습니다.</Li>
        <Li>(조회/수정 및 정정) 1:1 상담을 이용하여 처리할 수 있습니다. 처리가 완료될 때까지 해당 정보를 이용하거나 타인에게 제공하지 않을 것입니다. 또한 합리적인 사유로 잘못된 개인정보를 제3자에게 이미 제공한 경우, 정정처리의 결과를 지체없이 제3자에게 통지하여 정정하도록 조치합니다.</Li>
        <Li>(동의철회/삭제) 1:1 상담을 이용하여 수집 정보에 대한 동의철회 및 삭제를 요청하실 수 있습니다. 다만, 수집정보에 대한 동의철회, 삭제 시 서비스의 일부 또는 전부 이용이 제한될 수 있으며, 다른 법령에 따라 수집하는 정보의 경우에는 동의철회가 어려울 수 있습니다.</Li>
        <Li>(회원탈퇴) 1:1 상담을 이용하여 탈퇴가 가능합니다. *광고수익 참여권 및 캐시쉬를 보유하고 계신 경우, 마켓을 통해 광고수익 참여권 판매 및 보유 캐시쉬 금액출금 처리 완료 후 탈퇴 진행 가능합니다.</Li>
        <Li>(서비스 재이용) 가입 여부 확인 문의는 고객센터 [070-8065-3365]를 이용하시기 바랍니다.</Li>
        <Li>(상담/문의) 회사는 개인정보의 수집 및 이용 목적에 따라 이용자와 상담원 간의 통화내용을 녹음할 수 있으며 고객센터를 통한 소비자 상담/문의 시 녹음 등에 대한 안내는 고객센터 상담 전 안내 멘트를 통해 확인하실 수 있습니다.</Li>

      </Ul>
      <Text>다만, 개인정보 열람 및 처리정지 요구는 「개인정보보호법」 제35조 제4항, 제37조 제2항에 의하여 이용자의 권리가 제한될 수 있습니다. 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에 그 삭제를 요구할 수 없습니다. 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체없이 통지하여 정정이 이루어지도록 하겠습니다.</Text>
    <Li>법정대리인의 권리</Li>
    <Text>이용자의 권리는 이용자의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 행사할 수 있습니다. 이 경우 「개인정보 처리 방법에 관한 고시(제2020-7호)」[별지 제11호 서식]에 따른 위임장을 제출하셔야 합니다. 회사는 이용자의 권리에 따른 열람의 요구, 정정ㆍ삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지 확인합니다.</Text>
  </Ul>

  <SubTitle>제6조 (이용자 및 법정대리인의 권리ㆍ의무와 행사방법)</SubTitle> 
  <Ul>
    <Li>회사는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.</Li>
    <Li>쿠키는 앱을 운영하는데 이용되는 서버(http)가 이용자의 디바이스 브라우저에게 보내는 소량의 정보이며 이용자의 디바이스에 저장되기도 합니다.</Li>
    <Li>쿠키는 이용자의 접속빈도, 방문시간 등을 분석, 이용자의 취향과 관심분야를 파악 및 자취 추적, 각종 이벤트 참여 정도 및 방문회수 파악 등을 통한 타겟 마케팅 및 개인 맞춤 서비스 제공에 사용되며, 이용자는 이를 거부할 수 있습니다. 그러나 이를 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.</Li>
  </Ul>

  <SubTitle>제7조 (행태정보의 수집ㆍ이용ㆍ제공 및 거부 등에 관한 사항)</SubTitle>
  <Ul>
    <Li>회사는 서비스 이용과정에서 이용자에게 최적화된 맞춤형 서비스 및 혜택, 온라인 맞춤형 광고 등을 제공하기 위해 행태정보를 수집·이용하고 있습니다.</Li>
    <Li>회사는 다음과 같이 행태정보를 수집합니다.</Li>
    <Ul>
      <Li>수집하는 행태정보 항목</Li>
      <NoneUl>
        <Li>-  이용자의 웹사이트/앱 서비스 방문이력</Li>
        <Li>-  이용자의 웹사이트/앱 서비스 검색이력</Li>
        <Li>-  이용자의 웹사이트/앱 서비스 설치이력</Li>
        <Li>-  기타 규제기관 가이드라인에 따라 행태정보로 인정되는 온라인상의 이용자 활동정보</Li>
      </NoneUl>
      <Li>행태정보 수집 방법</Li>
    <NoneUl>
      <Li>-  이용자의 웹 사이트 및 앱 방문/실행시 생성 정보 수집 툴(구글애널리틱스)을 통한 자동 수집</Li>
      <Li>-  이용자의 정보를 저장하고 수시로 찾아내는 쿠키(cookie)를 설치 및 운용</Li>
      <Li>-  제휴사로부터 제휴사가 직접 수집한 행태정보를 제공 받거나 제휴사로부터 제휴사 </Li>
      <Li>-  사이트 내 행태정보 수집을 허용받아 수집 툴 등을 통해 자동 수집 전송</Li>
      <Li>-  기타 관련 법령 및 가이드라인에 따라 허용된 방법</Li>
    </NoneUl>
      <Li>행태정보 수집 목적</Li>  
      <NoneUl>
        <Li>-  이용자들이 이용한 회사의 각 서비스와 이용형태 등을 분석하여 이용자에게 최적화된 개인 맞춤형 상품 추천 서비스(광고포함)를 제공</Li>
        <Li>-  개인의 관심 분야에 따라 차별화된 정보 및 개인 맞춤 서비스 제공</Li>
        <Li>-  기타 이용자의 행태정보를 분석하여 신상품 개발 및 서비스 개편의 척도로 활용</Li>
      </NoneUl>
      <Li>보유ㆍ이용기간 및 이후 정보처리 방법</Li>
      <Text style={{justifyContent:"left"}}>수집일로부터 2년 간 보유하며, 그 후 파기합니다.</Text>
      
    </Ul>
    <Li>회사는 다음과 같이 제3자(온라인 맞춤형 광고 사업자 등)가 행태정보를 수집ㆍ처리하도록 허용하고 있습니다.</Li>
    <Li>회사는 개인 맞춤형 서비스, 온라인 맞춤형 광고 등에 필요한 최소한의 행태정보만을 수집하며, 사상, 신념, 가족 및 친인척관계, 학력·병력, 기타 사회활동 경력 등 개인의 권리·이익이나 사생활을 뚜렷하게 침해할 우려가 있는 민감한 행태정보를 수집하지 않습니다.</Li>
    <Li>회사는 만 14세 미만임을 알고 있는 아동이나 만14세 미만의 아동을 주 이용자로 하는 온라인 서비스로부터 맞춤형 광고 목적의 행태정보를 수집하지 않고, 만 14세 미만임을 알고 있는 아동에게는 맞춤형 광고를 제공하지 않습니다.</Li>
    <Li>회사는 모바일 앱에서 온라인 맞춤형 광고를 위하여 광고식별자를 수집·이용합니다. 이용자는 모바일 단말기의 설정 변경을 통해 회사가 제공하는 개인 맞춤형 서비스, 온라인 맞춤형 광고를 수신거부·차단·허용할 수 있습니다.</Li>
      <Ul>
        <Li>안드로이드</Li>
        <Text style={{justifyContent:"left"}}>-  설정 ⇒ 개인정보보호 ⇒ 광고 ⇒ 광고 ID 재설정 또는 광고 ID 삭제</Text>
        <Li>아이폰</Li>
        <Text style={{justifyContent:"left"}}>-  설정 ⇒ 개인정보보호 ⇒ 추적 ⇒ 앱이 추적을 요청하도록 허용 끔</Text>
        <SmallText style={{justifyContent:"left"}}> * 모바일 OS 버전에 따라 메뉴 및 방법이 다소 상이할 수 있습니다.</SmallText>
      </Ul>
    <Li>이용자는 웹브라우저의 쿠키 설정 변경 등을 통해 회사가 제공하는 개인 맞춤형 서비스, 온라인 맞춤형 광고를 일괄적으로 차단·허용할 수 있습니다. 다만, 쿠키 설정 변경은 웹사이트 자동로그인 등 일부 서비스의 이용에 영향을 미칠 수 있습니다.</Li>
     <Ul>
      <Li>인터넷 익스플로러(Windows 10용 Internet Explorer 11)</Li>
      <Text style={{justifyContent:"left"}}>-  Internet Explorer에서 도구 버튼을 선택한 다음 인터넷 옵션을 선택</Text>
      <Text style={{justifyContent:"left"}}>-  개인 정보 탭을 선택하고 설정에서 고급을 선택한 다음 쿠키의 차단 또는 허용을 선택</Text>
      <Li>Microsoft Edge</Li>
      <Text style={{justifyContent:"left"}}>-  Edge에서 오른쪽 상단 ‘⋯’ 표시를 클릭한 후, 설정을 클릭</Text>
      <Text style={{justifyContent:"left"}}>-  설정 페이지 좌측의 ‘개인정보, 검색 및 서비스’를 클릭 후 「추적방지」 섹션에서 ‘추적방지’ 여부 및 수준을 선택</Text>
      <Text style={{justifyContent:"left"}}>-  ‘InPrivate를 검색할 때 항상 “엄격” 추적 방지 사용’ 여부를 선택</Text>
      <Text style={{justifyContent:"left"}}>-  아래 「개인정보」 섹션에서 ‘추적 안함 요청보내기’ 여부를 선택</Text>
      <Li>크롬 브라우저</Li>
      <Text style={{justifyContent:"left"}}>-  Chrome에서 오른쪽 상단 ‘⋮’ 표시(chrome 맞춤설정 및 제어)를 클릭한 후, 설정 표시를 클릭</Text>
      <Text style={{justifyContent:"left"}}>-  설정 페이지 하단에 ‘고급 설정 표시’를 클릭하고 「개인정보」 섹션에서 콘텐츠 설정을 클릭</Text>
      <Text style={{justifyContent:"left"}}>-  쿠키 섹션에서 ‘타사 쿠키 및 사이트 데이터 차단’의 체크박스를 선택</Text>
      <Text style={{justifyContent:"left"}}>-  웹브라우저를 통한 맞춤형 광고 차단·허용</Text>
     </Ul>
     
     <Li>이용자는 아래의 연락처로 행태정보와 관련하여 궁금한 사항과 거부권 행사, 피해 신고 접수 등을 문의할 수 있습니다.</Li>
     <Text style={{justifyContent:"left"}}>개인정보보호 책임자: 최고경영자 최준영</Text>
     <Text style={{justifyContent:"left"}}>개인정보보호 담당자 : 개발팀장 우수몽</Text>
     <Text style={{justifyContent:"left"}}>연락처: (전화) 070-8015-2578 / (전자우편) support@ourtube.co.kr</Text>

    </Ul>

    <SubTitle>제8조 (개인정보의 기술적ㆍ관리적 보호조치)</SubTitle>
    <Text>회사는 이용자의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여, 「개인정보보호법」 제29조에 따라 다음과 같은 기술적ㆍ관리적 조치를 하고 있습니다.</Text>
    <Ul>
      <Li>개인정보를 안전하게 처리하기 위한 내부관리계획의 수립ㆍ시행</Li>
      <Li>개인정보에 대한 불법적인 접근을 차단하기 위한 침입차단시스템 등 접근 통제장치의 설치ㆍ운영</Li>
      <Li>접속기록의 위조ㆍ변조 방지를 위한 조치</Li>
      <Li>개인정보를 안전하게 저장ㆍ전송할 수 있는 암호화기술 등을 이용한 보안조치</Li>
      <Li>백신 소프트웨어의 설치ㆍ운영 등 컴퓨터바이러스에 의한 침해 방지조치</Li>

    </Ul>
      
    <SubTitle>제9조 (개인정보 보호책임자 및 담당자 안내)</SubTitle>
    <Text>회사는 개인정보처리에 관한 업무를 총괄해서 책임지고, 개인정보처리와 관련한 이용자의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.</Text>
    <Text style={{backgroundColor:"silver"}}>개인정보 보호책임자</Text>
    <Text2>최준영</Text2>
    <Text2>대표</Text2>
    <Text2>070-8065-3365, support@ourtube.co.kr</Text2>

    <Text>이용자는 회사의 서비스 등을 이용하시면서 발생한 모든 개인정보보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자에게 문의하실 수 있습니다. 회사는 이용자의 문의에 대해 지체없이 답변 및 처리해드릴 것입니다.</Text>

    <SubTitle>제10조 (권익 침해 구제방법)</SubTitle>
    <Text>이용자는 개인정보 침해로 인한 구제를 받기 위해 개인정보분쟁조정위원회, 한국인터넷진흥원, 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖의 신고, 상담에 대하여는 아래 기관에 문의하시기 바랍니다.</Text>

    <Text style={{justifyContent:"left"}}>-  개인정보 분쟁조정위원회: (국번없이)1833-6972, www.kopico.go.kr</Text>
    <Text style={{justifyContent:"left"}}>- 개인정보 침해신고센터: (국번없이)118, privacy.kisa.or.kr </Text>
    <Text style={{justifyContent:"left"}}>-  대검찰청 사이버범죄수사단: (국번없이)1301, www.spo.go.kr</Text>
    <Text style={{justifyContent:"left"}}>-  경찰청 사이버수사국: (국번없이)182, cyberbureau.police.go.kr</Text>

    <SubTitle>제11조 (개인정보처리방침 개정 및 고지의무)</SubTitle>
    <Text>본 개인정보처리방침의 내용 추가, 삭제 및 수정이 있을 경우 개정 최소 7일 전에 사전공지를 할 것입니다. 다만, 수집하는 개인정보의 항목, 이용목적의 변경 등과 같이 이용자 권리의 중대한 변경이 발생할 때에는 최소 30일 전에 공지합니다. 본 개인정보처리방침은 2022. 2. 5 부터 적용됩니다.</Text>
</Container>
}

const Container = styled.div`
justify-content: center;
align-items: center;
width:60vw;
font-family: auto;
margin:0 auto;


@media (max-width: 800px) {
width: 100%;
margin:10px 0 10px 10px;
overflow: hidden;



}

`;

const TableContainer = styled.div`
@media (max-width: 800px) {
width: 90%;

}

`;

const TrContainer = styled.div`
@media (max-width: 800px) {
width: 100%;

}

`;

const MainTitle = styled.div`
margin: 50px 15px;
margin-bottom: 50px;
font-size: initial;
width: 135px;


`;

const Title = styled.h1`
display:flex;
margin: 30px;
margin-bottom: 50px;
justify-content:center;

@media (max-width: 800px) {
size:3px;

}
`;

const Text =styled.div`
display:flex;
margin: 15px 35px 15px 20px;
justify-content: center;
line-height:20px;
font-size:15px;

@media (max-width: 800px) {

  font-size:12px;
  }


`;

const Text2=styled.div`
display:flex;
margin: 0 35px 15px 20px;
justify-content: center;
line-height:10px;
font-size:15px;
`

const SmallText =styled.div`!important
display:flex;
margin: 15px 35px 15px 20px;
justify-content: center;
line-height:20px;
font-size:5px;

@media (max-width: 800px) {
margin:15px 15px;
line-height:20px;
font-size:10px;
}

`;

const SubTitle = styled.h2`
display:flex;
margin: 40px 35px 15px 20px;
font-size:20px;

@media (max-width: 800px) {
margin:15px 10px;
line-height:20px;
font-size:17px;
}
`;

const Ul = styled.ul`
list-style: decimal;
margin:15px 30px;



@media (max-width: 800px) {

margin:15px 15px;
line-height:20px;
font-size:12px;

}

`;

const SmallUl = styled.ul`
list-style: decimal;
margin:15px 15px;
font-size: 5px;  



@media (max-width: 800px) {

margin:15px 15px;
line-height:20px;
font-size:15px;
}

`;

const RedUl = styled.ul`
list-style: decimal;
margin:15px 15px;
color: red
`;

const NoneUl = styled.ul`
list-style: none;
margin:15px 15px;

@media (max-width: 800px) {
margin:15px 15px;
line-height:20px;
font-size:15px;
}
`;

const RomaUl = styled.ul`
list-style: lower-roman;
margin:15px 15px 15px 20px;
`;


const Li =styled.li`
margin: 15px 15px 15px 25px;
font-size: 15px;  


@media (max-width: 800px) {
margin:15px 15px;
line-height:20px;
font-size:12px;
}

`;

const SmallLi =styled.li`
margin: 15px 15px 15px 25px;
font-size: 12px;  


@media (max-width: 800px) {

margin:15px 15px;
line-height:20px;
font-size:10px;
}

`;

const CircleUl = styled.ul`
list-style: decimal;
margin:15px 15px;



@media (max-width: 800px) {

margin:15px 15px;

}


`;
export default PrivacyPolicyPage;