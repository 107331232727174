import React, { useEffect, useState } from "react";
import Page from "../../components/layout/Page";
import inAppImg from "../../assets/images/in_app_4.png";
import { ContentSection } from "../../components/ui/ContentSection";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import handleViewport from "react-in-viewport";
import { loadAppearAnimation } from "../../feature/loadAppearAnimation";
import { S } from "./AppDownload.style";
import AppButton from "../../components/ui/AppButton";
import IosConfirmModal from "../../components/ui/IosConfirmModal";
import {
  isAndroid,
  isIOS,
  isMobile,
  isSafari,
  isMacOs,
} from "react-device-detect";

const AppDownLoadPage = (props) => {
  const [animationActivated, setAnimationActivated] = useState(false);
  const [iosModalOpen, setIosModalOpen] = useState(false);
  const { inViewport, forwardedRef, enterCount } = props;

  useEffect(() => {
    loadAppearAnimation(inViewport, enterCount, setAnimationActivated);
  }, [enterCount, inViewport]);

  return (
    <Page
      id="info_app_page"
      backgroundColor="white"
      className="swiper-slide slide-6"
    >
      {/* <IosConfirmModal open={iosModalOpen} close={() => setIosModalOpen(false)}/> */}
      <ContentSection>
        <S.TopSizedBox />
        <S.LeadingTitle ref={forwardedRef} activate={animationActivated}>
          <S.Strong>지금 바로</S.Strong>
          <br />
          원하는 채널에 투자하고
          <br />
          수익금 받아가세요
        </S.LeadingTitle>
        <S.ButtonWrapper>
          <AppButton
            id={"click_download_bottom"}
            onClick={() => {
              if (isMobile && isIOS) {
                //ios mobile
                window.location.href =
                  "https://ourtube.onelink.me/AcWx/ksc83dbb";
              } else if (isMobile && isAndroid) {
                // Android
                window.location.href =
                  "https://ourtube.onelink.me/AcWx/ksc83dbb";
              } else if (isSafari) {
                // ios mobile safarri
                window.location.href =
                  "https://ourtube.onelink.me/AcWx/ksc83dbb";
              } else if (isMacOs) {
                // mac 웹
                window.location.href =
                  "https://apps.apple.com/kr/app/ourtube/id1579637479";
              } else {
                // 그 외 Web
                window.location.href =
                  "https://play.google.com/store/apps/details?id=com.ourstock.ourtube_app";
              }
            }}
          >
            앱 다운받기
          </AppButton>
        </S.ButtonWrapper>
        <S.MockUpWrapper activate={animationActivated}>
          <S.MockUp>
            <S.InApp src={inAppImg} />
          </S.MockUp>
        </S.MockUpWrapper>
      </ContentSection>
    </Page>
  );
};

export default handleViewport(AppDownLoadPage);
