import inAppImg1 from "../assets/images/in_app_1.png";
import inAppImg2 from "../assets/images/in_app_2.png";
import inAppImg3 from "../assets/images/in_app_3.png";
import investor1 from "../assets/images/investor_1.png";
import investor2 from "../assets/images/investor_2.png";
import supporter1 from "../assets/images/supporter_1.png";
import supporter2 from "../assets/images/supporter_2.png";
import supporter3 from "../assets/images/supporter_3.png";
import supporter4 from "../assets/images/supporter_4.png";
import supporter5 from "../assets/images/supporter_5.png";
import supporter6 from "../assets/images/supporter_6.png";
import supporter7 from "../assets/images/supporter_7.png";
import supporter8 from "../assets/images/supporter_8.png";


// eslint-disable-next-line import/no-anonymous-default-export
export default {

    // 서비스설명 #2 에서 사용되는 옵션 요소
    SERVICE_STEP: [
        {
            index: 0,
            content: "옥션 입찰",
            img: inAppImg1,
            key: "0101"
        },
        {
            index: 1,
            content: "상시거래",
            img: inAppImg2,
            key: "0110",
        },
        {
            index: 2,
            content: "수익금 받기",
            img: inAppImg3,
            key: "0111",
        },
    ],

    // 서비스설명 #2 슬라이더에서 사용되는 setting options
    SLIDER_SETTINGS: {
        infinite: true,
        speed: 250,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        arrow: false
    },

    // 법률 FQA #4
    FQA_LIST: [
        {
            id: "faq_ourtube",
            index: 0,
            question: "아워튜브에서 거래할 수 있는 것은\n무엇인가요?",
            answer: "아워튜브는 '유튜브채널 광고수익청구권' 거래를 중개합니다. 기존에 기업 단위 혹은 단순한 신뢰로 거래되고 수익을 공유하던 시장의 문제를, 아워튜브에서는 누구나 쉽고 빠르게 거래할 수 있도록 광고수익청구권을 앱 서비스에서 거래할 수 있게 하여 전문 지식이 없더라도 유튜브 채널에 투자할 수 있습니다.",

        },
        {
            id: "faq_profit",
            index: 1,
            question: "‘유튜브채널 광고수익’은 무엇인가요?",
            answer: "유튜브 채널에 업로드 된 영상에 유튜브가 할당한 광고로 발생한 수익금을 유튜브채널 광고수익이라고 합니다.\n" +
                "\n" +
                "예를 들어 a 유튜버가 운영하는 유튜브 채널 A에 업로드 된 모든 영상물들 중 수익 창출이 허가난 영상물을 1~100으로 100개라 할 경우 이 100개의 영상 컨텐츠에서 발생한 광고수익금을 유튜브채널 광고수익이라 합니다. 즉, 유튜브 채널 A에서 발생한 광고수익금을 유튜브채널 광고수익이라 합니다. 이때, 유튜브 프리미엄과 슈퍼챗 등의 후원, 유튜브 플랫폼 이외에 별도의 광고 계약 등은 포함되지 않습니다.",

        },
        {
            id: "faq_right",
            index: 2,
            question: "‘유튜브채널 광고수익청구권’은\n무엇인가요?",
            answer: "컨텐츠에서 발생한 광고수익금 일부에 대한 소유를 인정하는 권리를 유튜브채널 광고수익청구권이라 합니다.",

        },
        {
            id: "faq_trade",
            index: 3,
            question: "어떻게 구매 및 판매를 하나요?",
            answer: "로그인 후, 메뉴에서 '거래'를 클릭합니다.\n" +
                "\n" +
                "1. 구매 또는 판매 희망 채널을 선택하여 채널 상세 페이지로 이동합니다.\n" +
                "2. 다른 시청자들의 구매 희망 가격 또는 판매 희망 가격과 주문 수량 정보를 확인하고, '구매하기' 또는 '판매하기'를 선택합니다.\n" +
                "3. '주문 수량'과 '주문 캐시'를 입력하고 '총 주문 캐시'를 확인한 후 주문을 실행합니다.\n" +
                "4. 체결되지 않은 주문 내역은 '거래' → '미체결'에서 확인이 가능하며, 체결된 주문 내역은 '내정보' → '이용 내역' → '거래 내역'에서 확인 가능합니다.\n" +
                "\n" +
                "* 아워튜브에서는 원활한 거래 플랫폼의 유지를 위해 거래 금액의 1%(거래 금액 기준)의 거래 수수료를 부과하고 있으나 현재는 이벤트로 인해 1/4인 0.25%의 거래 수수료를 부과하고 있습니다.\n" +
                "* 국내 비거주자의 거래는 제한될 수 있습니다.",

        },
        {
            id: 'faq_tax',
            index: 4,
            question: "수익금에 대한 세금은 어떻게 되나요?",
            answer: "유튜브채널 광고수익청구권을 보유하시게 되면 매달 광고 수익금을 정산 받으실 때 & 구매한 금액보다 높은 가격으로 보유한 청구권을 판매했을 때 소득이 발생하게 되며, 두 가지 경우 모두 기타 소득으로 분류됩니다.\n" +
                "\n" +
                "기타 소득의 경우, 건 당 수입이 5만원 이하이고 동시에 연간 누적 총 기타 소득이 3백만원 이하일 시 분리과세를 선택할 수 있으며 분리과세를 선택하여 납세의무를 종결하실 수 있습니다.\n" +
                "다만 연간 총 기타소득이 300만원을 초과하는 경우에는 전체 금액에 대해 종합소득과세표준에 합산하여 매년 5월말까지 종합소득세 신고해야 합니다.\n" +
                "\n" +
                "아워튜브 이용시 세금이 발생하는 경우\n" +
                "1. 유튜브채널 광고수익금 분배 수익이 5만원 초과일 때 (매월)\n" +
                "2. 유튜브채널 광고수익청구권 거래 수익이 건당 5만원 초과일 때 (구매자 기준)",
        },
    ],

    PARTNER_LOGO_LIST: [
        investor1, investor2, supporter1, supporter2, supporter3, supporter4, supporter5, supporter6, supporter7, supporter8,
    ],

    // Footer > 투자 유의사항
    NOTICE: [
        {
            index: 0,
            content: "아워튜브에서 거래되는 광고수익청구권은 여러 요인에 의해 수익 발생 여부나 수익 규모가 달라지며 미래의 광고수익청구권 수익을 보장하지 않습니다",
        },
        {
            index: 1,
            content: "매월 정산되는 광고수익금과 별개로 광고수익청구권은 시세 변동에 따라 투자원금의 손실이 발생할 수 있습니다.",
        },
        {
            index: 2,
            content: "모든 투자에 대한 판단 및 책임은 투자자 본인에게 있습니다.",
        },
    ]
}

