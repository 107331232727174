import { setDoc, doc } from 'firebase/firestore/lite';
import { db } from '../firebase.js';
import { ChannelEntity } from './entity/ChannelEntity.js';
import channelListJson from '../assets/datas/youtuber_list.json';
import categoryIndexListJson from '../assets/datas/category_index_list.json';

/// 주의 : 아래 함수는 Firebase의 doc 문서명(pathSegments)을 만들기 위해 쓰인다.
/// 이 때, 문서명에 '/'가 들어가면 경로로 인식하기 때문에 문서명에는 '/'를 쓰면 안 된다.
function currentDateTimeStr() {
    let today = new Date();
    let year = today.getFullYear(); // 년도
    let month = today.getMonth() + 1;  // 월
    let date = today.getDate();  // 날짜
    let hours = today.getHours(); // 시
    let minutes = today.getMinutes();  // 분
    let seconds = today.getSeconds();  // 초
    let milliseconds = today.getMilliseconds(); // 밀리초

    return year + '-' + month + '-' + date + '_' + hours + ':' + minutes + ':' + seconds + ':' + milliseconds;
}

function shuffleList(array) {
    array.sort(() => Math.random() - 0.5);
}

function randomIndex(arrayLength) {
    // 0 <= random <= endNum
    return Math.floor(Math.random() * arrayLength);
}

// 각 카테고리별로 1개씩, 랜덤한 채널 리스트를 얻어옴.
export function getChannelList() {
    // 1. JSON에서 원본 데이터를 읽음
    const { beauty: beautyList, cook: cookList, gamevlog: gamevlogList, investment: investmentList, movie: movieList, sports: sportsList } = categoryIndexListJson;
    const { data: channelList } = channelListJson;

    // 2. 각 카테고리별로 채널 1개씩을 뽑는다.
    const beautyChannelData = channelList[beautyList[randomIndex(beautyList.length)]];
    const cookChannelData = channelList[cookList[randomIndex((cookList.length))]];
    const gamevlogChannelData = channelList[gamevlogList[randomIndex(gamevlogList.length)]];
    const investmentChannelData = channelList[investmentList[randomIndex(investmentList.length)]];
    const movieChannelData = channelList[movieList[randomIndex(movieList.length)]];
    const sportsChannelData = channelList[sportsList[randomIndex(sportsList.length)]];

    // 3. 2에서 뽑은 채널을 ChannelEntity로 변환한다.
    const beautyChannel = new ChannelEntity(beautyChannelData['name'], beautyChannelData['profile'], beautyChannelData['subscriberCountAfter'],
        beautyChannelData['subscriberCountBefore'], beautyChannelData['subscriberChangeRate'], beautyChannelData['viewCountAfter'],
        beautyChannelData['viewCountBefore'], beautyChannelData['viewCountChangeRate'], beautyChannelData['profitPerOneStock'], beautyChannelData['channelValue']);
    const cookChannel = new ChannelEntity(cookChannelData['name'], cookChannelData['profile'], cookChannelData['subscriberCountAfter'],
        cookChannelData['subscriberCountBefore'], cookChannelData['subscriberChangeRate'], cookChannelData['viewCountAfter'],
        cookChannelData['viewCountBefore'], cookChannelData['viewCountChangeRate'], cookChannelData['profitPerOneStock'], cookChannelData['channelValue']);
    const gamevlogChannel = new ChannelEntity(gamevlogChannelData['name'], gamevlogChannelData['profile'], gamevlogChannelData['subscriberCountAfter'],
        gamevlogChannelData['subscriberCountBefore'], gamevlogChannelData['subscriberChangeRate'], gamevlogChannelData['viewCountAfter'],
        gamevlogChannelData['viewCountBefore'], gamevlogChannelData['viewCountChangeRate'], gamevlogChannelData['profitPerOneStock'], gamevlogChannelData['channelValue']);
    const investmentChannel = new ChannelEntity(investmentChannelData['name'], investmentChannelData['profile'], investmentChannelData['subscriberCountAfter'],
        investmentChannelData['subscriberCountBefore'], investmentChannelData['subscriberChangeRate'], investmentChannelData['viewCountAfter'],
        investmentChannelData['viewCountBefore'], investmentChannelData['viewCountChangeRate'], investmentChannelData['profitPerOneStock'], investmentChannelData['channelValue']);
    const movieChannel = new ChannelEntity(movieChannelData['name'], movieChannelData['profile'], movieChannelData['subscriberCountAfter'],
        movieChannelData['subscriberCountBefore'], movieChannelData['subscriberChangeRate'], movieChannelData['viewCountAfter'],
        movieChannelData['viewCountBefore'], movieChannelData['viewCountChangeRate'], movieChannelData['profitPerOneStock'], movieChannelData['channelValue']);
    const sportsChannel = new ChannelEntity(sportsChannelData['name'], sportsChannelData['profile'], sportsChannelData['subscriberCountAfter'],
        sportsChannelData['subscriberCountBefore'], sportsChannelData['subscriberChangeRate'], sportsChannelData['viewCountAfter'],
        sportsChannelData['viewCountBefore'], sportsChannelData['viewCountChangeRate'], sportsChannelData['profitPerOneStock'], sportsChannelData['channelValue']);

    // 4. 만든 ChannelEntity들을 하나의 리스트에 넣고, 이를 섞은 뒤 return
    const resultList = [beautyChannel, cookChannel, gamevlogChannel, investmentChannel, movieChannel, sportsChannel];
    shuffleList(resultList);
    return resultList;
}

// term에 해당하는 채널의 리스트를 얻어옴.
export function searchChannelList(term) {
    // 0. 검색어가 비어 있으면 빈 배열 리턴
    if(term.replace(' ', '').length === 0) {
        return [];
    }

    // 1. JSON에서 원본 데이터를 읽음
    const { data: channelList } = channelListJson;

    // 2. 검색 & 결과 리턴
    // (ChannelEntity로 mapping해주어야 한다.)
    return channelList.filter(e => e.name.toLowerCase().includes(term.toLowerCase())).map(data => new ChannelEntity(
        data['name'], data['profile'], data['subscriberCountAfter'],
        data['subscriberCountBefore'], data['subscriberChangeRate'], data['viewCountAfter'],
        data['viewCountBefore'], data['viewCountChangeRate'], data['profitPerOneStock'], data['channelValue']
    ));
}

// 유저가 term으로 검색한 이력을 남김
/// 주의 : increment() 함수를 이용해서 카운터 형식으로 구현 가능하나, Firestore의 document 업데이트는 1초에 최대 1번만 가능하다.
///       이 때문에, 동시성에 문제가 생길 수 있어서 '검색 이력' 형식으로 구현했다.
///       (추가하자면, document의 업데이트 주기를 늘리는 방법이 있긴 하다. 근데 복잡하고 불필요해보여서 더 쉬운 방법으로 구현했다.)
export async function writeSearchHistory(term) {
    if(term.replace(' ', '').length !== 0) {
        const termDoc = doc(db, 'search', currentDateTimeStr());

        await setDoc(termDoc, {
            term: term,
        });
    }
}

// 유저가 추가 요청한 채널 정보를 저장함
/// 주의 : increment() 함수를 이용해서 카운터 형식으로 구현 가능하나, Firestore의 document 업데이트는 1초에 최대 1번만 가능하다.
///       이 때문에, 동시성에 문제가 생길 수 있어서 '검색 이력' 형식으로 구현했다.
///       (추가하자면, document의 업데이트 주기를 늘리는 방법이 있긴 하다. 근데 복잡하고 불필요해보여서 더 쉬운 방법으로 구현했다.)
export async function saveNewChannelName(channelName) {
    const newChannelDoc = doc(db, 'new-channel', currentDateTimeStr());

    await setDoc(newChannelDoc, {
        name: channelName,
    });
}
