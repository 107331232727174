import React, {useEffect, useState} from 'react';
import Page from "../../components/layout/Page";
import {ContentSection} from "../../components/ui/ContentSection";
import constants from "../../utils/constants";
import AppModal from "../../components/ui/AppModal";
import handleViewport from "react-in-viewport";
import {loadAppearAnimation} from "../../feature/loadAppearAnimation";
import {S, SC} from "./FAQ.style";


const FAQPage = (props) => {
    const [animationActivated, setAnimationActivated] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [fqaIndex, setFqaIndex] = useState(0);

    const {inViewport, forwardedRef, enterCount} = props;

    useEffect(()=> {
         loadAppearAnimation(inViewport, enterCount, setAnimationActivated);
    },[enterCount,inViewport]);

    // Dialog 노출 여부 변경
    const toggleDialog = () => {
        setShowDialog(!showDialog);
    }

    // Dialog props index 값 변경
    const handleFqaOption = (index) => {
        setFqaIndex(index);
        setShowDialog(!showDialog);
    };

    return <Page id="faq_page" backgroundColor="white" className="swiper-slide slide-4">
        <AppModal isOpen={showDialog} closeModal={toggleDialog} title={constants.FQA_LIST[fqaIndex].question}
                  content={constants.FQA_LIST[fqaIndex].answer}/>
        <S.ContentSection>
            {/*<S.TopSizedBox/>*/}
            <S.LeadingTitle ref={forwardedRef} activate ={animationActivated}>아워튜브에<br/>궁금한게 있으신가요?</S.LeadingTitle>
            <S.QuestionList activate={animationActivated}>
                {constants.FQA_LIST.map((e) => <QuestionItemBtn key={e.index} element={e} handleFqaOption={handleFqaOption}/>)}
            </S.QuestionList>
        </S.ContentSection>

    </Page>
}



const QuestionItemBtn = (props) => {

    const {id, element, handleFqaOption} = props;
    return <SC.Container className={(matchMedia('only screen and (max-width: 768px)').matches) ? '' : 'swiper-no-swiping'}>
        <SC.Wrapper>
            <SC.Button id={id} onClick={(e) => {
                e.preventDefault();
                handleFqaOption(element.index);
            }} variant="text">{element.question}</SC.Button>
            <SC.ArrowIcon name="arrowRight"/>
        </SC.Wrapper>
        <SC.Divider mustShow={4 !== element.index}/>
    </SC.Container>
}




export default handleViewport(FAQPage);