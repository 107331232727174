import React from 'react';
import styled from "styled-components";
import "../Footer/Marketing.css";

const MarketingPolicyPage = () => {

  return <Container>
  <Title>마케팅 정보 수신동의 확인안내</Title>
  <Text>이용자는 마케팅 정보 수신에 동의함으로써 전자적 전송매체(SMS/MMS/이메일/앱 푸쉬 등)를
        통해 주식회사 아워튜브(이하 “회사”)가 제공하는 각종 혜택 정보, 이벤트 정보, 신규 서비스 안내
        등의 마케팅 정보를 수신하실 수 있습니다. 단, 광고성 정보 이외 의무적으로 안내되어야 하는
        정보성 내용은 수신동의 여부와 무관하게 제공됩니다.
        본 마케팅 정보 수신동의 항목은 선택사항이므로 동의를 거부하는 경우에도 회사의 서비스의
        이용에는 영향이 없습니다. 다만 거부시 동의를 통해 제공 가능한 각종 혜택, 이벤트 안내를
        등을받아 보실 수 없습니다.
        본 수신 동의를 철회하고자 할 경우에는 고객센터를 통해 언제든 수신동의 철회를 요청하실 수
        있습니다.
  </Text>

</Container>
}


const Container = styled.div`
width:100%;
font-family: auto;
text-align: -webkit-center;


@media (max-width: 800px) {
width: 100vw;
margin:0;
}

`;


const MainTitle = styled.div`
margin: 50px 15px;
margin-bottom: 50px;
font-size: initial;
width: 135px;


`;

const Title = styled.h1`
display:flex;
margin: 60px;
margin-bottom: 50px;
justify-content:center;
marginLeft:8px;
font-size:25px;

@media (max-width: 800px) {
font-size: 20px;
margin:30px 20px 30px 30px;
line-height:20px;

}


`;

const Text =styled.div`
display:flex;
line-height:40px;
font-size:15px;
width: 70vw;
text-aling:center;


@media (max-width: 800px) {
font-size: 10px;
margin:15px 20px 15px 30px;
line-height:20px;

}


`;


export default MarketingPolicyPage;