import React, {useEffect, useState} from "react";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "./styles/swiper.css"
// Import Swiper React components

import{ Mousewheel, Keyboard } from "swiper";

import ServiceMainIntroductionPage from "./pages/ServiceMainIntroduction/ServiceMainIntroductionPage";
import {Window} from "./components/layout/Window";
import "./styles/global.css";
import ServiceFlowPage from "./pages/ServiceFlow/ServiceFlowPage";
import ServiceClosingIntroductionPage from "./pages/ServiceClosingIntroduction/ServiceClosingIntroductionPage";
import FAQPage from "./pages/FAQ/FAQPage";
import AppDownloadPage from "./pages/AppDownload/AppDownloadPage";
import PartnerPage from "./pages/Partner/PartnerPage";
import Footer from "./pages/Footer/Footer";
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import Policy from "./pages/Footer/PolicyPage";
import DemoInvestmentPage from "./pages/DemoInvestment/DemoInvestmentPage";
import {render} from "react-dom";
import PrivacyPolicyPage from "./pages/Footer/PrivacyPolicy";
import MarketingPolicy from "./pages/Footer/MarketingPolicy";
import ReactGA from "react-ga4";



/**
 * React App 시작점.
 * FullPageScrollView(Swiper) 레이아웃 적용..
 * */

ReactGA.initialize("G-ZWQ1FMRX0G");
ReactGA.send("ARANG12");


const App = () => {
    function setScreenSize() {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
      }
      useEffect(() => {
        setScreenSize();
      });

    let [mySwiper, setMySwiper] = useState(null);
    let [index, setIndex] = useState(0);



    const aioAlert = () => {
        alert(mySwiper);
    };


    const slideToFAQ = () => {
        mySwiper.slideTo(4);
    };


    const slides = [
        <ServiceMainIntroductionPage key={"section0"}/>,
        <ServiceFlowPage key={"section1"}/>,
        <ServiceClosingIntroductionPage key={"section2"}/>,
        <FAQPage key={"section3"}/>,
        <PartnerPage key={"section4"}/>,
        <AppDownloadPage key={"section5"}/>,
        <Footer key={"section6"}/>
    ]

    // <DemoInvestmentPage parentSlider={mySwiper} go={slideToFAQ}/>,


    return (
        <Window>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={
                        <Swiper
                            slidesPerView={1} 
                            direction={"vertical"}
                            mousewheel 
                            keyboard 
                            modules={[ Mousewheel, Keyboard]} 
                            speed={500} 
                            onSwiper={(mySwiper)=> setMySwiper(mySwiper)}  
                          
                            >
                                
                            <SwiperSlide> 
                                {/*가로 슬라이드*/}
                                <DemoInvestmentPage parentSlider={mySwiper} go={slideToFAQ}/>         
                                    
                            </SwiperSlide>
                                 {/*세로 슬라이드*/}
                                {slides.map((slide) => (<SwiperSlide>{slide}</SwiperSlide>))}
                                      
                        </Swiper>
                        }/>
                    <Route path="mainPolicy" element={<Policy/>}/>
                    <Route path="privacy" element={<PrivacyPolicyPage/>}/>
                    <Route path="marketing" element={<MarketingPolicy/>}/>
                </Routes>
            </BrowserRouter>
        </Window>
    );
};


const rootElement = document.getElementById('root');
render(
    <App/>,
    rootElement
);
