import * as React from 'react';
import { styled } from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import SvgIcon from '@mui/material/SvgIcon';
import TextField from '@mui/material/TextField';
import * as props from "../../styles/theme";
import { lighten } from "@material-ui/core";
import {useCallback} from "react";
import {debounce} from "../../utils/debounce";

const AppSearchBarBase = styled((otherProps) => (<TextField
    InputProps={{
        disableUnderline: true,
        startAdornment: (
            <InputAdornment position="start">
                <SvgIcon sx={{ width: '24px', height: '24px' }}>
                    <circle cx="11" cy="11" r="6" stroke="#4A4D57" strokeWidth="2" fill='none'/>
                    <path d="M16 16L19 19" stroke="#4A4D57" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </SvgIcon>
            </InputAdornment>
        ),
        style: {
            /* Body/2_Medium 14 */
            fontSize: '14px',
            lineHeight: '22px',
            fontFamily: 'PretendardMedium, serif',
            letterSpacing: '-0.6px',
            /* Greyscale/Black */
            color: props.theme.colors.grey90,
        }
    }}
    {...otherProps}
/>))
(({ theme }) => ({
    "& .MuiFilledInput-root": {
        border: "0",
        height: "48px",
        width: "55.2rem",
        "@media (max-width: 768px)": {  // textOverflow 때문에 line break 필요
            width: "100%",
        },
        borderRadius: "12px",
        // Background/BlueGrey 10
        backgroundColor: props.theme.colors.blueGrey10,
        "&:hover": {
            backgroundColor: lighten(props.theme.colors.blueGrey10, 0.1)
        },
        "& input::placeholder": {
            // Body/2_Medium 14
            fontSize: '14px',
            lineHeight: '22px',
            fontFamily: 'PretendardMedium, serif',
            letterSpacing: '-0.6px',
            // Greyscale/20
            color: props.theme.colors.grey20,
        }
        /*'&.Mui-focused': {
            backgroundColor: 'transparent',
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
            borderColor: theme.palette.primary.main,
          },*/
    }
}));

export default function AppSearchBar({setSelectedChannelIndex,value, setValue, onChange}) {
    const printValue = useCallback(
        debounce((value) => onChange(value), 500),
        []
    );

    const handleChange = (e) => {
        printValue(e.target.value);
        setValue(e.target.value);
    };

    const handleKeyDown = event => {
        if (event.key === 'Backspace') {
            setSelectedChannelIndex(null);
        }
    };


    return (
        <AppSearchBarBase
            id="AppSearchBarBase"
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            value={value}
            placeholder="채널명 입력"
            hiddenLabel
            fullWidth
            variant="filled"
        />
    );
}