import styled from "styled-components";

export const ContentSection = styled.section`
  width: ${props => props.width};
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  
  @media ${({theme}) => theme.device.contentHeight} {
    justify-content: center;
  }
`;