import styled from "styled-components";
import {fontTheme} from "../../styles/fonts";
import Icon from "../../utils/icon";
import {Link} from "react-router-dom";

export const S = {
    TrailingWrapper: styled.div`
      display: flex;
      flex-shrink: 1;

      @media screen and (max-width: 1240px) {
        flex-direction: column;
      
      }

      
    `,

    ActionBtnContainer: styled.div`
      display: flex;
      flex-direction: column;
      margin: 0 0 0 -90px;
      margin-left:50px;
      flex-shrink: 2;

      @media screen and (max-width:1350px) {                 
        width: 100%
      };


      @media screen and (max-width: 1240px) {
      margin-top : 30px;
      margin-right: 5px;
      };

      @media ${({theme}) => theme.device.mobile} {
        margin: 24px 0 0 0px;
      };
    `,

    ButtonWrapper: styled.div``,

    AnkerBtn: styled.a`
      text-decoration: ${props => props.showUnderline ? "underline" : "none"};
      background-color: transparent;
      color: ${props => props.theme.colors.grey40};
      cursor: pointer;
      white-space: nowrap;
      ${fontTheme.alert1};
    
    `,

    Button: styled.a`
      text-decoration: ${props => props.showUnderline ? "underline" : "none"};
      background-color: transparent;
      color: ${props => props.theme.colors.grey40};
      cursor: pointer;
      white-space: nowrap;
      ${fontTheme.alert1};
    `,

    ListWrapper: styled.ol`
      margin-left: 16px;
    `,
    Item: styled.li`
      color: ${props => props.theme.colors.grey40};
      ${fontTheme.alert2};
    `,

    Row: styled.div`
      margin-top: ${props => props.marginTop};
      display: inline-flex;
      align-items: center;
      z-index:100;
     
    `,

    NoticeContainer: styled.div`
      margin: 0 0 0 45px;
      width: 490px;
      flex-shrink: 1;

      @media screen and (max-width:1350px) {                 
        width:100%
      };

      @media ${({theme}) => theme.device.tablet} {
        width: 100%;
      };


      @media ${({theme}) => theme.device.mobile} {
        margin: 24px 0 0 0;
      };
    `,

    Separator: styled.span`
      display: inline-block;
      height: 12px;
      width: 1px;
      margin: 0 8px;
      background-color: ${props => props.theme.colors.grey10};
    `,

    LeadingParagraph: styled.span`
      color: ${props => props.theme.colors.grey40};
      ${fontTheme.alert2};
      margin-bottom: 8px;
      display: block;
    `,

    Paragraph: styled.span`
      display: block;
      color: ${props => props.theme.colors.grey40};
      ${fontTheme.alert2};
    `,

    NoWrapParagraph: styled.span`
      display: block;
      white-space: nowrap;
      color: ${props => props.theme.colors.grey40};
      ${fontTheme.alert2};
    `,

    CompanyInfoContainer: styled.div`
    flex-shrink:2;
    
    `,

    ContentSection: styled.section`
      padding: 60px 60px 60px 0px;
      width: ${props => props.width};
      height: 100vh;
      display: flex;
      justify-content: start;
      margin: 10px 10px 10px 210px;
      

     

      @media ${({theme}) => theme.device.tablet} {
        padding: 46px 126px 46px 0px;
        margin-left:150px;
      
      };

      @media ${({theme}) => theme.device.mobile} {
        flex-direction: column;
        padding: 24px 16px 24px 16px;
        margin-left:0;
        
      };
    `,

    Logo: styled(Icon)`
      display: block;
      margin: 60px 60px -30px 205px;


      @media ${({theme}) => theme.device.tablet} {
        margin: 40px 60px 0px 140px;
      }

      @media ${({theme}) => theme.device.mobile} {
        margin: 24px 24px 0px 16px;
      };
      
      
    `,
};