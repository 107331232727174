import * as React from 'react';
import {styled as muiStyled} from '@mui/material/styles';
import styled from "styled-components";
import Button from '@mui/material/Button';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import * as props from "../../styles/theme";
import {darken} from 'polished';
import Icon from "../../utils/icon";


const TitleAppBarDownloadButtonBase = muiStyled(Button)(({theme}) => ({
    // 콘텐츠 정렬 방향
    display: 'flex',

    // 사이 간격
    justifyContent: 'space-between',
    alignItems: 'center',

    // 선 (1px, 색깔은 Greyscale/10
    border: `1px solid ${props.theme.colors.grey10}`,
    // 모서리 둥글게
    borderRadius: '12px',
    // 크기
    width: '108px',
    height: '32px',
    // padding
    padding: '8px 12px',

    /* 배경 색상 : Greyscale/white */
    // background: props.theme.colors.white,
    /* 이벤트 */
    '&:hover, &:active': {
        background: darken(0.1, `${props.theme.colors.white}`),
    },
}));

/*const StyledDownloadWrapper = styled.div`
  // Auto layout
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0;
  gap: 0.8rem;
`;*/

const TitleAppBarDownloadText = muiStyled(Typography)(({theme}) => ({
    textAlign: 'center',

    /* 텍스트 색상 : Greyscale/70 */
    color: props.theme.colors.grey70,
    /* 텍스트 스타일 : Body/3_Medium 13 */
    fontSize: '13px',
    lineHeight: '16px',
    fontFamily: 'PretendardMedium, serif',

    /* Inside auto layout */
    flex: 'none',
    order: 0,
    flexGrow: 0,

}));

const TitleAppBarDownloadIcon = muiStyled(SvgIcon)(({theme}) => ({
    width: '16px',
    height: '16px',
    color: props.theme.colors.grey70,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    order: 1,
    flexGrow: 0,
}));

const IconBtn = styled(Icon)`
`;

function AppDownloadButton({onClick}) {
    return (
        <TitleAppBarDownloadButtonBase id="click_download_top"  className={(matchMedia('only screen and (max-width: 768px)').matches) ? '' : 'swiper-no-swiping'}  onClick={onClick}>
            <TitleAppBarDownloadText>앱 다운로드</TitleAppBarDownloadText>
            <IconBtn name="download"/>
        </TitleAppBarDownloadButtonBase>
    );
}

export default AppDownloadButton;