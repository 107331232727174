import {css} from "styled-components";

/* Web */
const Web1 = css`
  font-size: 4.0rem;
  line-height: 5.2rem;
  font-family: "PretendardBold", serif;
`;

const Web2 = css`
  font-size: 3.6rem;
  line-height: 4.6rem;
  font-family: "PretendardBold", serif;
`;

const Web3 = css`
  font-size: 3.2rem;
  line-height: 4.8rem;
  font-family: "PretendardBold", serif;
`;


/* Headline */

const Headline1 = css`
  font-size: 2.4rem;
  line-height: 3.7rem;
  font-family: "PretendardBold", serif;
`


const Headline2 = css`
  font-size: 2.0rem;
  line-height: 3.0rem;
  font-family: "PretendardSemiBold", serif;
`

const Headline3 = css`
  font-size: 1.8rem;
  line-height: 2.2rem;
  font-family: "PretendardSemiBold", serif;
`

/* Title */
const Title1 = css`
  font-size: 1.6rem;
  line-height: 2.2rem;
  font-family: "PretendardBold", serif;
`

const Title2 = css`
  font-size: 1.6rem;
  line-height: 2.0rem;
  font-family: "PretendardSemiBold", serif;
`

const Title3 = css`
  font-size: 1.4rem;
  line-height: 2.0rem;
  font-family: "PretendardSemiBold", serif;
`

/* Body */
const Body1 = css`
  font-size: 1.4rem;
  line-height: 2.2rem;
  font-family: "PretendardSemiBold", serif;
`

const Body2 = css`
  font-size: 1.4rem;
  line-height: 2.2rem;
  font-family: "PretendardMedium", serif;
`

const Body3 = css`
  font-size: 1.3rem;
  line-height: 1.6rem;
  font-family: "PretendardMedium", serif;
`

const Alert1 = css`
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-family: "PretendardSemiBold", serif;
`;

const Alert2 = css`
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-family: "PretendardRegular", serif;
`;


export const remFontTheme = {
    // Web
    web1: Web1,
    web2: Web2,
    web3: Web3,

    // Headline
    headline3: Headline3,
    headline2: Headline2,
    headline1: Headline1,

    // Title
    title1: Title1,
    title2: Title2,
    title3: Title3,

    // Body
    body1: Body1,
    body2: Body2,
    body3: Body3,

    // Alert
    alert1: Alert1,
    alert2: Alert2,
}