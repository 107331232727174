import React from 'react';
import '../../styles/modal.css';
import styled from "styled-components";
import {fontTheme} from "../../styles/fonts";
import AppButton from "./AppButton";

/** Modal Dialog
 *  - 모달 다이어로그 노출 여부 , 다이어로그 닫기, 제목, 컨텐츠(본문)을 props로 전달 받음
 * */

const ChannelRequestComplishedDialog = (props) => {
    const {isOpen, closeModal} = props;

    return (
        <div onClick={() => closeModal()}
             className={isOpen ? 'openModal modal' : 'modal'}>
            {isOpen ? (
                <S.Container>
                    <S.ContentWrapper>
                        <S.Title >채널 요청이 완료됐어요</S.Title>
                        <S.Description>해당 해널이 반영되는 동안 다른 채널에 투자해보세요!</S.Description>
                    </S.ContentWrapper>
                    <AppButton onClick={() => closeModal()} id={"continue_invest_btn"}>투자하러 가기</AppButton>
                </S.Container>
            ) : null}
        </div>
    );
};

const S = {
    ContentWrapper: styled.div`


    `,

    Title: styled.h2`
      ${fontTheme.headline2};
      color: ${props => props.theme.colors.black};
      margin-bottom: 8px;
      text-align: start;
    `,

    Description: styled.span`
      ${fontTheme.body2};
      text-align: start;
      color: ${props => props.theme.colors.grey40};

    `,

    Container: styled.section`
      width: 327px;
      height: 176px;
      padding: 20px 24px 16px 24px;
      border-radius: 20px;
      background-color: #fff;
      position: relative;
      /* 팝업이 열릴때 스르륵 열리는 효과 */
      animation: modal-show 0.3s;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      margin: 0 auto;
    `,
}


export default ChannelRequestComplishedDialog;