import React from 'react';
import Box from "@mui/material/Box";
import {Dialog, Stack, SvgIcon} from "@mui/material";
import * as props from "../../styles/theme";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import AppLabel from "../../components/ui/AppLabel";
import close from "../../assets/images/icon_close_24.png";
import close2 from "../../assets/images/icon_close_c24.png";

export default function ProfitDetailDialog({open, onClose, adProfit, adProfitRate, principal, channelValue, tradingProfit, tradingProfitRate, adProfitLabelType, tradingProfitLabelType}) {
    return (
        <Dialog
            maxWidth={'md'}
            open={open}
            onClose={onClose}
            PaperProps={{
                style: {
                    borderRadius: '20px',
                    backgroundColor: props.theme.colors.white,
                    boxShadow: 'none',
                    minWidth: '327px',
                    minHeight: '490px',

                    paddingTop: '16px',
                    paddingBottom: '23px',
                },
            }}
        >
            <Box height='24px' alignItems='center' paddingRight='4px'>
                <Stack direction='row' justifyContent='flex-end'>
                    <img onClick={onClose} src={close} width='24px' height='24px' alt='close' />
                    <Box width='24px'/>
                </Stack>
            </Box>
            <Box height='8px'/>

            <Box
                borderRadius='16px'
                bgcolor={props.theme.colors.blueGrey10}
                textAlign='center'

                paddingTop='16px'
                paddingBottom='31px'
                marginRight='20px'
                marginLeft='20px'
            >
                <Typography sx={{
                    /* Body/1_Semibold 14 */
                    fontSize: '14px',
                    lineHeight: '22px',
                    fontFamily: 'PretendardSemiBold, serif',

                    alignItems: 'center',
                    textAlign: 'center',
                    letterSpacing: '-0.6px',

                    /* Greyscale/Black */
                    color: props.theme.colors.grey90,
                }}>
                    광고수익금
                </Typography>
                <Box height='28px'/>

                <AppLabel type={adProfitLabelType} text={adProfitRate}/>
                <Box height='12px'/>

                <Typography sx={{
                    /* Headline/1_Bold 24 */
                    fontSize: '24px',
                    lineHeight: '37px',
                    fontFamily: 'PretendardBold, serif',

                    textAlign: 'center',
                    letterSpacing: '-0.1px',

                    /* Greyscale/Black */
                    color: props.theme.colors.grey90,
                }}>
                    {adProfit}
                </Typography>
            </Box>

            <Box height='21px'/>

            <Box
                borderRadius='16px'
                bgcolor={props.theme.colors.blueGrey10}
                textAlign='center'

                paddingTop='16px'
                paddingBottom='23px'
                marginRight='20px'
                marginLeft='20px'
            >
                <Typography sx={{
                    /* Body/1_Semibold 14 */
                    fontSize: '14px',
                    lineHeight: '22px',
                    fontFamily: 'PretendardSemiBold, serif',

                    alignItems: 'center',
                    textAlign: 'center',
                    letterSpacing: '-0.6px',

                    /* Greyscale/Black */
                    color: props.theme.colors.grey90,
                }}>
                    채널가치
                </Typography>
                <Box height='32px'/>

                <Stack
                    direction='row'
                    justifyContent="center"
                    alignItems="center"
                >
                    <Typography sx={{
                        /* Headline/3_Semibold 18 */
                        fontSize: '18px',
                        lineHeight: '22px',
                        fontFamily: 'PretendardBold, serif',

                        textAlign: 'center',

                        /* Greyscale/40 */
                        color: props.theme.colors.grey40,
                    }}>
                        {principal}
                    </Typography>
                    <Box width='16px'/>
                    <SvgIcon width="22" height="8" viewBox="0 0 22 8">
                        <path d="M21.8536 4.35355C22.0488 4.15829 22.0488 3.84171 21.8536 3.64645L18.6716 0.464466C18.4763 0.269204 18.1597 0.269204 17.9645 0.464466C17.7692 0.659728 17.7692 0.976311 17.9645 1.17157L20.7929 4L17.9645 6.82843C17.7692 7.02369 17.7692 7.34027 17.9645 7.53553C18.1597 7.7308 18.4763 7.7308 18.6716 7.53553L21.8536 4.35355ZM0 4.5H21.5V3.5H0V4.5Z" fill="#969BAB"/>
                    </SvgIcon>
                    <Box width='16px'/>
                    <Typography sx={{
                        /* Headline/3_Semibold 18 */
                        fontSize: '18px',
                        lineHeight: '22px',
                        fontFamily: 'PretendardBold, serif',

                        textAlign: 'center',

                        /* Greyscale/40 */
                        color: props.theme.colors.grey40,
                    }}>
                        {channelValue}
                    </Typography>
                </Stack>
                <Box height='40px'/>

                <AppLabel type={tradingProfitLabelType} text={tradingProfitRate}/>
                <Box height='12px'/>

                <Typography sx={{
                    /* Headline/1_Bold 24 */
                    fontSize: '24px',
                    lineHeight: '37px',
                    fontFamily: 'PretendardBold, serif',

                    textAlign: 'center',
                    letterSpacing: '-0.1px',

                    /* Greyscale/Black */
                    color: props.theme.colors.grey90,
                }}>
                    {tradingProfit}
                </Typography>
            </Box>
        </Dialog>
    );
}