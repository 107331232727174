import React from 'react';
import '../../styles/global.css';
import {darken, lighten} from 'polished';
import styled from 'styled-components';
import refreshIcon from '../../assets/icons/icon_refresh_mono.svg';
import {remFontTheme} from "../../styles/remFonts";
import {theme} from "../../styles/theme";

const StyledRefreshIcon = styled.img`
  width: 2.0rem;
  height: 2.0rem;
  margin-right: 1.2rem;

    // @media ${({theme}) => theme.device.mobileS} {
  //   width: 14px;
  //   height: 14px;
  //   margin-right: 8px;
  // };


`;

const StyledRefreshButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #DCDEE5;
  width: 15.6rem;
  height: 4.0rem;
  border-radius: 7.9rem;
  cursor: pointer;
  

  /* 색상 */
  background: #FFFFFF;
  /* 이벤트 */

  &:hover {
    background: ${lighten(0.1, '#FFFFFF')};
  }

  &:active {
    background: ${darken(0.1, '#FFFFFF')};
  }

  /* 텍스트 스타일 */
  color: #5C606B; //Greyscale/60
  letter-spacing: -0.6px;
  ${remFontTheme.title2};


  margin-top: 2.0rem;
  margin-bottom: 2.0rem;

  @media ${({theme}) => theme.device.mobile} {
    margin: 10px auto;

    @media screen and (max-height: ${theme.breakPoint.firstHeightBreak}) {
      margin: 8px auto;
    }
    
  }
  
  
  





`;

function RefreshButton({onClick}) {
    return <StyledRefreshButton id="refresh_btn" className={(matchMedia('only screen and (max-width: 768px)').matches) ? '' : 'swiper-no-swiping'} onClick={onClick}>
        <StyledRefreshIcon src={refreshIcon}/>
        다른 채널 추천
    </StyledRefreshButton>;
}

export default RefreshButton;