import styled, {ThemeProvider} from "styled-components";
import {theme} from "../../styles/theme";


/** Created By Ximya - 2022.09.26
 *  모바일 웹 버전에서 safeArea 디바이스를 대응하기 위한 컴포넌트
 *  ThemeProvider 컴포넌트로 Global Theme 설정
 * */

export const Window = (props) => {
    return <ThemeProvider theme={theme}>
        <Layout>
            {props.children}
        </Layout>
    </ThemeProvider>

}

const Layout = styled.div`
  width: 100vw;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
`;

/*const PreventLandscape = styled.div`
  display: none;
  z-index: 100;
  position: fixed;

  @media (orientation: portrait) {
    display:block;
  }
`;*/
const PreventLandscape = styled.div`
  @media (orientation: landscape) and (max-width: 768px) {
    transform: rotateZ(90deg);
  }
`;