import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import * as props from "../../styles/theme";
import {darken, lighten} from "polished";

const AppButtonBase = styled(Button)(({ type, isActive, showShadow, isResponsive, theme }) => ({
    /* 색상 */
    background: isActive ? props.theme.colors.blue30 : props.theme.colors.blue10,

    /* 이벤트 */
    '&:hover': {
        background: isActive ? lighten(0.1, `${props.theme.colors.blue30}`) :lighten(0.0, `${props.theme.colors.blue10}`)
    },
    '&:active': {
        background: isActive ? darken(0.1, `${props.theme.colors.blue30}`) : darken(0.1, `${props.theme.colors.blue10}`),
    },

    /* 텍스트 스타일 */
    color: props.theme.colors.white,
    textAlign: 'center',
    letterSpacing: '-0.6px',
    /* Title/2_Semibold 16 */
    fontSize: '1.6rem',
    lineHeight: '2.0rem',
    fontFamily: 'PretendardSemiBold, serif',
    borderRadius: '12px',
    // ...(fontsTheme.title2),

    ...(
        isResponsive === false && {
                fontSize: '16px',
                lineHeight: '20px',


        }
    ),
    /* 크기 */
    ...(
        type === 'normal' && {
            width: '16.5rem',
            height: '4.8rem',
        }
    ),
    ...(
        type === 'full-width' && {
            width: '100%',
            height: '56px',
        }
    ),
    ...(
        type === 'dialog' && {
            width: '136px',
            height: '48px',
        }
    ),

    /* 그림자 */
    ...(
        showShadow === true && {
            boxShadow: '0 5.86471px 54px rgba(154, 170, 193, 0.18), 0 5px 29px 5px rgba(182, 191, 203, 0.14)',
        }
    )
}));


export default function AppButton({id,type, showShadow, isActive, children, onClick, isResponsive}) {
    return (
        <AppButtonBase className={(matchMedia('only screen and (max-width: 768px)').matches) ? '' : 'swiper-no-swiping'} id={id} isResponsive={isResponsive ?? true} onClick={onClick} type={type} isActive ={isActive === true && true} showShadow={showShadow}>{children}</AppButtonBase>
    );
}

AppButton.defaultProps = {
    type: 'normal',
    showShadow: false,
}