import React, {useEffect, useState} from 'react';
import handleViewport from "react-in-viewport";
import {loadAppearAnimation} from "../../feature/loadAppearAnimation";
import {S} from "./ServiceClosingIntroduction.style";
import Page from "../../components/layout/Page";





const ServiceClosingIntroductionPage = (props) => {
    const {inViewport, forwardedRef, enterCount} = props;
    const [animationActivated, setAnimationActivated] = useState(false);

    useEffect(() => {
        loadAppearAnimation(inViewport, enterCount, setAnimationActivated);
    }, [enterCount, inViewport]);

    return <Page id={"closing_page"} backgroundColor='white' className="swiper-slide slide-3">
        <S.ContentSection>
            <S.GradientOverlay>
                <S.LeadingTitle activate={animationActivated}><S.Strong>유튜브에
                    투자</S.Strong>하고<br/>매달 <S.Strong>수익금</S.Strong>을<br/>얻어보세요</S.LeadingTitle>
                <S.RefContainer ref={forwardedRef}/>
                <S.SubDescription activate={animationActivated}>'옥션'과 '거래'에서 광고수익청구권을 구매하면<br/>수익금을 받을 수
                    있습니다</S.SubDescription>
            </S.GradientOverlay>
        </S.ContentSection>
    </Page>
}


export default handleViewport(ServiceClosingIntroductionPage);