import React, {useContext, useState,} from "react";
import ChannelButtonGrid from "../../components/ui/ChannelButtonGrid";
import AppButton from "../../components/ui/AppButton";
import styled from "styled-components";
import {styled as muiStyled} from "@mui/material/styles";
import {remFontTheme} from "../../styles/remFonts";
import ChannelDetailInfoContainer from "../../components/ui/ChannelDetailinfoContainer";
import RefreshButton from "../../components/ui/refresh-button";
import {ChannelContext} from "../../context/channelContext";
import Page from "../../components/layout/Page";
import SearchChannelBtn from "../../components/ui/SearchChannelBtn";
import ScrollNotiBox from "../../components/ui/ScrollNotiBox";
import SearchChannelModal from "../../components/ui/SearchChannelModal";
import ChannelRequestComplishedDialog from "../../components/ui/ChannelRequestComplishedDialog";
import DemoPage from "../../components/layout/BackgroundPage";
import {theme} from "../../styles/theme";
import {Button} from "@mui/material";
import * as props from "../../styles/theme";
import {darken, lighten} from "polished";

const DemoInvestmentSubView1 = (props) => {
    const {
        refreshCount,
        selectedChannelIndex,
        channelList,
        refreshChannelList,
        goToNextPage,
        setSelectedChannelIndex,
        setTerm,
        setPopupSelectedChannelIndex,
        setPopupChannelList,
    } = useContext(ChannelContext);


    // 검색 페이지 열림 여부
    const [openSearch, setOpenSearch] = React.useState(false);
    const [openRequestIsDone, setOpenRequestIsDone] = useState(false);

    let arang = true;


    const closeEntireModal= () => {
        setOpenRequestIsDone(false);
        setOpenSearch(false);
    }


    return (
        <DemoPage id="invest_1_page" className="swiper-slide slide-2">
            <SearchChannelModal isOpen={openSearch} openRequestIsDoneModal={() => setOpenRequestIsDone(true)}
                                closeModal={() => setOpenSearch(false)} goToNextScreen={() => goToNextPage(1)}/>
            <ChannelRequestComplishedDialog isOpen={openRequestIsDone} closeModal={() => closeEntireModal()}/>
            <B.ContentWrapper>
                <B.ContentSection>
                    <B.LeftSection>
                        <LeadingTitle/>
                        <M.LeadingWrapper>
                            <B.AnimationBox activate={selectedChannelIndex === null}>
                                <MobileLeadingTitle/>
                            </B.AnimationBox>
                            <B.AnimationBox activate={selectedChannelIndex !== null}>
                                <ChannelDetailInfoContainer isUsedInMobile={true}/>
                            </B.AnimationBox>
                        </M.LeadingWrapper>
                        <RefreshButton id="refresh_btn" onClick={refreshChannelList}/>
                        <ChannelButtonGrid
                            selectedChannelIndex={selectedChannelIndex}
                            channelList={channelList}
                            setSelectedChannelIndex={setSelectedChannelIndex}
                        />
                        <B.Notice>실제 서비스에 입점하지 않은 채널이 포함되어 있습니다</B.Notice>
                    </B.LeftSection>
                    <B.RightSection>
                        <ChannelDetailInfoContainer isUsedInMobile={false}/>
                    </B.RightSection>
                </B.ContentSection>
            </B.ContentWrapper>
            <M.SearFloatBtnWrapper activate={refreshCount >= 3 && selectedChannelIndex === null}>
                <SearchChannelBtn onClick={() => {
                    setTerm(null);
                    setPopupSelectedChannelIndex(null);
                    setPopupChannelList([]);
                    setOpenSearch(true);
                }}/>
            </M.SearFloatBtnWrapper>
            <M.FloatBtnWrapper activate={selectedChannelIndex !== null}>    
                <AppButton id="invest_btn" onClick={() => {
                    setPopupSelectedChannelIndex(null);
                    goToNextPage(1);
                }}>투자하기</AppButton>
            </M.FloatBtnWrapper>
            <B.NotiContainer>
                <ScrollNotiBox isActive={false}/>
            </B.NotiContainer>
        </DemoPage>
    );
};


// Base Styled Component
const B = {
    Page: styled(Page)`
      position: relative;
    `,

    ContentWrapper: styled.div`
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      margin-top:5rem;
    `,

    NotiContainer: styled.div`
      margin-top: 10px;
      margin-bottom: 2.4rem;
      @media ${({theme}) => theme.device.mobile} {
       height:100%;
      };
    `,

    ScrollNotiWrapper: styled.div`
      position: absolute;
      bottom: 70px;
      left: 50%;
      z-index: 100;
      transform: translate(-50%, 0);
    `,


    AnimationBox: styled.div`
      height: ${props => props.activate ? "inherit" : "0px"};
      width: ${props => props.activate ? "inherit" : "0px"};
      z-index: ${props => props.activate ? 2 : -4};
      position: relative;
      opacity: ${props => props.activate ? 1 : 0.0};
      bottom: ${props => props.activate ? "0" : "-100px"};
      
    `,

    ContentSection: styled.div`
      width: 103.4rem;
      position: relative;
      display: inline-flex;
      overflow: hidden;
      align-items: center;
      justify-content: space-between;
      left: 6.4rem;

      @media ${({theme}) => theme.device.mobile} {
        width: 343px;
        left: inherit;
        flex-direction: column;
        top: 28px; // not Calculated
        justify-content: space-between;

        @media screen and (max-width: 355px) {
          width: 283px;
        }
      };
    `,

    LeftSection: styled.div`
      @media ${({theme}) => theme.device.mobile} {
        margin: 0 auto;
      };

    `,
    RightSection: styled.div`
      margin-top: 58px;

      @media ${({theme}) => theme.device.mobile} {
        display: none;
        margin-top: 0;
      };

    `,

    Notice: styled.span`
      display: block;
      font-size: 10px;
      font-family: "PretendardRegular", serif;
      line-height: 11.93px;
      letter-spacing: -0.6px;
      color: ${(props) => props.theme.colors.grey20};
      margin-top: 10px;

      @media ${({theme}) => theme.device.mobile} {
        margin-top: 8px;
      };
    `,
};


// Render on Mobile
const M = {
    LeadingWrapper: styled.div`
      
      @media ${({theme}) => theme.device.mobile} {
        height: 124px;
        width: 100%;
        display: flex;
        align-items: end;
        margin-top: 12px;

        @media screen and (max-width: 355px) {
          width: 283px;
        }

        

        @media screen and (max-height: ${theme.breakPoint.secondHeightBreak}) {
          margin-top: 0;
        }

        @media screen and (max-height: ${theme.breakPoint.fourthHeightBreak}) {
          height: 106px;
        }
      }

        // @media ${({theme}) => theme.device.mobileM} {
      //   @media screen and (max-height: 612px) {
      //     height: 100px;
      //   }
      // }
    `,

    SearFloatBtnWrapper: styled.div`
      position: absolute;
      left: 50%;
      z-index: 1;
      transform: translate(-50%, 0);
      display: block;
      opacity: ${props => props.activate ? 1 : 0.0};
      bottom: ${props => props.activate ? "32px" : "-50px"};
      transition: bottom 0.5s ease 0s, opacity 1.0s;


    `,

    FloatBtnWrapper: styled.div`
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      display: block;
      opacity: 0;
      z-index: 1;

      @media ${({theme}) => theme.device.mobile} {
        opacity: ${props => props.activate ? 1 : 0.0};
        bottom: ${props => props.activate ? "32px" : "-50px"};
        transition: bottom 0.5s ease 0s, opacity 1.0s;
      }

    `,

    MobileOnlyWrapper: styled.div`
      display: none;

      @media ${({theme}) => theme.device.mobile} {
        display: block;
      }
    `,
};

const MobileLeadingTitle = () => {
    return (
        <L.MobileContainer>
            <L.Sub>과거로 돌아가</L.Sub>
            <L.Main>1년 전에 투자했다면?</L.Main>
        </L.MobileContainer>
    );
};

const LeadingTitle = () => {
    return (
        <L.Container>
            <L.Sub>과거로 돌아가</L.Sub>
            <L.Main>1년 전에 투자했다면?</L.Main>
        </L.Container>
    );
};

const L = {
    MobileContainer: styled.div`
      display: none;
      margin: 0 auto;
      text-align: center;

      @media ${({theme}) => theme.device.mobile} {
        margin-top:2rem;
        display: block;
      }
    `,
    Container: styled.div`

      display: block;


      @media ${({theme}) => theme.device.mobile} {
        display: none;
      };
    `,
    Main: styled.h1`
      color: ${(props) => props.theme.colors.black};
      white-space: nowrap;
      ${remFontTheme.web3};
      @media ${({theme}) => theme.device.mobile} {
        ${remFontTheme.headline1};
      };
    `,
    Sub: styled.span`
      color: ${(props) => props.theme.colors.blue30};
      display: block;
      margin-bottom: 0.8rem;
      ${remFontTheme.title2};

      @media ${({theme}) => theme.device.mobile} {
        margin-bottom: 4px;
        ${remFontTheme.body2};
      };
    `,
};

export default DemoInvestmentSubView1;
