import * as React from 'react';
import {styled, ThemeProvider} from '@mui/material/styles';
import Slider from '@mui/material/Slider';
import Tooltip from '@mui/material/Tooltip';
import {withStyles} from '@material-ui/core/styles';
import * as props from "../../styles/theme";

// Shadow/200
const shadow200 =
    '0px 5.86471px 54px rgba(154, 170, 193, 0.18), 0px 5px 29px 5px rgba(182, 191, 203, 0.14)';

function valueLabelText(value) {
    return `${value}주`;
}

const AppSliderValueLabelBase = withStyles((theme) => ({
    tooltip: {
        // 위치
        // top: -6,

        // 크기
        // width: '59px',
        // height: '28px',
        padding: '6px 12px',
        borderRadius: '8px',

        // 폰트 속성 : body3
        fontFamily: 'PretendardMedium, serif',
        fontSize: '13px',
        lineHeight: '16px',
        // 폰트 색상 : Greyscale/60
        color: props.theme.colors.grey60,

        // 백그라운드 색상
        background: props.theme.colors.white,
        // 그림자 : Shadow/200
        boxShadow: shadow200,

        /*'&:before': {
            display: 'none',
        },*/
        /*'& *': {
            background: 'transparent',
            color: theme.palette.mode === 'dark' ? '#fff' : '#000',
        },*/
    },
    arrow: {
        color: props.theme.colors.white,
    },
}))(Tooltip);

function AppSliderValueLabel(props) {
    const { children, value } = props;

    //
    return (
        <AppSliderValueLabelBase enterTouchDelay={0} placement='top' title={value} arrow>
            {children}
        </AppSliderValueLabelBase>
    );
}

const AppSliderBase = styled(Slider)(({ theme }) => ({
    // 색상(각 요소별로 색상을 별도로 지정했기 때문에 비활성화)
    // color: theme.palette.mode === 'dark' ? '#3880ff' : '#3880ff',

    // 크기
    // width: '100%',
    height: '8px',

    // thumb(슬라이드의 원형 버튼)
    '& .MuiSlider-thumb': {
        height: '28px',
        width: '28px',
        backgroundColor: props.theme.colors.white,
        boxShadow: shadow200,
    },

    // 트랙
    '& .MuiSlider-track': {
        backgroundColor: '#36394D',
        border: 'none',
    },

    // 레일
    '& .MuiSlider-rail': {
        // opacity: 0.5,
        background: '#E7E9F1',
        borderRadius: '36px',
    },
}));

export default function AppSlider({value, onChange}) {
    return (
        // 기본 설정
        <AppSliderBase
            value={value}
            className={'swiper-no-swiping'}
            valueLabelDisplay="on"
            onChange={onChange}
            step={10}
            min={0}
            max={5000}
            components={{
                ValueLabel: AppSliderValueLabel,
            }}
            valueLabelFormat={valueLabelText}
        />
    );
}