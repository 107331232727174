import styled from "styled-components";
import {fontTheme} from "../../styles/fonts";
import mockupImg from "../../assets/images/mockup.png";

export const S = {
    ButtonWrapper: styled.div`
        margin: 0 auto;
    `,

    TopSizedBox: styled.div`
      height: 80px;
      display: none;

      // REM 적용 X 
      @media ${({theme}) => theme.device.contentHeight} {
        display: block;
      }

      @media ${({theme}) => theme.device.tablet} {
        height: 44px;
      }

      @media ${({theme}) => theme.device.mobile} {
        height: 96px;
      }
    `,

    LeadingTitle: styled.h1`
      display: block;
      text-align: center;
      color: ${props => props.theme.colors.black};
      margin-bottom: 32px;
      ${fontTheme.web3};
      
      ${props => props.theme.appearAnimation.first};

      @media ${({theme}) => theme.device.tablet} {
        ${fontTheme.headline1};
      }
    `,

    Strong: styled.span`
      color: ${props => props.theme.colors.grey30};
    `,

    MockUpWrapper: styled.div`
      z-index: 2;
      margin: 32px auto 0 auto;
      
      position: relative;
      ${props => props.theme.appearAnimation.second};


      @media ${({theme}) => theme.device.contentHeight} {
        overflow: hidden;
        //position: relative;
      };
    `,

    MockUp: styled.div`
      height: 638px;
      width: 296px;
      background-image: url(${mockupImg});
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      z-index: 1;


    `,

    InApp: styled.img`
      position: relative;
      height: 638px;
      width: 296px;
      z-index: -1;

    `,


};