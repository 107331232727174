import * as React from 'react';
import {styled} from '@mui/material/styles';
import Button from '@mui/material/Button';
import * as props from "../../styles/theme";
import {fontTheme as fontsTheme} from "../../styles/fonts";
import {darken, lighten} from "polished";

const AppButtonBase = styled(Button)(({type, showShadow, theme}) => ({
    borderRadius: '12px',

    /* 색상 */
    background: props.theme.colors.blue30,

    /* 이벤트 */
    '&:hover': {
        background: lighten(0.1, `${props.theme.colors.blue30}`)
    },
    '&:active': {
        background: darken(0.1, `${props.theme.colors.blue30}`),
    },

    /* 텍스트 스타일 */
    color: props.theme.colors.white,
    textAlign: 'center',
    letterSpacing: '-0.6px',
    /* Title/2_Semibold 16 */
    fontSize: '16px',
    lineHeight: '20px',
    fontFamily: 'PretendardSemiBold, serif',
    // ...(fontsTheme.title2),

    /* 크기 */
    ...(
        type === 'normal' && {
            width: '165px',
            height: '48px',
        }
    ),
    ...(
        type === 'full-width' && {
            width: '100%',
            height: '56px',
        }
    ),
    ...(
        type === 'dialog' && {
            width: '136px',
            height: '48px',
        }
    ),

    /* 그림자 */
    ...(
        showShadow === true && {
            boxShadow: '0 5.86471px 54px rgba(154, 170, 193, 0.18), 0 5px 29px 5px rgba(182, 191, 203, 0.14)',
        }
    )
}));

export default function AppButton({id, onClick, type, showShadow, children}) {
    return (
        <AppButtonBase id={id} className={(matchMedia('only screen and (max-width: 768px)').matches) ? '' : 'swiper-no-swiping'}  onClick={onClick} type={type} showShadow={showShadow}>{children}</AppButtonBase>
    );
}

AppButton.defaultProps = {
    type: 'normal',
    showShadow: false,
}