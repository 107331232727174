import styled from "styled-components";
import {fontTheme} from "../../styles/fonts";
import mockupImg from "../../assets/images/mockup.png";
import Slider from "react-slick";

export const S = {
    TopSizedBox: styled.div`
      height: 80px;
      display: none;

      // REM 적용 X 
      @media ${({theme}) => theme.device.contentHeight} {
        display: block;
      }

      @media ${({theme}) => theme.device.tablet} {
        height: 44px;
      }

      @media ${({theme}) => theme.device.mobile} {
        height: 98px;
      }
    `,

    LeadingTitle: styled.span`
      display: block;
      text-align: center;
      color: ${props => props.theme.colors.black};
      margin-bottom: 32px;
      position: relative;
      ${props => props.theme.appearAnimation.first};
      letter-spacing: -0.6px;
      font-size: 32px;
      line-height: 48px;
      font-family: "PretendardSemiBold", serif;
      
      @media ${({theme}) => theme.device.tablet} {
        font-size: 24px;
        line-height: 37px;
        letter-spacing: -0.1px;
      };
    `,

    GroupButton: styled.div`
      margin: 0 auto;
      width: fit-content;
      display: flex;
      justify-content: space-between;
      position: relative;
      ${props => props.theme.appearAnimation.second};

      @media screen and (max-width: 340px) {
        width: 100%;  // 특정 레이아웃에서 Group 버튼이 화면에서 짤리는 현상 방지
      }
    `,

    Button: styled.button`
      padding: 7px 17px;
      border-radius: 12px;
      color: ${props => props.isSelected ? 'white' : props.theme.colors.grey70};
      background: ${props => props.isSelected ? props.theme.colors.blue30 : 'white'};
      border: 1px solid ${props => props.isSelected ?  "white" : props.theme.colors.grey10 };
      cursor: pointer;
      margin: ${props => props.index === 1 ? "0 8px" : "0 0"};
      white-space: nowrap;
      
      font-size: 14px;
      line-height: 22px;
      font-family: "PretendardMedium", serif;
      text-align: center;
      letter-spacing: -0.6px;
    `,

    MockUpWrapper: styled.div`
      margin: 32px auto 0 auto;
      z-index: 0;
      position: relative;
      ${props => props.theme.appearAnimation.second};


      @media ${({theme}) => theme.device.contentHeight} {
        overflow: hidden;
        //position: relative;
      };
    `,

    MockUp: styled.div`
      height: 638px;
      width: 296px;
      background-image: url(${mockupImg});
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;

    `,

    InApp: styled.img`
      position: relative;
      z-index: -1;
      height: 638px;
      width: 296px;
    `,

    Slider: styled(Slider)`
      z-index: -1;
    `,
}