import styled from "styled-components";
import Div100vh from "react-div-100vh";

 const DemoPage = (props) => {
    const {id, backgroundColor, className} = props;
    return <Container id={id} color={backgroundColor} className={className}>
        {props.children}
    </Container>
};

 const Container = styled(Div100vh)`
  display: flex;
  height: 100%;
  width: 100%;
  background-color: white;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  
`;


export default  DemoPage;
