import {getChannelList} from "../../repository/repository";
import DemoInvestmentSubPage1 from "./DemoInvestmentSubView1";
import DemoInvestmentSubView2 from "./DemoInvestmentSubView2";
import DemoInvestmentSubPage3 from "./DemoInvestmentSubPage3";
import React, {useState, useEffect} from "react";
import styled from "styled-components";
import {ChannelContext} from "../../context/channelContext";
import "../../styles/swiper.css"

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// Import Swiper React components

import{ Mousewheel, Keyboard } from "swiper";

import IosConfirmModal from "../../components/ui/IosConfirmModal";
import Navbar from "../../components/ui/Navbar/Navbar";  

const DemoInvestmentPage = (props) => {
    const {parentSlider} = props;

    // 채널 목록
    const [channelList, setChannelList] = React.useState(getChannelList());
    // 선택한 채널 index
    const [selectedChannelIndex, setSelectedChannelIndex] = useState(null);
    // 채널 목록 새로고침 횟수
    const [refreshCount, setRefreshCount] = React.useState(0);
    // 유저가 투자하고자 하는 주식 수
    const [stockCount, setStockCount] = useState(0);

    /// 아래부터는 검색 관련
    // 검색 팝업의 채널 목록
    const [popupChannelList, setPopupChannelList] = useState([]);
    // 검색 팝업에서 선택한 채널 index
    const [popupSelectedChannelIndex, setPopupSelectedChannelIndex] = useState(null);
    // 검색어
    const [term, setTerm] = useState(null);

    // UI 관련
    let [mySwiper, setMySwiper] = useState(null);

    const [iosModalOpen, setIosModalOpen] = useState(false);


    const goToIntroductionPage = (pageNum) => {
        parentSlider.slideTo(pageNum);
    };

    // 채널리스트 refresh
    const refreshChannelList = () => {
        setChannelList(getChannelList());
        setRefreshCount(refreshCount + 1);
        setSelectedChannelIndex(null);
        setStockCount(0);
        goToNextPage(0);
    };

    // 다음 화면으로 이동
    const goToNextPage = (step) => {
        mySwiper.slideTo(step);
    };


    const slides = [
        <DemoInvestmentSubPage1/>,
       
        <DemoInvestmentSubView2
            isSelected={selectedChannelIndex !== null}
            channel={(popupSelectedChannelIndex === null) ? (channelList[selectedChannelIndex] ?? channelList[0]) : popupChannelList[popupSelectedChannelIndex]}
            stockCount={stockCount}
            setStockCount={setStockCount}
        />,
        
        <DemoInvestmentSubPage3
            openIosConfirmModal={() => setIosModalOpen(true)}
            refreshChannelList={refreshChannelList}
            isSelected={selectedChannelIndex !== null}
            channel={(popupSelectedChannelIndex === null) ? (channelList[selectedChannelIndex] ?? channelList[0]) : popupChannelList[popupSelectedChannelIndex]}
            stockCount={stockCount}
        />,
    ]

    return (
        
            <ChannelContext.Provider value={{
                refreshCount,
                selectedChannelIndex,
                channelList,
                refreshChannelList,
                goToNextPage,
                setSelectedChannelIndex,
                stockCount, setStockCount,
                popupChannelList, setPopupChannelList,
                popupSelectedChannelIndex, setPopupSelectedChannelIndex,
                term, setTerm,
                }}>
                    <Navbar goToIntroductionPage ={goToIntroductionPage}/>                     
                    {/* <IosConfirmModal open={iosModalOpen} close={() => setIosModalOpen(false)}/>  */}
                    
                    <Swiper        
                        slidesPerView={1}           
                        keyboard 
                        modules={[ Mousewheel]} 
                        speed={350}    
                        onSwiper={(mySwiper)=> setMySwiper(mySwiper)}
                        touchRatio={0}
                    >
        
                        {slides.map((slide) => (<SwiperSlide>{slide}</SwiperSlide>))}   

                    </Swiper>
            </ChannelContext.Provider>

    );
}

const B = {
    AppBar: styled.div`
      display: flex;
      justify-content: space-between;
      height: 56px;
      background: darkslategray;
      width: 100%;
      z-index: 10000;
      position: absolute;
      

      @media ${({theme}) => theme.device.mobile} {
        @media ${({theme}) => theme.device.investPreventSizedHeightM} {
          display: none;
        };
      };

      @media ${({theme}) => theme.device.mobileS} {
        @media ${({theme}) => theme.device.investPreventSizedHeight} {
          display: none;
        };
      };

      
      
      
      
      
    `,

    Wrapper: styled.div`
      width: 100px;
      height: 100px;
      position: absolute;
    `,

}

export default DemoInvestmentPage;