import React, {useEffect, useState} from 'react';
import Page from "../../components/layout/Page";
import {ContentSection} from "../../components/ui/ContentSection";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import constants from "../../utils/constants";
import handleViewport from "react-in-viewport";
import {loadAppearAnimation} from "../../feature/loadAppearAnimation";
import {S} from "./ServiceFlowPage.style";

const ServiceFlowPage = (props) => {
    const [animationActivated, setAnimationActivated] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const {inViewport, forwardedRef, enterCount} = props;

    /* Slick Slider 옥션 */
    const sliderOptions = {
        infinite: true,
        speed: 350,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: false,
        beforeChange: (current, next) => setSelectedIndex(next), // Auto Slide 되었을 때 hook 인덱스를 변경
    };

    // Slider Ref
    const slider = React.createRef();


    // Appear Animation effect 처리
    useEffect(() => {
        loadAppearAnimation(inViewport, enterCount, setAnimationActivated)
    }, [enterCount, inViewport]);


    // 해당 버튼이 선택되었는지 확인하고 boolean 값을 리턴
    const isSelected = (passedIndex) => {
        return selectedIndex === passedIndex;
    }

    // 버튼이 클릭 되었을 때
    function onBtnClicked(passedIndex) {
        setSelectedIndex(passedIndex);
        slider.current.slickGoTo(passedIndex);
    }

    return <Page id="service_flow_page" backgroundColor="white" className="swiper-slide slide-2">
        <ContentSection>
            <S.TopSizedBox/>
            <S.LeadingTitle activate={animationActivated}>유튜브 투자,<br/>그래서 어떻게
                하나요?</S.LeadingTitle>
            <S.GroupButton ref={forwardedRef} activate={animationActivated}>
                {constants.SERVICE_STEP.map((e) => (
                    <S.Button className={(matchMedia('only screen and (max-width: 768px)').matches) ? '' : 'swiper-no-swiping'} key={e.key} index={e.index} isSelected={isSelected(e.index)} onClick={() => onBtnClicked(e.index)}>
                        {e.content}
                    </S.Button>
                ))}

            </S.GroupButton>
            <S.MockUpWrapper activate={animationActivated}>
                <S.MockUp>
                    <S.Slider{...sliderOptions} ref={slider}>
                        {constants.SERVICE_STEP.map((slide) => (
                            <S.InApp key={slide.key} src={slide.img}/>
                        ))}
                    </S.Slider>
                </S.MockUp>
            </S.MockUpWrapper>
        </ContentSection>
    </Page>
};


export default handleViewport(ServiceFlowPage);
