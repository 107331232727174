import {ReactComponent as TextLogo} from '../assets/icons/text_logo_ic.svg';
import {ReactComponent as UserIcon} from '../assets/icons/icon_user_mono.svg';
import {ReactComponent as RefreshIcon} from '../assets/icons/icon_refresh_mono.svg';
import {ReactComponent as DownloadIcon} from '../assets/icons/icon_download_mono.svg';
import {ReactComponent as ArrowRight} from "../assets/icons/arrow_right_ic.svg";
import {ReactComponent as Close} from "../assets/icons/close_ic.svg";
import {ReactComponent as OurtubeTextLogo} from "../assets/icons/ourtube_text_logo.svg";
import {ReactComponent as LongArrowRight} from "../assets/icons/long_arrow_right_ic.svg";
import {ReactComponent as PinkUpArrow} from "../assets/icons/pink_up_arrow_ic.svg";
import {ReactComponent as DoubleArrowDown} from "../assets/icons/double_arrow_down_ic.svg";
import {ReactComponent as LeftArrow} from "../assets/icons/left_arrow_ic.svg";
import {ReactComponent as GooglePlay} from "../assets/icons/google_play_ic.svg";
import {ReactComponent as Apple} from "../assets/icons/apple_logo_ic.svg";
import {ReactComponent as OurtubeMainLogo} from "../assets/icons/ourtube_main_logo_ic.svg";
import {ReactComponent as Menu} from "../assets/icons/menu_ic.svg";
import {ReactComponent as Download} from "../assets/icons/download_ic.svg";


const iconTypes = {
    textLogo: TextLogo,
    userIcon: UserIcon,
    refreshIcon: RefreshIcon,
    downloadIcon: DownloadIcon,
    arrowRight: ArrowRight,
    close : Close,
    ourtubeTextLogo : OurtubeTextLogo,
    longArrowRight: LongArrowRight,
    pinkUpArrow : PinkUpArrow,
    doubleArrowDown : DoubleArrowDown,
    leftArrow: LeftArrow,
    googlePlay: GooglePlay,
    apple: Apple,
    ourtubeMainLogo : OurtubeMainLogo,
    menu: Menu,
    download: Download,

};



const IconComponent =({name, ...props}) => {
    let Icon = iconTypes[name];
    return <Icon {...props} />;
}

export default IconComponent;