import React from 'react';
import styled from "styled-components";


const Policy = () => {
    return <Container>
          <Title style={{justifyContent:"center", marginRight:"20px", marginBottom:"70px"}}>이용약관</Title>
          <Title>제1장 총칙</Title>
          <SubTitle>제1조 목적</SubTitle>
          <Text style={{lineHeight:'17px'}}>이 약관은 ㈜아워튜브(이하 “회사”)가 운영하는 아워튜브앱(이하 “앱”)에서 제공하는 구글 애드센스 지급금에 대한 이용자 지분 상당의 조건부 지급청구권(이하 “광고 수익 청구권”)의 판매 중개 서비스 및 기타 정보서비스(이하 “서비스”)와 관련하여 회사와 회원 간의 권리, 의무, 책임 사항 및 회원의 서비스 이용절차에 관한 사항을 규정함을 목적으로 합니다.</Text>
        
          <SubTitle>제2조 용어의 정의</SubTitle>
          <Ul>
            <Li>“회원”이라 함은 본 약관에 따라 회사와의 이용계약을 체결하고 앱에서 제공하는 서비스를 이용하는 자를 말합니다.</Li>
            <Li> “아이디(ID)”라 함은 회원의 식별과 서비스 이용을 위하여 회원이 정하고 회사가 승인하는 문자 및 숫자의 조합을 의미합니다.단, 회사는 일관된 아이디정책을 위해 특정 유형의 아이디를 회원이 선정하도록 사전에 정할 수 있습니다.</Li>
            <Li>“비밀번호(Password)”라 함은 회원이 부여 받은 아이디와 일치되는 회원임을 확인하고 비밀보호를 위해 회원 자신이 정한 문자 또는 숫자의 조합을 의미합니다.</Li>
            <Li>“광고 수익 청구권”이라 함은 유튜브 크리에이터 및/또는 MCN회사(이하 “파트너”)가 앱을 통하여 자신의 유튜브 채널에 업로드한 컨텐츠의 조회수 등에 따라 구글에 대하여 애드센스 수익금의 지급을 청구할 수 있는 권리의 전부 또는 일부를 본 약관 및 이용계약이 정하는 바에 따라 회원에게 판매(옥션 방식 포함)하는 경우, 회원이 취득하는 회사에 대한 청구권을 말합니다.</Li>
            <Li>“캐시”라 함은 회원이 광고 수익 청구권을 매매하는데 사용(지급)할 목적으로 이 약관, 개별약관(해당 사항 있는 경우) 및 이용계약이 정하는 바에 따라 회사에 예치한 현금(원화)을 회사가 제공하는 온라인 지갑(기능)에 설정된 단위로 변환한 것으로서, 1캐시는 1원과 대등한 가치를 가집니다.</Li>
            <Li>“해지”라 함은 회사 또는 회원이 이용계약의 효력을 장래를 향해 소멸시키는 것을 말합니다.</Li>
            <Li>이 약관에서 사용하는 용어 중 제2조에서 정하지 아니한 것은, 관계 법령 및 서비스별 안내에서 정하는 바에 따르며, 그 외에는 일반 거래 관행에 따릅니다.</Li>
          </Ul>

          <SubTitle>제3조 약관의 게시와 개정</SubTitle>
          <Ul>
            <Li>회사는 이 약관의 내용을 회원이 쉽게 알 수 있도록 앱 내에 게시합니다. 다만, 약관의 내용은 이용자가가 연결화면을 통하여 볼 수 있도록 할 수 있습니다.</Li>
            <Li>회사는 약관의 규제에 관한 법률, 정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하 “정보통신망법”) 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.</Li>
            <Li>회사가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행 약관과 함께 제1항의 방식에 따라 그 개정약관의 적용일자 7일 전부터 적용일자 전일까지 공지함으로써 그 효력이 발생합니다. 다만, 회원에게 불리하게 약관내용을 변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다. 이 경우 회사는 개정 전 내용과 개정 후 내용을 명확하게 비교하여 회원이 알기 쉽도록 표시합니다.</Li>
            <Li>회사가 전항에 따라 개정약관을 공지 또는 통지하면서 회원에게 7일 기간 내에 의사표시를 하지 않으면 의사표시가 표명된 것으로 본다는 뜻을 명확하게 공지 또는 통지하였음에도 회원이 명시적으로 거부의 의사표시를 하지 아니한 경우 회원이 개정약관에 동의한 것으로 봅니다.</Li>
            <Li>회원이 개정약관의 적용에 동의하지 않는 경우 회사는 개정 약관의 내용을 적용할 수 없으며, 이 경우 회원은 이용계약을 해지할 수 있습니다. 다만, 기존 약관을 적용할 수 없는 특별한 사정이 있는 경우에는 회사는 이용계약을 해지할 수 있습니다. 이 경우 회사는 제17조(회원에 대한 통지)에서 정한 방법으로 회원에게 통지합니다.</Li>
            <Li>본 약관은 회사와 회원 간에 성립되는 서비스이용계약의 기본약정입니다. 회사는 필요한 경우 특정 서비스에 관하여 적용될 사항(이하 “개별약관”)을 정하여 미리 공지할 수 있습니다. 회원이 이러한 개별약관에 동의하고 특정 서비스를 이용하는 경우에는 개별약관이 우선적으로 적용되고, 본 약관은 보충적인 효력을 갖습니다. 개별약관의 변경에 관해서는 개별약관에 특별한 규정이 없는 한 위 제3, 4항을 준용합니다. 회원이 앱에서 광고 수익 청구권 구매 시(옥션 포함) 및 판매 시 신청절차 상에서 요청되는 동의사항(이하 “동의사항”)은 개별 약관으로 봅니다. </Li>
          </Ul>
          
          <SubTitle>제4조 관련 법령과의 관계</SubTitle>
          <Ul>
            <Li>본 약관 또는 개별약관에서 정하지 않은 사항은 전기통신사업법, 전자문서 및 전자거래기본법, 정보통신망법, 전자상거래 등에서의 소비자보호에 관한 법률, 저작권법 등 관련 법령의 규정과 일반적인 상관례에 의합니다.</Li>
            <Li>회사와 회원은 이 서비스를 이용, 제공함에 있어서 전자상거래 등에서의 소비자보호에 관한 법률, 전자문서 및 전자거래기본법, 소비자기본법, 표시⋅광고의 공정화에 관한 법률, 정보통신망법, 저작권법 등 관련법령을 준수하여야 합니다. </Li>
          </Ul>

          <Title>제2장 서비스의 이용</Title>
          <SubTitle>제5조 이용계약의 체결</SubTitle>
          <Ul>
            <Li>이용계약은 회원이 되고자 하는 자(이하 “가입신청자”)가 약관의 내용에 대하여 동의를 한 다음 회원가입신청을 하고 회사가 이러한 신청에 대하여 승낙함으로써 체결됩니다.</Li>
            <Li>회사는 가입신청자의 신청에 대하여 서비스 이용을 승낙함을 원칙으로 합니다. 다만, 회사는 다음 각 호에 해당하는 신청에 대하여는 승낙을 하지 않거나 사후에 이용계약을 해지할 수 있습니다. </Li>
            <Ul>
              <Li>가입신청자가 이 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우, 단 회사의 회원 재가입 승낙을 얻은 경우에는 예외로 함.</Li>
              <Li>실명이 아니거나 타인의 명의를 이용한 경우</Li>
              <Li>허위의 정보를 기재하거나, 회사가 제시하는 내용을 기재하지 않은 경우</Li>
              <Li>회원의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반 사항을 위반하며 신청하는 경우</Li>
            </Ul>
            <Li>제1항에 따른 신청에 있어 회사는 회원의 종류에 따라 전문기관을 통한 실명확인 및 본인인증을 요청할 수 있습니다.</Li>
            <Li>회사는 서비스관련설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우에는 승낙을 유보할 수 있습니다. </Li>
            <Li>제2항과 제4항에 따라 회원가입신청의 승낙을 하지 아니하거나 유보한 경우, 회사는 원칙적으로 이를 가입신청자에게 알리도록 합니다.</Li>
            <Li>이용계약의 성립 시기는 회사가 가입완료를 신청절차 상에서 표시한 시점으로 합니다.</Li>
            <Li>회원은 개인회원과 법인회원으로 구분되며, 회사의 정책에 따라 회원을 등급별로 구분하여 서비스 이용범위나 혜택 또는 서비스 사용료 징수 등을 세분하여 이용에 차등을 둘 수 있습니다. </Li>
          </Ul>


          <SubTitle>제6조 서비스의 제공 및 중단 등</SubTitle>
          <Ul>
            <Li>회사는 회원에게 아래와 같은 서비스를 제공합니다.</Li>
             <NoneUl>
               <Li>1  구글 애드센스 지불금에 대한 이용자 지분 상당의 조건부 지급청구권 거래 서비스(이하 “플랫폼 서비스”)</Li>
            
                <RomaUl>
                  <Li>회사는 파트너의 구글 애드센스 광고 수익 청구권을 본 약관 및 이용계약이 정하는 바에 따라 회원에게 분할 판매(옥션 방식 포함)하는 것을 중개합니다. 회사는 광고 수익 청구권과 관련하여 월단위를 기준으로 파트너가 구글 애드센스로부터 정상적인 분배주기에 의해 정산 받은 광고 수익에 대해서 광고 수익 발생시점 등을 기준으로 하는 회사의 판단에 따라 정산업무와 서비스 제공 시기 및 소급적용 여부를 결정하여 계산/산출할 수 있습니다.</Li> 
                  <Li>회원이 취득한 광고 수익 청구권은 파트너의 애드센스 지급금에 대한 청구권을 회사가 회원과의 관계에서 지분에 따라 분할 판매 중개하는 것이므로, 저작권 자체는 파트너에 유보되어 있으며, 회원은 본 약관에서 명시된 광고 수익 청구권 외에 다른 권리를 행사할 수 없습니다. </Li>
                  <Li>회원이 광고 수익 청구권을 취득하려면 본 플랫폼 서비스를 통해 구매신청(옥션 방식 포함) 등을 해야 하며, 회사가 광고 수익 청구권 판매를 위해 앱 내에 공지, 제공하는 각 유튜브 채널 및 파트너의 정보, 서비스 내용 등에 대한 사항을 사전에 충분히 읽고 이해해야 합니다.</Li>
                  <Li>회원은 광고 수익 청구권에 따른 금원 정산 및 분배에 관한 일체의 업무를 회사에 전속적으로 위임하고 파트너 및 구글 애드센스에 직접 광고 수익 지급금의 지급을 요구할 수 없습니다. 구체적인 정산 및 분배에 관한 사항은 구매 시 동의사항을 참조하시기 바랍니다.</Li>
                  <Li>회원이 플랫폼 서비스를 통해 파트너로부터 구매(옥션 방식 포함)한 광고 수익 청구권은 본 플랫폼서비스에서 회사의 중개를 거쳐 다른 회원에게 자유롭게 양도할 수 있습니다. 다만, 본 플랫폼서비스 이외에는 광고 수익 청구권의 재양도가 허용되지 않으며, 이로 인해 발생하는 문제에 대해 회사는 어떠한 의무나 책임을 부담하지 않습니다.</Li>
                  <Li>회사는 플랫폼 서비스에 합리적이고 적정한 방식으로 지갑기능을 설정·도입할 수 있습니다. 이에 따라 회사는 회원으로 하여금 회원 명의의 지갑에 캐시를 저장(충전)하도록 할 수 있고, 캐시 저장(충전)과 관련한 일정한 요건을 충족하는 회원에게만 광고 수익 청구권 거래 기타 플랫폼 서비스를 이용하도록 할 수 있습니다. 회사가 지갑기능을 설정하고자 하는 경우, 그 세부적인 기준에 대해 회원에게 사전에 공지하여야 합니다. 다만 지갑기능의 설정(운용)여부는 회사의 전적인 재량사항으로서, 회원은 회사에게 지갑기능의 설정(운용)을 요구할 수 없습니다. 한편, 현금(원화)의 캐시 변환 및 지급 저장기능을 설정·운용하는 것은 회원의 광고 수익 청구권 거래의 편의를 제고하고 원활한 거래 생태계를 조성하기 위한 것으로서, 캐시의 충전 및 저장은 현금(원화) 예치와 동일한 성질의 것이고 전자금융거래법상 전자화폐, 선불전자지급수단 기타 전자지급수단에 해당하지 않습니다. 회원은 광고 수익 청구권 거래 이외의 목적으로는 캐시를 사용할 수 없습니다.</Li>

                </RomaUl>
                <Li>2 기타 서비스 : 회사의 플랫폼 서비스 이외에 앱을 통하여 제공하는 홍보 서비스 등 일체의 기타 서비스</Li>
              </NoneUl>

              <Li>회원은 아이디(ID)와 비밀번호(Password)를 통하여 회사가 제공하는 서비스를 사용할 수 있고, 동 서비스를 이용하여 광고 수익 청구권을 구매(옥션 포함)할 수 있습니다.</Li>
              <Li>회원은 회원이 취득한 광고 수익 청구권의 대상이 되는 컨텐츠의 다른 지분에 해당하는 광고 수익 청구권이 자유로운 거래의 대상이라는 점을 이해하고, 이에 대해 어떠한 이의도 제기할 수 없습니다</Li>
              <Li>회원이 플랫폼 서비스를 통하여 광고 수익 청구권을 구매하는 경우, 반드시 회사가 마련한 거래보호서비스를 통하여 거래를 완료하여야 합니다. 이를 위반하여 발생한 손해에 대해 회사는 어떠한 의무나 책임을 부담하지 않습니다.</Li>
              <Li>회사는 회사의 중재 없는 회원 간의 직거래로 인하여 발생한 문제에 대하여 책임지지 않습니다.</Li>
              <Li>회사는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영상 상당한 이유가 있는 경우 서비스의 제공을 일시적으로 중단할 수 있습니다. 이 경우 회사는 제17조(회원에 대한 통지)에서 정한 방법으로 회원에게 통지합니다. 다만, 회사가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.</Li>
              <Li>회사는 서비스의 제공에 필요한 경우 정기점검을 실시할 수 있으며, 정기점검시간은 서비스 제공화면에 공지한 바에 따릅니다.</Li> 
          </Ul>

          <SubTitle>제7조 서비스의 변경</SubTitle>
            <Ul>
              <Li>회사는 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라 제공하고 있는 전부 또는 일부 서비스를 변경할 수 있습니다.</Li>
              <Li>회사는 서비스 변경 시에 그 변경되는 서비스의 내용을 제17조(회원에 대한 통지)에서 정한 방법으로 회원에게 통지합니다.</Li>
              <Li>회사는 제공되는 서비스의 일부 또는 전부를 회사의 정책 및 운영의 필요상 수정, 중단, 변경할 수 있으며, 이에 대하여 회사는 고의 또는 과실이 없는 한 회원에게 별도의 보상을 하지 않습니다.</Li>
            </Ul>

            <SubTitle>제8조 서비스 이용계약의 해지, 종료 등</SubTitle>
              <Ul>
                <Li>회원은 언제든지 서비스 문의를 통하여 이용계약해지 신청을 할 수 있으며, 회사는 관련법 등이 정하는 바에 따라 이를 즉시 처리하여야 합니다. </Li>
                <Li>회원이 계약을 해지할 경우, 관련법 및 개인정보처리방침에 따라 회사가 회원정보를 보유하는 경우를 제외하고는 해지 즉시 회원의 모든 데이터는 소멸됩니다. </Li>
                <Li>회사의 귀책사유 또는 회사에 대한 행정처분 등 회사의 정상적인 사업추진이 불가능한 사유가 발생하여 회사의 사업이 종료되는 경우, 회사는 귀책사유 있는 파트너와 연대하여 회원에게 회원이 구매한 광고 수익 청구권에 대한 최초의 판매(옥션방식 포함) 시작 가격의 90%를 위약금으로 지급하고 서비스를 종료할 수 있습니다.</Li>
              </Ul>

            
            <SubTitle>제9조 이용제한 등</SubTitle>
              <Ul>
                <Li>회사는 회원이 이 약관의 의무를 위반하거나 서비스의 정상적인 운영을 방해한 경우, 경고, 일시정지, 영구이용정지 등으로 서비스 이용을 단계적으로 제한할 수 있습니다.</Li>
                <Li>회사는 전항에도 불구하고, 주민등록법을 위반한 명의도용 및 결제도용, 정보통신망법을 위반한 불법통신 및 해킹, 악성프로그램의 배포, 접속권한 초과행위 등과 같이 관련 법령을 위반한 경우에는 즉시 영구이용정지를 할 수 있습니다. 본 항에 따른 영구이용정지에 따른 각종 불이익에 대해 회사는 별도로 보상하지 않습니다.</Li>
                <Li>회사는 회원이 계속해서 12개월 이상 로그인하지 않는 경우, 회원정보의 보호 및 운영의 효율성을 위해 이용을 제한할 수 있습니다.</Li>
                <Li>회사는 본 조의 이용제한 범위 내에서 제한의 조건 및 세부내용은 이용제한정책 및 개별 서비스상의 운영정책에서 정하는 바에 의합니다.</Li>
                <Li>본 조 제1항 및 제2항에 따라 이용제한 된 회원의 광고 수익 청구권은 회사가 일정한 가격에 매입할 수 있는 권리가 있습니다. 회사는 회원이 앱에 제공한 계좌에 매입대금에서 회원이 취득 시 동의한 수수료를 공제한 금액을 지급하고 광고 수익 청구권을 매입할 수 있습니다. 이 경우 매입대금은 회원이 취득한 가격과 회사가 매입하기 전 3개월 동안 거래된 가격 중 가장 낮은 금액으로 합니다.</Li>
                <Li>회사는 본 조 제1항 및 제2항에 따라 이용제한 된 회원이 광고 수익 청구권을 이용제한 시점에 보유하고 있음에 따라 발생할 수 있는 향후 광고 수익 청구권으로 받을 수 있는 금원 정산 및 지급 등의 문제에 대하여 어떠한 책임도 부담하지 않습니다.</Li>
              </Ul>

            <SubTitle>제10조 캐시 환불</SubTitle>
              <RedUl>
                <Li>회원이 유료로 결제하여 충전한 캐시는 결제 후 한 번도 사용하지 않은 경우, 결제일로부터 [7]일 이내에 환불을 요청할 수 있습니다. 회원은 온라인, 전자우편 기타의 방법으로 회사에 대하여 환불을 요청하여야 합니다. 회사는 회원의 환불 요청과 회사의 환불규정을 확인하여 신속하게 환불 처리를 안내합니다.</Li>
                <Li>제1항에 의하여 환불이 이루어질 경우 회사는 회원이 환불을 요청한 날로부터 [3]영업일 이내에 지급받은 대금을 환급합니다. 회사는 결제와 동일한 방법으로 대금을 환급하거나 신용카드 등 결제수단을 제공한 사업자에게 대금 청구의 정지·취소를 요청하는 등 환급에 필요한 조치를 해야 합니다.</Li>
                <Li>회사가 이벤트 등의 목적으로 회원에게 무료로 지급한 캐시와 회원이 이미 사용한 캐시에 대해서는 환불이 불가능합니다. 또한 회원이 보유한 캐시 또는 회원이 보유한 캐시 중 환불하고자 하는 캐시가 환불 수수료 이하인 경우에는 환불이 불가능할 수 있습니다.</Li>
              </RedUl>

          <Title>제3장 회원 정보의 보호 </Title>
            <SubTitle>제11조 회원정보의 변경</SubTitle>
              <Ul>
                <Li>회원은 개인정보관리화면을 통하여 언제든지 본인의 개인정보를 열람하고 수정할 수 있습니다. 다만, 서비스 관리를 위해 필요한 실명, 아이디 등은 수정이 불가능합니다.</Li>
                <Li>회원은 회원가입 신청 시 기재한 사항이 변경되었을 경우 온라인으로 수정을 하거나 전자우편 기타 방법으로 회사에 대하여 그 변경 사항을 알려야 합니다.</Li>
                <Li>제2항의 변경사항을 회사에 알리지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다.</Li>
              </Ul>

            <SubTitle>제12조 개인정보보호 의무</SubTitle>
              <Ul>
                <Li>회사는 정보통신망법 등 관계 법령에 정하는 바에 따라 회원의 개인정보를 보호하기 위해 노력합니다. 개인정보 보호 및 처리에 대해서는 관련 법령 및 회사의 개인정보처리방침이 적용됩니다. 다만, 회사의 공식 앱 이외의 링크된 사이트에서는 회사의 개인정보처리방침이 적용되지 않습니다.</Li>
                <Li>회사는 회원의 개인정보 수집 시 서비스제공을 위하여 필요한 범위에서 최소한의 개인정보를 수집합니다.</Li>
                <Li>회사는 회원의 개인정보를 수집⋅이용하거나 제3자에게 제공하고자 하는 때에는 회원으로부터 동의를 받습니다.</Li>
                <Li>회사가 제3항에 따라 회원의 동의를 받는 경우, 회사는 개인정보의 수집⋅이용에 관한 사항(수집⋅이용 목적, 수집하는 개인정보의 항목, 개인정보의 보유⋅이용기간 등), 개인정보의 제3자 제공에 관한 사항(제공받는 자, 제공받는 자의 이용목적, 제공하는 개인정보의 항목, 제공받는 자의 보유 및 이용기간 등)을 미리 명시하거나 고지합니다.</Li>
                <Li>회원은 제3항에 따라 동의하더라도 언제든지 그 동의를 철회할 수 있습니다.</Li>
                <Li>회원은 언제든지 회사가 가지고 있는 자신의 개인정보에 대해 열람 및 요구정정을 요구할 수 있으며, 회사는 이에 대해 지체 없이 필요한 조치를 취할 의무가 있습니다. 회원이 오류의 정정을 요구한 경우에는 회사는 그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다.</Li>
                <Li>회사는 개인정보의 수집⋅이용 목적을 달성한 때에는 당해 회원의 개인정보를 지체 없이 파기하는 것을 원칙으로 합니다. 다만, 아래의 경우에는 회원정보를 보관합니다. 이 경우 회사는 보관하고 있는 회원정보를 그 보관의 목적으로만 이용합니다. 상법 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다.</Li>
                  <Ul>
                    <Li>회원의 탈퇴 신청 등으로 서비스 이용계약이 종료된 경우, 회사는 투자자 보호 및 본 약관이 정한 제한을 의도적으로 회피하기 위한 임의해지 방지를 위하여 상당하다고 인정되는 경우에 한하여 이용계약종료 후 1년간 아이디, 성명 또는 상호, 이메일주소, 연락처, 거래상세내역, 약관위반 행위자료 등 최소한의 필요정보를 보관합니다.</Li>
                    <Li>회사가 이용계약을 해지하거나 회사로부터 서비스 이용제한 조치를 받은 회원에 대해서는 재가입에 대한 승낙거부사유가 존재하는지 여부를 확인하기 위한 목적으로 이용계약종료 또는 이용제한 조치를 받은 후 1년간 아이디, 성명 또는 상호, 이메일주소, 연락처, 주소, 해지와 회원자격정지와 관련된 정보 등 최소한의 필요정보를 보관합니다.</Li>
                    <Li>기타 정보수집에 관한 동의를 받을 때 보유기간을 명시한 경우에는 그 보유기간까지 회원정보를 보관합니다.</Li>
                  </Ul>
                <Li>회사는 회원의 개인정보의 보호 및 처리에 관한 개인정보처리방침을 회원과 회사의 서비스를 이용하고자 하는 자가 알 수 있도록 앱 내에 게시합니다.</Li>                
              </Ul>

              <SubTitle>제13조</SubTitle>
                <Ul>
                  <Li>회원의 아이디와 비밀번호에 관한 관리책임은 회원에게 있으며, 이를 제3자가 이용하도록 하여서는 안 됩니다.</Li>
                  <Li>회사는 회원의 아이디가 개인정보 유출 우려가 있거나, 반사회적 또는 미풍양속에 어긋나거나 회사 및 회사의 운영자로 오인될 우려가 있는 경우, 해당 아이디의 이용을 제한할 수 있습니다.</Li>
                  <Li>회원은 아이디 및 비밀번호가 도용되거나 제3자가 사용하고 있음을 인지한 경우에는 이를 즉시 회사에 통지하고 회사의 안내에 따라야 합니다.</Li>
                  <Li>제3항의 경우에 해당 회원이 회사에 그 사실을 통지하지 않거나, 통지한 경우에도 회사의 안내에 따르지 않아 발생한 불이익에 대하여 회사는 고의 또는 중과실이 없는 한 책임지지 않습니다. </Li>
                </Ul>


            <Title>제4장 회사 및 회원의 의무</Title>
              <SubTitle>제14조 회사의 의무</SubTitle>
                <Ul>
                  <Li>회사는 관련 법령과 이 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며, 이 약관이 정하는 바에 따라 계속적이고 안정적으로 서비스를 제공하기 위하여 최선을 다하여 노력합니다.</Li>
                  <Li>회사는 회원이 안전하게 서비스를 이용할 수 있도록 개인정보(신용정보 포함)보호를 위해 보안시스템을 갖추어야 하며 개인정보처리방침을 공개하고 준수합니다.</Li>
                  <Li>회사는 서비스이용과 관련하여 발생하는 회원의 불만 또는 피해구제요청을 적절하게 처리할 수 있도록 필요한 인력 및 시스템을 구비합니다.</Li>
                  <Li>회사는 서비스이용과 관련하여 회원으로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 이를 처리하여야 합니다. 회원이 제기한 의견이나 불만사항에 대해서는 게시판을 활용하거나 전자우편 등을 통하여 “회원”에게 처리과정 및 결과를 전달합니다.</Li>
                </Ul>
              
              <SubTitle>제15조 회원의 의무</SubTitle>
                <Ul>
                  <Li>회원은 다음 행위를 하여서는 안 됩니다.</Li>
                    <Ul>
                      <Li>신청 또는 변경 시 허위내용의 등록</Li>
                      <Li>타인의 정보도용</Li>
                      <Li>회사가 게시한 정보의 변경</Li>
                      <Li>회사가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시</Li>
                      <Li>회사 기타 제3자의 저작권 등 지식재산권에 대한 침해</Li>
                      <Li>회사 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위</Li>
                      <Li>외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 앱 내에 공개 또는 게시하는 행위</Li>
                      <Li>기타 불법적이거나 부당한 행위</Li>
                    </Ul>
                  <Li>회원은 관계 법령, 이 약관의 규정, 이용안내 및 서비스와 관련하여 공지한 주의사항, 회사가 통지하는 사항 등을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 하여서는 안 됩니다.</Li>
                </Ul>

            <Title>제5장 기타사항</Title>
              <SubTitle>제16조 서비스이용료의 내용</SubTitle>
                <Ul>
                  <Li>회사는 서비스 이용과 관련하여 각종 이용료를 회원에게 징수할 수 있습니다.</Li>
                  <Li>회사는 재량 내에서 이용료의 추가 및 폐지, 이용료율의 변경을 할 수 있으며, 개정할 경우에는 적용일자 및 개정사유를 명시하여 그 적용일자 30일 이전부터 적용일자 전일까지 앱 내에 게시하거나 기타의 방법으로 회원에게 공지합니다.</Li>     
                </Ul>
              
              <SubTitle>제17조 채임 제한</SubTitle>
                <Ul>
                  <Li>회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 회사의 고의 또는 과실이 없는 한 서비스 제공에 관한 책임이 면제됩니다.</Li>
                  <Li>회사는 회원의 귀책사유로 인한 서비스 이용의 장애에 대하여는 회사의 고의 또는 중과실이 없는 한 책임을 지지 않습니다.</Li>
                  <Li>회사는 회원이 서비스와 관련하여 게재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여는 회사의 고의 또는 중과실이 없는 한 책임을 지지 않습니다.</Li>
                  <Li>회사는 무료로 제공되는 서비스 이용과 관련하여 관련법에 특별한 규정이 없는 한 책임을 지지 않습니다.</Li>
                  <Li>회사는 서비스상에 게재되어 있거나 본 서비스를 통한 제휴업체 등의 판촉활동에 회원이 참여하거나 교신 또는 거래를 함으로써 발생하는 손실과 손해에 대해 회사의 고의 또는 과실이 없는 한 책임을 지지 않습니다. 회원은 서비스 내에 포함되어 있는 링크를 통하여 다른 웹사이트로 옮겨갈 경우, 회사는 해당 사이트에서 제공하는 정보내용 및 이로 인한 손해 등에 대하여 회사의 고의 또는 중과실이 없는 한 책임을 지지 않습니다.</Li>
                </Ul>

              <SubTitle>제18조 회원에 대한 통지</SubTitle>
                <Ul>
                  <Li>회사가 회원에 대한 통지를 하는 경우 이 약관에 별도 규정이 없는 한 회원 가입 시 제공한 전자우편 또는 문자메시지 등으로 할 수 있습니다.</Li>
                  <Li>회사는 불특정다수 회원에 대한 통지의 경우 7일 이상 서비스 초기 화면에 게시함으로써 제1항의 통지에 갈음할 수 있습니다. 다만, 회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 개별통지를 합니다.</Li>
                </Ul>

              <SubTitle>제19조 분쟁조정 및 관할법원</SubTitle>
                <Ul>
                  <Li>회사와 회원간 제기된 소송은 대한민국법을 준거법으로 합니다</Li>
                  <Li>회사와 회원간 발생한 분쟁에 관한 소송은 제소 당시 회원의 주소를 관할하는 지방법원을 전속관할법원으로 하되, 주소가 없는 경우 거소를 관할하는 지방법원을 전속관할법원으로 합니다. 단, 제소 당시 회원의 주소 또는 거소가 명확하지 않거나 회원이 외국 거주자인 경우 관할법원은 민사소송법에 따라 정합니다.</Li>
                </Ul>

              <LastText style={{marginTop:"50px"}}>부칙(2022.[05].[01].</LastText>
              <LastText>이 약관은 2022년 05월 [ 02 ]일부터 적용됩니다.</LastText>
              <LastText style={{marginTop:"20px"}}>부칙(2022. [05]. [01].)</LastText>
              <LastText>이 약관은 2022. [05]. [02].부터 시행하며 종전의 약관은 이 약관으로 대체합니다.</LastText>

    </Container>


};

// const RealMainTitle = styled.span`
//  border-bottom :solid 1px gray;
//  width:800px;
//  justify-content:center;
//  align-items:center;
//  font-size: 18px;
//  margin: 50px 40px 15px 60px;
//  margin-top:40px

// `;


// const BorderBottom = styled.span`
//  border-bottom :solid 1px gray;

//  justify-content:center;
//  align-items:center;
// `;

// const Docs = styled.iframe`
//   frameBorder: 0;
//   overflow-scrolling: auto;
//   height: 100vh;
//   display: block;
//   width: 800px;
  

//   @media (max-width: 800px) {
//     width: 100%;
//   }
// `;

const Container = styled.div`!important
  justify-content: center;
  align-items: center;
  width:65vw ;
  text-aling: center;
  margin:0 auto;
  font-family: auto;

    @media (max-width: 800px) {
    width: 100%;
    margin:0;
  }

`;

const MainTitle = styled.div`
  margin: 50px 15px;
  margin-bottom: 50px;
  font-size: initial;
  width: 135px;


`;

const BorderBottom = styled.div`
 border-bottom :solid 1px gray;
`;



const Title = styled.h1`
  display:flex;
  margin: 30px 15px;
  margin-bottom: 50px;
  font-size:25px;

  @media (max-width: 800px) {
    font-size: 20px;
    margin:15px 15px;
    lineHeight:normal;
    margin-top:50px;
    margin-bottom: 50px;
    
  }
`;

const SubTitle = styled.h2`
  display:flex;
  margin: 20px 15px;
  font-size:20px;

  @media (max-width: 800px) {
    font-size: 15px;
    margin:15px 15px;
    lineHeight:normal;
    
  }
`;
const Ul = styled.ul`
  list-style: decimal;
  margin:15px 15px;
  lineHeight:10px;

      
  @media (max-width: 800px) {

     margin:15px 15px;
    
  }
  
`;

const RedUl = styled.ul`
  list-style: decimal;
  margin:15px 15px;
  color: red
  lineHeight:normal;
  
`;

const NoneUl = styled.ul`
  list-style: none;
  margin:15px 15px;
  lineHeight:normal;
`;

const RomaUl = styled.ul`
list-style: lower-roman;
margin:15px 15px 15px 20px;
lineHeight:normal;
`;


const Li =styled.li`
margin: 15px 15px 15px 25px;
font-size: 15px;  
lineHeight:20px;

  @media (max-width: 800px) {
  font-size: 10px;
  margin:15px 15px;
  lineHeight:normal;
  
}

`;

const Text =styled.div`
  display:flex;
  margin: 15px 35px 15px 20px;
  line-height:25px;
  font-size:15px;

  @media (max-width: 800px) {
    font-size: 10px;
    margin:15px 15px;
    lineHeight:normal;
    
  }


`;

const LastText = styled.div`
  color:red;
  text-align: center;
  margin:10px;
  font-weight: bold;
  font-size:15px;


  @media (max-width: 800px) {
    font-size: 10px;
    margin:10px 20px 15px 20px;
    lineHeight:normal;
    
  }
  
  
`;

export default Policy;