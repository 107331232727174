import styled from "styled-components";
import {fontTheme} from "../../styles/fonts";
import Icon from "../../utils/icon";
import {styled as muiStyled} from "@mui/material/styles";
import {Button} from "@mui/material";
import * as props from "../../styles/theme";
import {ContentSection} from "../../components/ui/ContentSection";

export const S = {
    ContentSection: styled.section`
      height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 auto;
      
      // @media ${({theme}) => theme.device.contentHeight} {
      //   justify-content: start;
      //   margin-top: 10%;
      // }
    `,
    TopSizedBox: styled.div`
      height: 10rem;
      display: none;
      // REM 적용 X 
      // @media ${({theme}) => theme.device.contentHeight} {
      //   display: block;
      // }
      @media ${({theme}) => theme.device.tablet} {
        height: 7rem;
      }
      @media ${({theme}) => theme.device.mobile} {
        height: 11.6rem;
      }
    `,

    LeadingTitle: styled.h1`
      display: block;
      text-align: center;
      color: ${props => props.theme.colors.black};
      ${fontTheme.web3};
      ${props => props.theme.appearAnimation.first};
      @media ${({theme}) => theme.device.tablet} {
        ${fontTheme.headline1};
      }
    `,

    QuestionList: styled.ul`
      margin: 5.6rem auto 0 auto;
      list-style: none;
      width: 37.5rem;
      position: relative;
      
      ${props => props.theme.appearAnimation.second};
      // @media screen and (max-width: 375px) {
      //   width: 100%;
      //   padding: 0 1.2rem;
      // }
    `
};

export const SC = {
    Container: styled.li``,

    Wrapper: styled.div`
      height: 4.8rem;
      position: relative;
    `,

    ArrowIcon: styled(Icon)`
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      margin: auto 0;
      height: 2.4rem;
      width: 2.4rem;
    `,

    Button: muiStyled(Button)({
        height: "100%",
        width: "100%",
        justifyContent: 'start',
        fontSize: '1.6rem',
        lineHeight: '2.2rem',
        fontFamily: 'PretendardSemiBold, serif',
        color: props.theme.colors.grey80,
        zIndex: 1,
        textAlign: "start",
        "@media (max-width: 375px)": {  // textOverflow 때문에 line break 필요
            whiteSpace: "pre-wrap",
        }
    }),


    Divider: styled.hr`
      display: ${props => props.mustShow ? 'block' : 'none'};
      border-top: 1px solid ${props => props.theme.colors.grey10};
      margin: 1.6rem 0;
      width: 100%;
      color: ${props => props.theme.colors.grey10};
    `,
};