import styled from "styled-components";
import {fontTheme} from "../../styles/fonts";

export const S = {
    RefContainer: styled.span`
      display: block
    `,

    TopSectionWrapper: styled.div`
      position: relative;
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin: 0 auto;
      ${props => props.theme.appearAnimation.first};
    `,
    BottomSectionWrapper: styled.div`
      position: relative;
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin: 0 auto;
      ${props => props.theme.appearAnimation.second};
    `,

    CenterSizedBox: styled.div`
      height: 80px;
      display: block;
      @media ${({theme}) => theme.device.mobile} {
        height: 1rem;
      }
    `,

    TopSizedBox: styled.div`
      display: none;
      height: 140px;
      // // REM 적용 X 
      // @media ${({theme}) => theme.device.contentHeight} {
      //   display: block;
      // }
      // @media ${({theme}) => theme.device.tablet} {
      //   height: 44px;
      // }
      // @media ${({theme}) => theme.device.mobile} {
      //   height: 112px;
      // }
    `,

    Title: styled.h1`
      margin: 0 auto 40px auto;
      color: ${props => props.theme.colors.black};
      ${fontTheme.headline1} @media ${({theme}) => theme.device.mobile} {
        margin: 3rem auto 2rem auto;
      };
    `,

    LogoWrapper: styled.div`
      display: flex;
      margin: 0 auto;
      @media ${({theme}) => theme.device.mobile} {
        flex-direction: column;
      }
    `,
};

export const SC = {
    Container: styled.div`
      justify-content: center;
      display: inline-flex;
      margin-bottom: 32px;
    `,

    Logo: styled.img`
      height: 56px;
      object-fit: contain;
    `,
    SizedBox: styled.div`
      width: 8px;
      @media ${({theme}) => theme.device.mobile} {
        flex-direction: column;
      }
    `,

}