import React from 'react';
import {styled} from "@mui/material/styles";
import Button from "@mui/material/Button";
import * as props from "../../styles/theme";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";
import {darken} from "@material-ui/core";

const GoToSearchButtonBase = styled(Button)(({ theme }) => ({
    // 콘텐츠 정렬 방향
    flexDirection: 'row',
    // 사이 간격
    gap: '0.7rem',

    // 모서리 둥글게
    borderRadius: '1.2rem',
    // 크기
    width: '22.5rem',
    height: '4.8rem',
    

    // 배경 색상 : Greyscale/white
    background: props.theme.colors.white,
    // 그림자 : Shadow/200
    boxShadow: `0px 5.86471px 54px rgba(154, 170, 193, 0.18), 0px 5px 29px 5px rgba(182, 191, 203, 0.14)`,

    // 이벤트
    '&:hover, &:active': {
        background: darken(props.theme.colors.white, 0.1),
    },
}));

const GoToSearchButtonIcon = styled(SvgIcon)(({ theme }) => ({
    width: '2.4rem',
    height: '2.4rem',
    flex: 'none',
    order: 0,
    flexGrow: 0,
}));

const GoToSearchButtonText = styled(Typography)(({ theme }) => ({
    textAlign: 'center',

    // 텍스트 색상 : Greyscale/Black
    color: props.theme.colors.black,
    /* 텍스트 스타일 : Body/1_Semibold 14 */
    fontSize: '1.4rem',
    lineHeight: '2.2rem',
    fontFamily: 'PretendardSemiBold, serif',
    letterSpacing: '-0.6px',

    /* Inside auto layout */
    flex: 'none',
    order: 1,
    flexGrow: 0,
}));

export default function SearchChannelBtn({onClick}) {
    return (
        <GoToSearchButtonBase id="click_search" className={(matchMedia('only screen and (max-width: 768px)').matches) ? '' : 'swiper-no-swiping'} onClick={onClick}>
            <GoToSearchButtonIcon>
                <circle cx="11" cy="11" r="6" stroke="#026BFB" strokeWidth="2" fill='none'/>
                <path d="M16 16L19 19" stroke="#026BFB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </GoToSearchButtonIcon>
            <GoToSearchButtonText>찾는 유튜브 채널이 있나요?</GoToSearchButtonText>
        </GoToSearchButtonBase>
    );
}