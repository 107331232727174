import React, {useEffect, useState} from 'react';
import Page from "../../components/layout/Page";
import constants from "../../utils/constants";
import {ContentSection} from "../../components/ui/ContentSection";
import handleViewport from "react-in-viewport";
import {loadAppearAnimation} from "../../feature/loadAppearAnimation";
import {S, SC} from "./Partner.style";


const PartnerPage = (props) => {
    const [animationActivated, setAnimationActivated] = useState(true);
    const {inViewport, forwardedRef, enterCount} = props;

    useEffect(() => {
        loadAppearAnimation(inViewport, enterCount, setAnimationActivated)
    }, [enterCount, inViewport])

    return <Page id="partners_page" backgroundColor="white" className="swiper-slide slide-5">
        <ContentSection>
            <S.TopSizedBox/>
            <S.TopSectionWrapper activate={animationActivated}>
                <S.Title activate={animationActivated}>Investor</S.Title>
                <S.LogoWrapper>
                    <LogoBundle leftLogoPath={constants.PARTNER_LOGO_LIST[0]}
                                rightLogoPath={constants.PARTNER_LOGO_LIST[1]}/>
                </S.LogoWrapper>
            </S.TopSectionWrapper>
            <S.CenterSizedBox ref={forwardedRef}/>
            <S.BottomSectionWrapper activate={animationActivated}>
                <S.Title activate={true}>Supporter</S.Title>
                <S.LogoWrapper>
                    <LogoBundle leftLogoPath={constants.PARTNER_LOGO_LIST[2]}
                                rightLogoPath={constants.PARTNER_LOGO_LIST[3]}/>
                    <LogoBundle leftLogoPath={constants.PARTNER_LOGO_LIST[4]}
                                rightLogoPath={constants.PARTNER_LOGO_LIST[5]}/>
                </S.LogoWrapper>
                <S.LogoWrapper>
                    <LogoBundle leftLogoPath={constants.PARTNER_LOGO_LIST[6]}
                                rightLogoPath={constants.PARTNER_LOGO_LIST[7]}/>
                    <LogoBundle leftLogoPath={constants.PARTNER_LOGO_LIST[8]}
                                rightLogoPath={constants.PARTNER_LOGO_LIST[9]}/>
                </S.LogoWrapper>
            </S.BottomSectionWrapper>
        </ContentSection>
    </Page>
};




const LogoBundle = (props) => {
    const {leftLogoPath, rightLogoPath} = props;
    return <SC.Container>
        <SC.Logo src={leftLogoPath}/>
        <SC.SizedBox/>
        <SC.Logo src={rightLogoPath}/>
    </SC.Container>
};



export default handleViewport(PartnerPage);