import React from 'react';
import {styled as muiStyled} from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import SvgIcon from '@mui/material/SvgIcon';
import ToggleButton from '@mui/material/ToggleButton';
import Typography from '@mui/material/Typography';
import '../../styles/global.css';
import * as props from "../../styles/theme";
import {darken, lighten} from "polished";
import styled, {css} from "styled-components";
import useWindowDimensions from "../../feature/useWindowDimensions";
import {theme} from "../../styles/theme";


// background 색상(선택 X) : BlueGrey10
const unselectedBackgroundColor = props.theme.colors.blueGrey10;
// background 색상(선택 O) : Greyscale/80
const selectedBackgroundColor = props.theme.colors.grey80;

const ChannelButtonBase = muiStyled(ToggleButton)(({selected, theme}) => ({
    // background 색상(선택 X)
    backgroundColor: unselectedBackgroundColor,
    // 테두리 없음
    border: 0,
    // 크기 및 padding
    width: '100%',
    height: '100%',
    padding: "1.6rem 0",
    // height: '12.8rem',
    // 모서리 둥글게
    borderRadius: '1.6rem',
    // 텍스트 및 콘텐츠 정렬
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'space-between',
    // 콘텐츠 정렬 방향
    flexDirection: 'column',


    "@media (max-width: 768px)": {
        padding: "8px 0px 12px 0px",
        "@media (max-height: 710px)": {
            padding: "12px 0px 12px 0px",
            fontSize: '14px',
            lineHeight: '20px',
            marginBottom: "8px",
        },
    },


    // 선택 시 색상
    '&.Mui-selected': {
        // background 색상(선택 O)
        backgroundColor: selectedBackgroundColor,
    },

    // hover 시 색상 (현재 채널 선택 여부에 따라 달라짐)
    '&.Mui-selected:hover': {
        backgroundColor: selected === false
            ? darken(0.2, `${unselectedBackgroundColor}`)
            : lighten(0.0, `${selectedBackgroundColor}`),
    }
}));

const ChannelButtonTitle = muiStyled(Typography)(({selected, theme}) => ({
    // 선택 시 텍스트 색상 : Greyscale/white
    ...(
        selected === true && {
            color: props.theme.colors.white,
        }
    ),
    // 미선택 시 텍스트 색상 : Greyscale/80
    ...(
        selected === false && {
            color: props.theme.colors.grey80,
        }
    ),

    letterSpacing: '-0.01px',
    /* Title/1_Bold 16 */
    fontSize: '1.6rem',
    lineHeight: '2.2rem',
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    width: "14.0rem",
    fontFamily: 'PretendardBold, serif',
    marginBottom: "1.2rem",

    "@media (max-width: 768px)": {
        "@media (max-height: 710px)": {
            fontSize: '14px',
            lineHeight: '20px',
            marginBottom: "8px",
        },
    },
}));

const ChannelButtonSubscriberWrapper = muiStyled(Box)(({theme}) => ({
    /* Auto layout */
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
}));


function ChannelButtonSubscriberLogo({selected}) {
    return (
        <svg width="1.6rem" height="1.6rem" viewBox="0 0 1.6rem 1.6rem" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M11.7584 4.4295C11.7585 4.85805 11.6741 5.28241 11.5102 5.67835C11.3462 6.0743 11.1059 6.43407 10.8029 6.73713C10.4999 7.04019 10.1401 7.2806 9.74424 7.44464C9.34833 7.60868 8.92399 7.69313 8.49544 7.69317C7.62995 7.69326 6.79987 7.34953 6.18782 6.7376C5.57576 6.12567 5.23186 5.29566 5.23177 4.43017C5.23173 4.00162 5.31609 3.57726 5.48005 3.18132C5.64401 2.78538 5.88435 2.42561 6.18735 2.12255C6.79928 1.51049 7.62928 1.16659 8.49477 1.1665C9.36026 1.16642 10.1903 1.51015 10.8024 2.12208C11.4145 2.73401 11.7584 3.56401 11.7584 4.4295M8.49511 8.6915C3.79577 8.6915 1.96777 11.6822 1.96777 13.0735C1.96777 14.4642 5.85911 14.8348 8.49511 14.8348C11.1311 14.8348 15.0224 14.4642 15.0224 13.0735C15.0224 11.6822 13.1944 8.6915 8.49511 8.6915Z"
                  fill={selected ? "#C9CCD7" : "#C9CCD7"}/>
        </svg>
    );
}

const ChannelButtonSubscriberText = muiStyled(Typography)(({selected, theme}) => ({
    // 선택 시 텍스트 색상 : Greyscale/20
    ...(
        selected === true && {
            color: props.theme.colors.grey20,
        }
    ),
    // 미선택 시 텍스트 색상 : Greyscale/40
    ...(
        selected === false && {
            color: props.theme.colors.grey40,
        }
    ),

    /* Body/3_Medium 13 */
    fontSize: '1.3rem',
    lineHeight: '1.6rem',
    fontFamily: 'PretendardMedium, serif',

    /* Inside auto layout */
    marginLeft: "0.8rem",

    // "@media (max-width: 375px)": {  // textOverflow 때문에 line break 필요
    //     fontSize: '10.68px',
    //     lineHeight: "13.15px",
    //     marginLeft: "6.56px",
    //
    // },


}));


const ChannelImg = muiStyled(Avatar)(({}) => ({
    width: "3.6rem",
    height: "3.6rem",
    margin: "auto",
    marginBottom: "1rem",

    "@media (max-width: 768px)": {
        "@media (max-height: 710px)": {
            width: "24px",
            height: "24px",
            margin: "auto",
            marginBottom: "8px",
        },
    },


}));


function ChannelButton({index, selected, setSelected, name, profile, currentSubscriberNum}) {
    return (
        <ChannelButtonBase
            disableRipple
            id ={"channel_btn"}
            className={("channel_btn" + matchMedia('only screen and (max-width: 768px)').matches) ? '' : 'swiper-no-swiping'}
            value={name}
            selected={selected}
            onChange={() => {
                setSelected();
            }}
        >
            <B.Wrapper>
                <ChannelImg
                    alt="Channel Image"
                    src={profile}
                    sx={{width: '3.6rem', height: '3.6rem'}}
                    style={{margin: "auto", marginBottom: "1rem"}}
                />
                <ChannelButtonTitle id ={"name"} selected={selected}>{name}</ChannelButtonTitle>
            </B.Wrapper>
            <ChannelButtonSubscriberWrapper>
                <ChannelButtonSubscriberLogo selected={selected}/>
                <ChannelButtonSubscriberText selected={selected}>{currentSubscriberNum}</ChannelButtonSubscriberText>
            </ChannelButtonSubscriberWrapper>
        </ChannelButtonBase>
    );
}


function ChannelButtonGrid({channelList, selectedChannelIndex, setSelectedChannelIndex}) {
    const {height, width} = useWindowDimensions();



    const gridItemWidth = () => {
        if(width >= theme.breakPoint.firstWidthBreak) {
            return css`
              grid-template-columns: 166px 166px;
            `;
        } else {
            return css`
              grid-template-columns: 136px 136px;
            `;
        }

    };

    const showForItemGrid = () => {
        if(width <= 768 && height <= 608) {
            return true
        } else {
            return false;
        }
    };
    const gridRows = [];


    for (let i = 0; i < channelList.length; i++) {
        gridRows.push(
            <ChannelButton
                index ={i}
                selected={selectedChannelIndex === i}
                setSelected={() => setSelectedChannelIndex(i)}
                name={channelList[i].name}
                profile={channelList[i].profile}
                currentSubscriberNum={channelList[i].getCurrentSubscriberNumStr()}
            />
        );
    }

    return (
        <B.Grid gridItemWidth={gridItemWidth()}>
            {gridRows.slice(0, showForItemGrid() ? 4 :  6)}
        </B.Grid>
    );
}

const B = {
    Grid: styled.div`
      display: inline-grid;
      grid-template-columns: 16.6rem 16.6rem 16.6rem;
      row-gap: 2.6rem;
      column-gap: 2.0rem;
      
      @media ${({theme}) => theme.device.mobile} {
        ${props => props.gridItemWidth};
        grid-template-rows: 128px 128px 128px;
        row-gap: 1.6rem;
        column-gap: 1.2rem;
        

        @media screen and (max-height: ${theme.breakPoint.thirdHeightBreak}) {
          ${props => props.gridItemWidth};
          grid-template-rows: 99px 99px 99px;
          row-gap: 1.6rem;
          column-gap: 1.1rem;
        }

        @media screen and (max-height: ${theme.breakPoint.fifthHeightBreak}) {
          ${props => props.gridItemWidth};
          grid-template-rows: 100px 100px;
          row-gap: 1.6rem;
          column-gap: 1.1rem;
        }
      };

      
      
      
      
    `,

    Wrapper: styled.div``,
}


export default ChannelButtonGrid;




// import React, {useEffect} from 'react';
// import {styled as muiStyled} from '@mui/material/styles';
// import Avatar from '@mui/material/Avatar';
// import Box from '@mui/material/Box';
// import SvgIcon from '@mui/material/SvgIcon';
// import ToggleButton from '@mui/material/ToggleButton';
// import Typography from '@mui/material/Typography';
// import '../../styles/global.css';
// import * as props from "../../styles/theme";
// import {darken, lighten} from "polished";
// import styled from "styled-components";
// import useWindowDimensions from "../../feature/useWindowDimensions";
//
//
// // background 색상(선택 X) : BlueGrey10
// const unselectedBackgroundColor = props.theme.colors.blueGrey10;
// // background 색상(선택 O) : Greyscale/80
// const selectedBackgroundColor = props.theme.colors.grey80;
//
// const ChannelButtonBase = muiStyled(ToggleButton)(({selected, theme}) => ({
//     // background 색상(선택 X)
//     backgroundColor: unselectedBackgroundColor,
//     // 테두리 없음
//     border: 0,
//     // 크기 및 padding
//     width: '100%',
//     height: '100%',
//     padding: "1.6rem 0",
//     // height: '12.8rem',
//     // 모서리 둥글게
//     borderRadius: '1.6rem',
//     // 텍스트 및 콘텐츠 정렬
//     textAlign: 'center',
//     alignItems: 'center',
//     justifyContent: 'space-between',
//     // 콘텐츠 정렬 방향
//     flexDirection: 'column',
//
//     "@media (max-width: 375px)": {  // textOverflow 때문에 line break 필요
//         padding: "13.5px 0",
//     },
//
//
//     // 선택 시 색상
//     '&.Mui-selected': {
//         // background 색상(선택 O)
//         backgroundColor: selectedBackgroundColor,
//     },
//
//     // hover 시 색상 (현재 채널 선택 여부에 따라 달라짐)
//     '&.Mui-selected:hover': {
//         backgroundColor: selected === false
//             ? darken(0.2, `${unselectedBackgroundColor}`)
//             : lighten(0.0, `${selectedBackgroundColor}`),
//     }
// }));
//
// const ChannelButtonTitle = muiStyled(Typography)(({selected, theme}) => ({
//     // 선택 시 텍스트 색상 : Greyscale/white
//     ...(
//         selected === true && {
//             color: props.theme.colors.white,
//         }
//     ),
//     // 미선택 시 텍스트 색상 : Greyscale/80
//     ...(
//         selected === false && {
//             color: props.theme.colors.grey80,
//         }
//     ),
//
//     letterSpacing: '-0.01px',
//     /* Title/1_Bold 16 */
//     fontSize: '1.6rem',
//     lineHeight: '2.2rem',
//     textOverflow: "ellipsis",
//     whiteSpace: "nowrap",
//     overflow: "hidden",
//     width: "14.0rem",
//     fontFamily: 'PretendardBold, serif',
//     marginBottom: "1.2rem",
//
//     "@media (max-width: 375px)": {
//         marginBottom: "8.95px",
//         width: "109px",
//
//         "@media (max-height: 618px)": {
//             fontSize: '12px',
//             lineHeight: '1.6rem',
//             marginBottom: "6.95px",
//             width: "100px",
//         },
//     },
//
//
// }));
//
// const ChannelButtonSubscriberWrapper = muiStyled(Box)(({theme}) => ({
//     /* Auto layout */
//     display: 'flex',
//     flexDirection: 'row',
//     justifyContent: 'center',
//     alignItems: 'center',
//     padding: 0,
// }));
//
//
// function ChannelButtonSubscriberLogo({selected}) {
//     return (
//         <svg width="1.6rem" height="1.6rem" viewBox="0 0 1.6rem 1.6rem" fill="none" xmlns="http://www.w3.org/2000/svg">
//             <path fill-rule="evenodd" clip-rule="evenodd"
//                   d="M11.7584 4.4295C11.7585 4.85805 11.6741 5.28241 11.5102 5.67835C11.3462 6.0743 11.1059 6.43407 10.8029 6.73713C10.4999 7.04019 10.1401 7.2806 9.74424 7.44464C9.34833 7.60868 8.92399 7.69313 8.49544 7.69317C7.62995 7.69326 6.79987 7.34953 6.18782 6.7376C5.57576 6.12567 5.23186 5.29566 5.23177 4.43017C5.23173 4.00162 5.31609 3.57726 5.48005 3.18132C5.64401 2.78538 5.88435 2.42561 6.18735 2.12255C6.79928 1.51049 7.62928 1.16659 8.49477 1.1665C9.36026 1.16642 10.1903 1.51015 10.8024 2.12208C11.4145 2.73401 11.7584 3.56401 11.7584 4.4295M8.49511 8.6915C3.79577 8.6915 1.96777 11.6822 1.96777 13.0735C1.96777 14.4642 5.85911 14.8348 8.49511 14.8348C11.1311 14.8348 15.0224 14.4642 15.0224 13.0735C15.0224 11.6822 13.1944 8.6915 8.49511 8.6915Z"
//                   fill={selected ? "#C9CCD7" : "#C9CCD7"}/>
//         </svg>
//     );
// }
//
// const ChannelButtonSubscriberText = muiStyled(Typography)(({selected, theme}) => ({
//     // 선택 시 텍스트 색상 : Greyscale/20
//     ...(
//         selected === true && {
//             color: props.theme.colors.grey20,
//         }
//     ),
//     // 미선택 시 텍스트 색상 : Greyscale/40
//     ...(
//         selected === false && {
//             color: props.theme.colors.grey40,
//         }
//     ),
//
//     /* Body/3_Medium 13 */
//     fontSize: '1.3rem',
//     lineHeight: '1.6rem',
//     fontFamily: 'PretendardMedium, serif',
//
//     /* Inside auto layout */
//     marginLeft: "0.8rem",
//
//     "@media (max-width: 375px)": {  // textOverflow 때문에 line break 필요
//         fontSize: '10.68px',
//         lineHeight: "13.15px",
//         marginLeft: "6.56px",
//
//     },
//
//
// }));
//
//
// const ChannelImg = muiStyled(Avatar)(({}) => ({
//     width: "3.6rem",
//     height: "3.6rem",
//     margin: "auto",
//     marginBottom: "1rem",
//
//     "@media (max-width: 375px)": {
//         width: "29.6px",
//         height: "29.6px",
//         margin: "auto",
//         marginBottom: "8.22px",
//
//         "@media (max-height: 618px)": {
//             width: "22.6px",
//             height: "22.6px",
//             margin: "auto",
//             marginBottom: "8.22px",
//         },
//
//     },
//
//
// }));
//
//
// function ChannelButton({selected, setSelected, name, profile, currentSubscriberNum}) {
//     return (
//         <ChannelButtonBase
//             className='swiper-no-swiping'
//             id="channel_btn"
//             value={name}
//             selected={selected}
//             onChange={() => {
//                 setSelected();
//             }}
//         >
//             <B.Wrapper>
//                 <ChannelImg
//                     alt="Channel Image"
//                     src={profile}
//                     sx={{width: '3.6rem', height: '3.6rem'}}
//                     style={{margin: "auto", marginBottom: "1rem"}}
//                 />
//                 <ChannelButtonTitle id="name" selected={selected}>{name}</ChannelButtonTitle>
//             </B.Wrapper>
//             <ChannelButtonSubscriberWrapper>
//                 <ChannelButtonSubscriberLogo selected={selected}/>
//                 <ChannelButtonSubscriberText selected={selected}>{currentSubscriberNum}</ChannelButtonSubscriberText>
//             </ChannelButtonSubscriberWrapper>
//         </ChannelButtonBase>
//     );
// }
//
// function ChannelButtonGrid({channelList, selectedChannelIndex, setSelectedChannelIndex}) {
//     const {height, width} = useWindowDimensions();
//
//     useEffect(()=> {
//         console.log(height);
//     },[height, ]);
//
//     const showForItemGrid = () => {
//         if(width <= 768 && height <= 525 ) {
//             return true
//         } else {
//             return false;
//         }
//     };
//     const gridRows = [];
//
//
//     for (let i = 0; i < channelList.length; i++) {
//         gridRows.push(
//             <ChannelButton
//                 selected={selectedChannelIndex === i}
//                 setSelected={() => setSelectedChannelIndex(i)}
//                 name={channelList[i].name}
//                 profile={channelList[i].profile}
//                 currentSubscriberNum={channelList[i].getCurrentSubscriberNumStr()}
//             />
//         );
//     }
//
//     return (
//         <B.Grid>
//             {gridRows.slice(0, showForItemGrid() ? 4 :  6)}
//         </B.Grid>
//     );
// }
//
// const B = {
//     Grid: styled.div`
//       display: inline-grid;
//       grid-template-columns: 16.6rem 16.6rem 16.6rem;
//       row-gap: 2.6rem;
//       column-gap: 2.0rem;
//
//       @media ${({theme}) => theme.device.mobile} {
//         grid-template-columns: 16.6rem 16.6rem;
//         row-gap: 1.6rem;
//         column-gap: 1.1rem;
//       }
//
//       @media ${({theme}) => theme.device.mobileM}  {
//         @media screen and (max-height: 612px) {
//           grid-template-columns: 136px 136px;
//           grid-template-rows: 104px 104px 104px;
//           row-gap: 11px;
//           column-gap: 9px;
//         };
//       }
//
//         @media ${({theme}) => theme.device.mobileS} {
//         grid-template-columns: 134px 136px;
//         grid-template-rows: 104px 104px 104px;
//         row-gap: 14px;
//         column-gap: 9px;
//
//         @media ${({theme}) => theme.device.investPreventSizedHeight} {
//           top: inherit;
//           grid-template-columns: 136px 136px;
//           grid-template-rows: 94px 94px 94px;
//           row-gap: 14px;
//           column-gap: 9px;
//         };
//       }
//
//
//
//     `,
//
//     Wrapper: styled.div``,
// }
//
//
// export default ChannelButtonGrid;