import React from 'react';
import '../../styles/modal.css';
import styled from "styled-components";
import Icon from "../../utils/icon";
import {fontTheme} from "../../styles/fonts";

/** Modal Dialog
*  - 모달 다이어로그 노출 여부 , 다이어로그 닫기, 제목, 컨텐츠(본문)을 props로 전달 받음
* */

const AppModal = (props) => {
    const {isOpen, closeModal, title, content} = props;

    return (
        <div onClick={() => closeModal()}
            className={matchMedia('only screen and (max-width: 768px)').matches ? (isOpen ? 'openModal modal' : 'modal') : (isOpen ? 'openModal modal swiper-no-swiping' : 'modal swiper-no-swiping')}>

            {isOpen ? (
                <S.Container>
                    <S.TitleWrapper>
                        <S.Title>{title}</S.Title>
                        <S.CloseBtn>
                            <S.CloseIc name="close" onClick={(e) => {
                                e.preventDefault();
                                closeModal();
                            }}/>
                        </S.CloseBtn>
                    </S.TitleWrapper>
                    <S.Content>{content}</S.Content>
                </S.Container>
            ) : null}
        </div>
    );
};

const S = {
    Content: styled.p`
      color: ${props => props.theme.colors.grey40};
      white-space: pre-wrap;
      ${fontTheme.body2};
    `,

    Title: styled.h2`
      color: ${props => props.theme.colors.black};
      ${fontTheme.headline3};
    `,

    CloseIc: styled(Icon)`
      height: 100%;
      width: 100%;
    `,

    CloseBtn: styled.button`
      height: 24px;
      width: 24px;
      background: white;
      cursor: pointer;
      margin-left: 16px;
    `,

    TitleWrapper: styled.div`
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;

    `,

    Container: styled.section`
      width: 327px;
      padding: 24px 24px 20px 24px;
      border-radius: 20px;
      background-color: #fff;
      position: relative;
      /* 팝업이 열릴때 스르륵 열리는 효과 */
      animation: modal-show 0.3s;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: start;
      margin: 0 auto;
    `,
}


export default AppModal;