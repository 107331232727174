import React, { useEffect } from "react";
import animationData from '../../assets/lotties/confetti.json';
import styled from "styled-components";
import Lottie from 'react-lottie';

const LottieComponent = (props) => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return(
        <B.LottieContainer>
            <Lottie options={defaultOptions}/>
        </B.LottieContainer>
    )
}

const B = {
    LottieContainer: styled.div`
      
      width: 370px;
      height: 370px;

      // 태블릿 한정
      @media only screen and (max-width: 1024px) and (min-width: 768px) {
        width: 284px;
        height: 284px;
      }
    `
}

export default LottieComponent;