export class ChannelEntity {
    // constructor(생성자)
    constructor(name, profile, currentSubscriberNum, beforeSubscriberNum,
                diffSubscriberNum, currentViewCount, beforeViewCount, diffViewCount, profitPerOneStock, tradingProfitRate) {
        // fields
        this.name = name;
        this.profile = profile;
        this.currentSubscriberNum = currentSubscriberNum;
        this.beforeSubscriberNum = beforeSubscriberNum;
        this.diffSubscriberNum = diffSubscriberNum;
        this.currentViewCount = currentViewCount;
        this.beforeViewCount = beforeViewCount;
        this.diffViewCount = diffViewCount;
        this.profitPerOneStock = profitPerOneStock;
        this.tradingProfitRate = tradingProfitRate;
    }

    // methods
    toString() {
        return this.name;
    }

    getCurrentSubscriberNumStr() {
        const parts = (this.currentSubscriberNum / 10000).toString().split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return `${parts.join('.')}만명`;
    }
}