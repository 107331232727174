import {css} from "styled-components";


/* Web */
const Web1 = css`
  font-size: 40px;
  line-height: 52px;
  font-family: "PretendardBold", serif;
`;

const Web2 = css`
  font-size: 36px;
  line-height: 46px;
  font-family: "PretendardBold", serif;
`;

const Web3 = css`
  font-size: 32px;
  line-height: 48px;
  font-family: "PretendardBold", serif;
`;


/* Headline */

const Headline1 = css`
  font-size: 24px;
  line-height: 37px;
  font-family: "PretendardBold", serif;
`


const Headline2 = css`
  font-size: 20px;
  line-height: 30px;
  font-family: "PretendardBold", serif;
`

const Headline3 = css`
  font-size: 18px;
  line-height: 22px;
  font-family: "PretendardSemiBold", serif;
`

/* Title */
const Title1 = css`
  font-size: 16px;
  line-height: 22px;
  font-family: "PretendardBold", serif;
`

const Title2 = css`
  font-size: 16px;
  line-height: 20px;
  font-family: "PretendardSemiBold", serif;
`

const Title3 = css`
  line-height: 20px;
  font-family: "PretendardSemiBold", serif;
`

/* Body */
const Body1 = css`
  font-size: 14px;
  line-height: 22px;
  font-family: "PretendardSemiBold", serif;
`

const Body2 = css`
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.6px;
  font-family: "PretendardMedium", serif;
`

const Body3 = css`
  font-size: 13px;
  line-height: 16px;
  font-family: "PretendardMedium", serif;
`

const Alert1 = css`
  font-size: 12px;
  line-height: 18px;
  font-family: "PretendardSemiBold", serif;
`;

const Alert2 = css`
  font-size: 12px;
  line-height: 18px;
  font-family: "PretendardRegular", serif;
`;


export const fontTheme = {
    // Web
    web1: Web1,
    web2: Web2,
    web3: Web3,

    // Headline
    headline3: Headline3,
    headline2: Headline2,
    headline1: Headline1,

    // Title
    title1: Title1,
    title2: Title2,
    title3: Title3,

    // Body
    body1: Body1,
    body2: Body2,
    body3: Body3,

    // Alert
    alert1: Alert1,
    alert2: Alert2,
}