/**
 * 컴포넌트의 ViewPort 값들을 계산해 처음 컴포넌트가 로드 될 때
 * 해당 컴포넌트의 애니메이션 실행 여부를 판단하고 값을 저장하는 메소드
 * */

export const loadAppearAnimation = (inViewport, enterCount, setAniActivated) => {
    if (inViewport && enterCount === 1) {
        setAniActivated(true)
    } else if (!inViewport && enterCount < 1) {
        setAniActivated(false);
    } else {
        return {};
    }
}