import styled from "styled-components";
import {ContentSection} from "../../components/ui/ContentSection";
import {fontTheme} from "../../styles/fonts";

const size = {
    mobileM : "57rem",
}

export const S = {
    TopSectionWrapper: styled.div`
      position: relative;
      ${props => props.theme.appearAnimation.first};
    `,

    BottomSectionWrapper: styled.div`
      position: relative;
      ${props => props.theme.appearAnimation.second};
    `,


    ContentSection: styled.section`
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: center;
      margin: 0 auto;
      width: 37.5rem;
      overflow: hidden;
      
      @media ${({theme}) => theme.device.mobile} {
        @media ${({theme}) => theme.device.contentHeight} {
      
        }
      }
      
      @media screen and (max-width: 407px) {
        width: 100%;
        padding: 0 1.6rem;
      }
    `,


    TopSizedBox: styled.div`
      height: 100px;
      display: none;
      
      @media ${({theme}) => theme.device.mobile} {
        height: 4%;
        
      }
      // @media ${({theme}) => theme.device.contentHeight} {
      //   display: block;
      // }
      
      //@media screen and (min-height:635px) {
      //  height: 1%;
      //  background: blue;
      //}
    `,

    LeadingTitle: styled.span`
      display: block;
      position: relative;
      color: ${props => props.theme.colors.blue30};
      margin-bottom: 0.8rem;
      ${fontTheme.title2};
      @media screen and (max-height: ${size.mobileM}) {
        ${fontTheme.body1};
        
      }
      
    `,

    ServiceKeyword: styled.span`
      display: block;
      color: ${props => props.theme.colors.grey30};
      margin-bottom: 3.2rem;
      ${fontTheme.web3};
      @media screen and (max-height: ${size.mobileM}) {
        font-size: 2.6rem;
        line-height: 4rem;
      }
    `,
    StrongKeyword: styled.span`
      color: ${props => props.theme.colors.black};
    `,

    SizedBox: styled.div`
      height: 0.8rem;
    `,

    SubDescription: styled.span`
      display: block;
      white-space: pre-wrap;
      margin: 0 0 2.4rem 0.6rem;
      color: ${props => props.theme.colors.grey30};
      ${fontTheme.alert2};
    `,
}

export const SC = {
    Container: styled.div`
      display: flex;
      width: 100%;
      border-radius: 1.6rem;
      background-color: #F6F8FC;
      padding-right: 1.6rem;
      
      @media screen and (max-height: ${size.mobileM}) {
        
      }
    `,

    SectionNum: styled.span`
      display: block;
      margin: 1.4rem 0 0 1.6rem;
      color: #BCC4E2;
      ${fontTheme.title1};
      
      @media screen and (max-width: 407px) {
        margin-left: 4%;
      }
      @media screen and (max-height: ${size.mobileM}) {
        margin: 1.2rem 0 0 1.4rem;
        ${fontTheme.title3};
      }
      
      
    `,

    Strong: styled.span`
      color: ${props => props.theme.colors.grey40}
    `,

    Content: styled.span`
      display: block;
      margin : 3.3rem 0 3.2rem 4rem;
      color: ${props => props.theme.colors.black};
      white-space: pre-wrap;
      ${fontTheme.headline2};
      @media screen and (max-width: 407px) {
        margin-left: 2%;
        
      }
      @media screen and (max-height: ${size.mobileM}) {
        ${fontTheme.title1};
        margin : 3rem 0 2.8rem 3rem;
      }
    `,
}