import React, {useContext} from 'react';

import AppButton from "../../components/ui/NewAppButton";
import styled from "styled-components";
import {remFontTheme} from "../../styles/remFonts";
import {fontTheme} from "../../styles/fonts";
import Page from "../../components/layout/Page";
import AppSlider from "../../components/ui/Slider";
import {ChannelContext} from "../../context/channelContext";
import ScrollNotiBox from "../../components/ui/ScrollNotiBox";
import DemoPage from "../../components/layout/BackgroundPage";
import {theme} from "../../styles/theme";

function inputPriceStr(profit) {
    const parts = profit.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return `${parts.join('.')}원`;
}

const DemoInvestmentSubView2 = (props) => {
    const {goToNextPage} = useContext(ChannelContext);
    const {channel, stockCount, setStockCount} = props;

    return (
        <DemoPage id="invest_2_page" className="swiper-slide slide-3">
            <B.ContentSection>
                <B.TopSection>
                    <B.LeadingTitle>얼마를 투자하시겠어요?</B.LeadingTitle>
                    <B.SubDescription>투자금액에 따라 받는 수익금이 달라져요</B.SubDescription>
                </B.TopSection>
                <B.CenterSection>
                    <B.PriceCard>
                        <B.CardInfoWrapper>
                            <B.RoundChannelImg
                                alt="Channel Image"
                                src={channel.profile}
                            />
                            <B.ChannelName>
                                {channel.name}
                            </B.ChannelName>
                        </B.CardInfoWrapper>
                        <B.Price>
                            {inputPriceStr(stockCount * 2000)}
                        </B.Price>
                        <AppSlider
                            value={stockCount}
                            onChange={(event, newValue) => {
                                setStockCount(newValue);
                            }}
                        />
                    </B.PriceCard>
                </B.CenterSection>
                <B.ButtonWrapper>
                    <AppButton isResponsive={false} isActive={stockCount !== 0} id="confirm_btn" type='full-width'
                               onClick={() => {
                                   if (stockCount !== 0) {
                                       goToNextPage(2);
                                   }
                               }}>수익금 확인하기</AppButton>
                </B.ButtonWrapper>
            </B.ContentSection>
            {/*<B.ScrollNotiWrapper>*/}
            {/*    <ScrollNotiBox isActive={false} />*/}
            {/*</B.ScrollNotiWrapper>*/}
        </DemoPage>
    );
}

const B = {
    ScrollNotiWrapper: styled.div`
      position: absolute;
      bottom: 70px;
      left: 50%;
      z-index: 100;
      transform: translate(-50%, 0);


      @media ${({theme}) => theme.device.mobile} {
        display: none;
      };
    `,

    TopSection: styled.div`
      margin-bottom: 5.4rem;
      @media ${({theme}) => theme.device.mobile} {
        @media screen and (max-height: ${theme.breakPoint.firstHeightBreakDemo2}) {
         margin-bottom: 32px;     
        }

        @media screen and (max-height: ${theme.breakPoint.secondHeightBreakDemo2}) {
          margin-bottom: 20px;
        }
      };
    }

    ;


    `,
    CenterSection: styled.div`
      width: 100%;
    `,

    ContentSection: styled.div`
      height: fit-content;
      width: 55.2rem;
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: auto auto;
      position: relative;


      @media ${({theme}) => theme.device.mobile} {
        flex-direction: column;
        top: 28px;
        justify-content: space-between;
        width: 375px;

        @media screen and (max-width: ${theme.breakPoint.firstWidthBreakDemo2}) {
          width: 288px;
        }        
      };



    `,

    ChannelName: styled.span`
      color: ${props => props.theme.colors.grey50};
      ${remFontTheme.body2};
    `,

    CardInfoWrapper: styled.div`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    `,

    Price: styled.span`
      ${remFontTheme.web1};
      color: ${props => props.theme.colors.black};
      margin-bottom: 3px;
    `,

    PriceCard: styled.div`
      padding: 2.8rem 6.4rem 3.7rem 6.4rem;
      width: 100%;
      height: 33.4rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: ${props => props.theme.colors.blueGrey10};
      justify-content: space-between;
      margin-bottom: 40px;
      border-radius: 2.0rem;

      @media ${({theme}) => theme.device.mobile} {
        margin-bottom: 32px;
        padding: 20px 13px 30px 13px;

        @media screen and (max-height: ${theme.breakPoint.secondHeightBreakDemo2}) {
          height: 236px;
        }

        
      };




    `,

    RoundChannelImg: styled.img`
      width: 2.8rem;
      height: 2.8rem;
      margin-bottom: 0.7rem;
      border-radius: 50%;
    `,


    LeadingTitle: styled.h1`
      text-align: center;
      color: ${props => props.theme.colors.black};
      ${remFontTheme.web3};
      margin-bottom: 0.8rem;

      @media ${({theme}) => theme.device.mobile} {
        ${fontTheme.headline1};
        margin-bottom: 4px;
      };




    `,

    SubDescription: styled.span`
      display: block;
      color: ${props => props.theme.colors.grey30};
      ${remFontTheme.headline3};


      @media ${({theme}) => theme.device.mobile} {
        ${fontTheme.title2}


      };




    `,

    ElseInfo: styled.span`
      color: ${props => props.theme.colors.grey60};
      ${fontTheme.title3};
      font-size: 14px;

      @media ${({theme}) => theme.device.tablet} {
        font-size: 13px;
      };
    `,

    ElseInfoWrapper: styled.div`
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 2.4rem;

      @media ${({theme}) => theme.device.mobile} {
        display: none;
      };
    `,

    Divider: styled.div`
      margin: 0 0.8rem;
      background-color: ${props => props.theme.colors.grey20};
      height: 14px;
      width: 1px;
    `,

    ButtonWrapper: styled.div`
      width: 343px;


      @media ${({theme}) => theme.device.mobile} {
        width: 100%
      };
    `,
}

export default DemoInvestmentSubView2;

