import * as React from 'react';
import {styled} from "@mui/material/styles";
import Box from '@mui/material/Box';
import { SvgIcon } from '@mui/material';
import * as props from "../../styles/theme";
import labelUp from '../../assets/images/label_up.png';
import labelDown from '../../assets/images/label_down.png';
import {backgrounds} from "polished";
import Typography from "@mui/material/Typography";

const AppLabelBase = styled((otherProps) => (
    <Box component="span" {...otherProps}/>
))(({ type, theme }) => ({
    ...(
        (type === 'up' || type === 'normal' || type === 'upNoArrow') && {
            background: '#FFF2F6',
            // Sub/Notice, Highlight
            color: props.theme.colors.pink,
        }
    ),
    ...(
        (type === 'down' || type === 'downNoArrow') && {
            background: '#F2F5FF',
            // Sub/Information
            color: props.theme.colors.subBlue,
        }
    ),

    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',

    // padding
    padding: '4px 6px',
    // 모서리 둥글게
    borderRadius: '8px',
}));

const AppLabelTextBase = styled((otherProps) => (
    <Typography component="span" {...otherProps}/>
))(({ theme }) => ({
    // 폰트 관련 설정
    fontFamily: 'PretendardMedium, serif',
    fontSize: '13px',
    lineHeight: '16px',
}));

export default function AppLabel({type, text}) {
    if(type === 'up' || type === 'down') {
        return (
            <AppLabelBase type={type}>
                <AppLabelTextBase>{text}</AppLabelTextBase>
                <img
                    src={type === 'up' ? labelUp : labelDown}
                    alt={type}
                    width='12px'
                    height='12px'
                />
            </AppLabelBase>
        );
    }

    return (
        <AppLabelBase type={type}>
            <AppLabelTextBase>{text}</AppLabelTextBase>
        </AppLabelBase>
    );
}