import React, {useEffect, useState} from 'react';
import Page from "../../components/layout/Page";
import handleViewport from "react-in-viewport";
import {loadAppearAnimation} from "../../feature/loadAppearAnimation";
import {S, SC} from "./ServiceMainIntroduction.style";
import Div100vh from "react-div-100vh";
import styled from "styled-components";

/* 1 서비스 설명 수익금 */

const ServiceMainIntroductionPage = (props) => {
    const [animationActivated, setAnimationActivated] = useState(false);
    const {inViewport, forwardedRef, enterCount} = props;

    // Appear Animation effect 처리
    useEffect(() => {
        loadAppearAnimation(inViewport, enterCount, setAnimationActivated);
    }, [enterCount, inViewport]);

    return <Page id="main_introduction_page" className="swiper-slide slide-1">
            <S.ContentSection>
                <S.TopSizedBox/>
                <S.TopSectionWrapper activate={animationActivated}>
                    <S.LeadingTitle>어떻게 수익금을 얻나요?</S.LeadingTitle>
                    <S.ServiceKeyword>크리에이터와<br/><S.StrongKeyword>광고수익금</S.StrongKeyword><br/>공유하기</S.ServiceKeyword>
                </S.TopSectionWrapper>
                <S.BottomSectionWrapper activate={animationActivated}>
                    <ServiceInfoContainer height='120px' sectionNum={1} content="옥션에서 광고수익청구권을 사고"/>
                    <S.SizedBox/>
                    <S.SubDescription ref={forwardedRef}>‘광고수익청구권'이란 광고 조회 수익금의 일부를<br/>청구할 수 있고 받을 수 있는
                        권리입니다.</S.SubDescription>
                    <ServiceInfoContainer height='140px' sectionNum={2} content={`유튜브채널의\n광고수익금을 매달 나눠받기`}/>
                </S.BottomSectionWrapper>
            </S.ContentSection>
    </Page>

};




const ServiceInfoContainer = (props) => {
    const {height, sectionNum, content} = props;

    return <SC.Container height={height}>
        <SC.SectionNum>0<SC.Strong>{sectionNum}</SC.Strong></SC.SectionNum>
        <SC.Content>{content}</SC.Content>
    </SC.Container>
}


export default handleViewport(ServiceMainIntroductionPage);