import React from 'react';
import styled from "styled-components";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// Import Swiper React components

import{ Mousewheel, Keyboard } from "swiper";

const Page = (props) => {
    const {id, backgroundColor, className} = props;
    return <Container id={id} color={backgroundColor} className={className}>
                {props.children}
    </Container>
};

const Container = styled.div`
  display: inline-flex;
  height: 100%;
  width: 100%;
  //width: 100vw;
  /*background-color: white;*/
  flex-direction: column;
  align-items: start;
  overflow: hidden;
`;


export default Page;