import React, { useState } from "react";
import {
  NavbarContainer,
  MainLogo,
  ButtonWrapper,
  NavbarButton,
  EmptyBox,
  LeadingWrapper,
} from "../Navbar/Navbar.style";
import styled from "styled-components";
import AppDownloadButton from "../AppDownloadButton";
import { fontTheme } from "../../../styles/fonts";
import {
  isAndroid,
  isIOS,
  isMobile,
  isSafari,
  CustomView,
  isMacOs,
} from "react-device-detect";
import Icon from "../../../utils/icon";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const NavBar = (props) => {
  const [isDropDownMenuShowed, setIsDropDownMenuShowed] = useState(false);
  const { openIosConfirmModal, goToIntroductionPage } = props;

  function routeToMarketPlace() {
    if (isMobile && isIOS) {
      //ios mobile
      window.location.href = "https://ourtube.onelink.me/AcWx/ksc83dbb";
    } else if (isMobile && isAndroid) {
      // Android
      window.location.href = "https://ourtube.onelink.me/AcWx/ksc83dbb";
    } else if (isSafari) {
      // ios mobile safarri
      window.location.href = "https://ourtube.onelink.me/AcWx/ksc83dbb";
    } else if (isMacOs) {
      // mac 웹
      window.location.href =
        "https://apps.apple.com/kr/app/ourtube/id1579637479";
    } else {
      // 그 외 Web
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.ourstock.ourtube_app";
    }
  }

  function menuIconTapHandler() {
    if (isDropDownMenuShowed === true) {
      setIsDropDownMenuShowed(false);
    } else {
      setIsDropDownMenuShowed(true);
    }
  }

  function onCategoryBtnTapHandler(index) {
    goToIntroductionPage(index);
    setIsDropDownMenuShowed(false);
    // eslint-disable-next-line default-case
  }

  return (
    <NavBarContainerWrapper>
      <NavbarContainer>
        <ContentSection>
          <LeadingWrapper>
            <MenuButton
              name={"menu"}
              href="#"
              onClick={(e) => {
                e.preventDefault();
                menuIconTapHandler();
              }}
            />

            <MainLogo
              name={"ourtubeMainLogo"}
              onClick={(e) => {
                e.preventDefault();
                window.location.reload();
              }}
            />
          </LeadingWrapper>
          <EmptyBox />
          <ShowOnlyMobile>
            <AppDownloadButton onClick={routeToMarketPlace} />
          </ShowOnlyMobile>
          <ButtonWrapper>
            <NavbarButton
              className={
                matchMedia("only screen and (max-width: 768px)").matches
                  ? ""
                  : "swiper-no-swiping"
              }
              href="#"
              onClick={(e) => {
                e.preventDefault();
                onCategoryBtnTapHandler(0);
              }}
            >
              모의투자하기
            </NavbarButton>
            <NavbarButton
              className={
                matchMedia("only screen and (max-width: 768px)").matches
                  ? ""
                  : "swiper-no-swiping"
              }
              href="#"
              onClick={(e) => {
                e.preventDefault();
                onCategoryBtnTapHandler(1);
              }}
            >
              아워튜브란
            </NavbarButton>
            <NavbarButton
              className={
                matchMedia("only screen and (max-width: 768px)").matches
                  ? ""
                  : "swiper-no-swiping"
              }
              href="#"
              onClick={(e) => {
                e.preventDefault();
                onCategoryBtnTapHandler(2);
              }}
            >
              앱 사용법
            </NavbarButton>
            <NavbarButton
              className={
                matchMedia("only screen and (max-width: 768px)").matches
                  ? ""
                  : "swiper-no-swiping"
              }
              href="#"
              onClick={(e) => {
                e.preventDefault();
                onCategoryBtnTapHandler(4);
              }}
            >
              FAQ
            </NavbarButton>
            <AppDownloadButton onClick={routeToMarketPlace} />
          </ButtonWrapper>
        </ContentSection>
      </NavbarContainer>

      <DropDownMenuBar className={isDropDownMenuShowed ? "active" : ""}>
        <MenuTextBtnWrapper>
          <MenuTextButton
            className="swiper-no-swiping"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              onCategoryBtnTapHandler(0);
            }}
          >
            모의투자 해보기
          </MenuTextButton>
          <MenuTextButton
            className="swiper-no-swiping"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              onCategoryBtnTapHandler(1);
            }}
          >
            아워튜브란
          </MenuTextButton>
          <MenuTextButton
            className="swiper-no-swiping"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              onCategoryBtnTapHandler(2);
            }}
          >
            앱 사용법
          </MenuTextButton>
          <MenuTextButton
            className="swiper-no-swiping"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              onCategoryBtnTapHandler(4);
            }}
          >
            FAQ
          </MenuTextButton>
        </MenuTextBtnWrapper>
      </DropDownMenuBar>
    </NavBarContainerWrapper>
  );
};

const MenuTextBtnWrapper = styled.div`
  height: 200px;
  top: 2px;
  display: flex;
  flex-direction: column;
`;

const ShowOnlyMobile = styled.div`
  display: none;

  @media ${({ theme }) => theme.device.mobile} {
    display: block;
  }
`;

const MenuTextButton = styled.button`
  text-align: start;
  padding: 0 33px;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  ${fontTheme.body2}
  color: ${(props) => props.theme.colors.grey40};
  cursor: pointer;
`;

const NavBarContainerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 56px;
  background-color: white;
  width: 100%;
  z-index: 10000;
  position: fixed;
  top: 0;
`;

const DropDownMenuBar = styled.div`
  height: 198px;
  width: 100%;
  position: fixed;
  top: -146px;
  z-index: 100;
  opacity: 0;
  transition: all 0.35s;

  &.active {
    top: 56px;
    opacity: 1;
  }
`;

const MenuButton = styled(Icon)`
  display: none;
  height: 24px;
  width: 24px;
  background-color: white;
  position: relative;
  cursor: pointer;
  margin-right: 12px;

  @media ${({ theme }) => theme.device.mobile} {
    display: block;
  }
`;

const PariLine = styled.span`
  display: block;
  position: absolute;
  width: 100%;
  height: 1.5px;
  border-radius: 30px;
  background: ${(props) => props.theme.colors.black};
  transition: all 0.35s;
`;

const PairLine1 = styled(PariLine)`
  top: 0;
  background: #4a4d57;

  &.active {
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
  }
`;
const PairLine2 = styled(PariLine)`
  top: 50%;
  transform: translateY(-50%);
  background: #4a4d57;

  &.active {
    opacity: 0;
  }
`;
const PairLine3 = styled(PariLine)`
  bottom: 0;
  background: #4a4d57;

  &.active {
    bottom: 50%;
    transform: translateY(50%) rotate(-45deg);
  }
`;

const ContentSection = styled.div`
  width: 112.8rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${({ theme }) => theme.device.tablet} {
    width: 1024px;
  }
`;

export default NavBar;
