import React from 'react';
import img from "../../assets/images/search.png";
import styled from "styled-components";
import AppButton from "./AppButton";
import {remFontTheme} from "../../styles/remFonts";
import useAsync from "../../utils/useAsync";
import {saveNewChannelName} from "../../repository/repository";
import {fontTheme} from "../../styles/fonts";

const NoSearchResultView = (props) => {
    const {term, openDoneDialog} = props;
    // const [saveNewChannelState, refetchSaveNewChannel] = useAsync(() => saveNewChannelName(term), [term], true);

    return <S.Container>
        <S.SearchImg src={img} alt="search_img"/>
        <S.LeadingTitle>찾는 채널이 없어요</S.LeadingTitle>
        <S.Description>요청하면 추후 {term} 채널을 반영해드려요</S.Description>
        <AppButton id="request_btn" onClick={async () => {
            // await refetchSaveNewChannel();
            await saveNewChannelName(term);
            // 따로 오류 처리는 하지 않았음.
            openDoneDialog();
        }}>해당 채널 요청하기</AppButton>
    </S.Container>
}

const S = {
    Container: styled.div`
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      
    `,
    SearchImg: styled.img`
      height: 20.0rem;
      width: 20.0rem;
    `,
    LeadingTitle: styled.h3`
      ${fontTheme.title1};
      color: ${props => props.theme.colors.black};
      
      margin-bottom: 0.4rem;
    `,
    Description: styled.span`
      ${fontTheme.body2};
      color: ${props => props.theme.colors.grey30};
      
      margin-bottom: 2.4rem;
    `,

}

export default NoSearchResultView;